import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Grid, Button, CardMedia, Stack, Divider, Typography, Chip, Card, Box } from '@mui/material';
import ProjectImagePlaceholder from 'assets/images/placeholders/project_placeholder.jpeg';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import LoadingCard from './LoadingCard';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { getNChars } from 'utils/helpers';
import { gridSpacing } from 'store/constant';
import useConfig from 'hooks/useConfig';

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const ProjectCard = ({ isLoading, data, link }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const goToUsers = () => {
        navigate('/admin/users');
    };

    const mediaSX = {
        width: '100%',
        borderRadius: '12px'
    };

    const goToPreview = () => {
        navigate(link);
    };

    return (
        <>
            {isLoading ? (
                <LoadingCard />
            ) : (
                <Card sx={{ boxShadow: 2 }}>
                    <Box sx={{ pt: 2, px: 2 }}>
                        <Grid container sx={{ mt: '2px' }}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} md={6}>
                                    <MainCard
                                        content={false}
                                        boxShadow
                                        sx={{
                                            '&:hover': {
                                                transform: 'scale3d(1.02, 1.02, 1)',
                                                transition: 'all .4s ease-in-out'
                                            }
                                        }}
                                    >
                                        <CardMedia
                                            sx={{ height: 180 }}
                                            image={data?.image?.url || ProjectImagePlaceholder}
                                            title={data?.name}
                                            component={Link}
                                            to={link}
                                        />
                                    </MainCard>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                        sx={{
                                            position: 'relative',
                                            zIndex: 1,
                                            bottom: '20px'
                                        }}
                                    >
                                        <Stack>
                                            <Chip size="small" label="Stage" color="success" sx={{ color: `white`, border: `1px solid white`, width: '60px', ml: `auto`, mb: '-10px', zIndex: 2 }} />
                                            <Chip
                                                label=" Feasiblity Assessment"
                                                sx={{ borderTopRightRadius: 0, backgroundColor: 'white', border: `1px solid ${theme.palette.secondary.main}`, zIndex: 1 }}
                                                color="success"
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={1}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item >
                                                <Typography variant="h3" sx={{ ml: 0.8 }}>
                                                    {data.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    style={{ backgroundColor: '#14B7BA' }}
                                                    component={Link}
                                                    to={link}
                                                    size="small"
                                                    variant="contained"
                                                >
                                                    View More
                                                    <EastIcon sx={{ ml: 1 }} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Stack direction="row">
                                            <LocationOnOutlinedIcon sx={{ color: theme.palette.grey[500] }} />
                                            <Typography noWrap variant="body2">
                                                {data?.location?.address}
                                            </Typography>
                                        </Stack>

                                        <Grid container spacing={2}>
                                            <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical" flexItem sx={{ color: theme.palette.primary.main }} />}
                                                spacing={2}
                                                sx={{
                                                    background: theme.palette.grey[300],
                                                    borderRadius: useConfig().borderRadius,
                                                    pr: 1.5,
                                                    pl: 1.5,
                                                    pt: 1,
                                                    pb: 1
                                                }}
                                            >
                                                <Stack direction="column">
                                                    <Typography variant="subtitle" align="center" noWrap>
                                                        {data?.totalProjectCost ?? `NA`}
                                                    </Typography>
                                                    <Typography variant="caption" align="center" noWrap>
                                                        Deal Size
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="column">
                                                    <Typography variant="subtitle" align="center" noWrap>
                                                        {data?.thematicGrou ?? `NA`}
                                                    </Typography>
                                                    <Typography variant="caption" align="center">
                                                        Sector
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="column">
                                                    <Typography variant="subtitle" align="center">
                                                        {data?.thematicGrou ?? `NA`}
                                                    </Typography>
                                                    <Typography variant="caption" align="center" noWrap>
                                                        Financing Type
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        overflow: 'hidden',
                                                        height: 45
                                                    }}
                                                >
                                                    {data.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            )}
        </>
    );
};

ProjectCard.propTypes = {
    isLoading: PropTypes.bool
};

export default ProjectCard;
