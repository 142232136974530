// material-ui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import AppLogo from 'ui-component/Logo';
import { Link } from 'react-router-dom';

//  if you want to use image instead of <svg> uncomment following.
// import logoDark from 'assets/images/logos/verst_logo_white.png';
// import logo from 'assets/images/logos/verst_logo.png';

// ==============================|| LOGO ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <Box sx={{ display: { xs: 'block', md: 'block' } }}>
            <AppLogo />
        </Box>

        // <img
        //     style={{ width: 100, height: 100, position: 'relative' }}
        //     src={theme.palette.mode === 'dark' ? logoDark : logo}
        //     alt="Verst Logo"
        // />
    );
};

export default Logo;
