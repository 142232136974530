// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    conversations: [],
    user: {},
    users: [],
    conversation: {}
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.user = action.payload;
        },
        getConversationSuccess(state, action) {
            state.conversation = action.payload;
        },

        // GET USER CHATS
        getUserConversationsSuccess(state, action) {
            state.conversations = action.payload;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.users = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUserConversations(user) {
    return async () => {
        try {
            const response = await axios.get('/conversations');
            dispatch(slice.actions.getUserConversationsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createConversation(project, technicalAssistant) {
    return axios.post('/conversations', { project, technicalAssistant });
}

export function getConversation(conversationId) {
    return async () => {
        try {
            const response = await axios.get(`/conversations/${conversationId}`);
            dispatch(slice.actions.getConversationSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}
