/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { COUNTRIES } from 'utils/Constants';
import FileUpload from 'ui-component/FileUpload';
import AnimateButton from 'ui-component/extended/AnimateButton';

// material-ui
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {
    FormHelperText,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    Typography,
    Autocomplete,
    Box,
    Button,
    Stack,
    Avatar
} from '@mui/material';

// third party
import * as yup from 'yup';

// project imports
import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { handleBack, handleNext, updateInvestor } from 'store/slices/investor';

// ===========================|| JWT - REGISTER ||=========================== //

const RepresentativeForm = ({ ...others }, steps) => {
    const { activeStep, investor } = useSelector((state) => state.investor);

    const theme = useTheme();
    const dispatch = useDispatch();

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    const getInitValues = () => {
        return {
            _id: investor?._id || '',
            repEmail: investor?.repEmail || '',
            isAuthorized: investor?.isAuthorized || false,
            repAddress: investor?.repAddress || '',
            repPhone: investor?.repPhone || '',
            repName: investor?.repName || '',
            repProfilePhoto: investor?.repProfilePhoto || ''
        };
    };

    const validationSchema = yup.object({
        repEmail: yup.string().email('Must be a valid email').max(255).required('Representative Name is required'),
        repName: yup.string().required('Representative Name is required'),
        isAuthorized: yup.boolean().oneOf([true], 'You must accept this concent')
    });

    const formik = useFormik({
        initialValues: getInitValues(),
        validationSchema,
        onSubmit: async (values) => {
            await dispatch(updateInvestor(values));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Your company information was saved successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            handleNext();
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} id="validation-forms" encType="multipart/form-data">
                {formik.isSubmitting && <Loader />}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Full Name
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.repName && formik.errors.repName)}
                            type="text"
                            placeholder="Representative Full Names"
                            variant="outlined"
                            value={formik.values.repName}
                            name="repName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.repName && formik.errors.repName && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.repName}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Email
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.repEmail && formik.errors.repEmail)}
                            type="email"
                            variant="outlined"
                            placeholder="johndoe@example.com"
                            value={formik.values.repEmail}
                            name="repEmail"
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.repEmail && formik.errors.repEmail && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.repEmail}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Phone Number
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.repPhone && formik.errors.repPhone)}
                            type="text"
                            variant="outlined"
                            placeholder="+254 ********"
                            value={formik.values.repPhone}
                            name="repPhone"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.repPhone && formik.errors.repPhone && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.repPhone}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Physical Address
                        </Typography>

                        <Autocomplete
                            id="places"
                            name="physicalAddress"
                            options={status === 'OK' ? data : []}
                            isOptionEqualToValue={(option, value) => option.label === value?.description}
                            autoHighlight
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderOption={(props, option) => (
                                <li {...props} style={{ fontSize: 15 }} value={option.label}>
                                    {option.description}
                                </li>
                            )}
                            onChange={(e, val) => {
                                if (val) {
                                    formik.setFieldValue('repAddress', val.description);
                                } else {
                                    formik.setFieldValue('repAddress', '');
                                }
                            }}
                            value={value || formik.values.repAddress}
                            onInputChange={(e, value) => {
                                setValue(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    disabled={!ready}
                                    error={formik.touched.repAddress && Boolean(formik.errors.repAddress)}
                                    helperText={formik.touched.repAddress && formik.errors.repAddress}
                                    placeholder="e.g Kampala, Uganda"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {ready ? null : <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            required
                        />
                        {formik.touched.repAddress && formik.errors.repAddress && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.repAddress}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={8}>
                        <Stack>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Profile Photo
                            </Typography>
                            <FileUpload
                                mandatory={false}
                                name="repProfilePhoto"
                                label="Representative Profile Photo"
                                formik={formik}
                                acceptedFileTypes=".png,.jpg,.jpeg"
                            />
                        </Stack>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} sm={12} md={4} alignItems="center">
                        <Stack sx={{ mt: 3 }}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Logo Preview
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar alt="Representative Image" src={formik.values.repProfilePhoto} sx={{ width: 84, height: 84 }} />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.isAuthorized}
                                    error={Boolean(formik.touched.isAuthorized && formik.errors.isAuthorized)}
                                    name="isAuthorized"
                                    color="primary"
                                    fullWidth
                                    onBlur={formik.handleBlur}
                                    onChange={() => formik.setFieldValue('isAuthorized', !formik.values.isAuthorized)}
                                />
                            }
                            label="I have the authority to represent the company"
                        />
                        {formik.touched.isAuthorized && formik.errors.isAuthorized && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.isAuthorized}
                            </FormHelperText>
                        )}
                    </Grid>

                    {/* Bottom Buttons */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    disableElevation
                                    sx={{
                                        width: '200px',
                                        height: '48px',
                                        backgroundColor: theme.palette.grey[500],
                                        color: 'white'
                                    }}
                                    color="secondary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                >
                                    <WestIcon sx={{ mr: 'auto' }} /> Back
                                </Button>
                            </AnimateButton>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <AnimateButton>
                                <Button
                                    type="submit"
                                    disableElevation
                                    fullWidth
                                    sx={{
                                        width: '200px',
                                        height: '48px',
                                        backgroundColor: theme.palette.secondary.main,
                                        color: 'white'
                                    }}
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                >
                                    Save & Complete <EastIcon sx={{ ml: 'auto' }} />
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default RepresentativeForm;
