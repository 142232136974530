import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { COUNTRIES } from 'utils/Constants';
import AnimateButton from 'ui-component/extended/AnimateButton';

// material-ui
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography, Autocomplete, Box, Button } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { handleBack, handleNext, updateSubmittingOrg } from 'store/slices/submittingOrganization';

// ===========================|| JWT - REGISTER ||=========================== //

const RepresentativeForm = ({ ...others }, steps) => {
    const { activeStep, submittingOrganization } = useSelector((state) => state.submittingOrganization);

    const theme = useTheme();
    const dispatch = useDispatch();

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();
    
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    _id: submittingOrganization?._id ?? '',
                    repEmail: submittingOrganization?.repEmail ?? '',
                    repAddress: submittingOrganization?.repAddress ?? '',
                    repPhone: submittingOrganization?.repPhone ?? '',
                    repName: submittingOrganization?.repName ?? '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    repEmail: Yup.string().email('Must be a valid email').max(255).required('Representative Name is required'),
                    repName: Yup.string().required('Representative Name is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    dispatch(updateSubmittingOrg(values));
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your company information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Full Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.repName && errors.repName)}
                                    type="text"
                                    placeholder="Representative Full Names"
                                    variant="outlined"
                                    value={values.repName}
                                    name="repName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.repName && errors.repName && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repName}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Email
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.repEmail && errors.repEmail)}
                                    type="email"
                                    variant="outlined"
                                    placeholder="johndoe@example.com"
                                    value={values.repEmail}
                                    name="repEmail"
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.repEmail && errors.repEmail && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repEmail}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Phone Number
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.repPhone && errors.repPhone)}
                                    type="text"
                                    variant="outlined"
                                    placeholder="+254 ********"
                                    value={values.repPhone}
                                    name="repPhone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.repPhone && errors.repPhone && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repPhone}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Physical Address
                                </Typography>

                                <Autocomplete
                                    id="places"
                                    name="physicalAddress"
                                    options={status === 'OK' ? data : []}
                                    isOptionEqualToValue={(option, value) => option.label === value?.description}
                                    autoHighlight
                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ fontSize: 15 }} value={option.label}>
                                            {option.description}
                                        </li>
                                    )}
                                    onChange={(e, val) => {
                                        if (val) {
                                            setFieldValue('repAddress', val.description);
                                        } else {
                                            setFieldValue('repAddress', '');
                                        }
                                    }}
                                    value={value || values.repAddress}
                                    onInputChange={(e, value) => {
                                        setValue(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            disabled={!ready}
                                            error={touched.repAddress && Boolean(errors.repAddress)}
                                            helperText={touched.repAddress && errors.repAddress}
                                            placeholder="e.g Kampala, Uganda"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {ready ? null : <CircularProgress color="inherit" size={20} />}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                    required
                                />
                                {touched.repAddress && errors.repAddress && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repAddress}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Save & Complete <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default RepresentativeForm;
