// third-party
import { FormattedMessage } from 'react-intl';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChatIcon from '@mui/icons-material/Chat';

// ==============================|| PROJECT OWNER MENU ITEMS ||============================== //
const technicalAssistant = {
    id: 'technicalAssistant',
    title: <FormattedMessage id="Admin" defaultMessage="Technical Assistant" />,
    icon: SpaceDashboardIcon,
    type: 'group',
    children: [
        {
            id: 'ta-all-projects',
            title: <FormattedMessage id="Projects" defaultMessage="All Projects" />,
            type: 'item',
            url: '/ta/projects',
            icon: SolarPowerIcon,
            breadcrumbs: false
        },
        {
            id: 'ta-requests',
            title: <FormattedMessage id="Requests" defaultMessage="Quotes Requests" />,
            type: 'item',
            url: '/ta/requests',
            icon: VolunteerActivismIcon,
            breadcrumbs: false
        },
        {
            id: 'deal_room',
            title: <FormattedMessage id="Deal Room" defaultMessage="Deal Room" />,
            type: 'collapse',
            icon: MeetingRoomIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'my_calendar',
                    title: <FormattedMessage id="View Projects" defaultMessage="My calendar" />,
                    type: 'item',
                    url: '/calendar',
                    icon: CalendarMonthOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'pitch_room',
                    title: <FormattedMessage id="Pitch Room" defaultMessage="Pitch Room" />,
                    type: 'item',
                    url: '/pitch-room',
                    icon: MeetingRoomIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'so-chat',
            title: <FormattedMessage id="Requests" defaultMessage="Chat" />,
            type: 'item',
            url: '/chat',
            icon: ChatIcon,
            breadcrumbs: false
        }
        // {
        //     id: 'so-account',
        //     title: <FormattedMessage id="my_profile" defaultMessage="My Account" />,
        //     type: 'item',
        //     url: '/ta/profile',
        //     icon: PersonIcon,
        //     breadcrumbs: false
        // }
    ]
};

export default technicalAssistant;
