// IncompleteProfileAlert.js
import { Grid, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const IncompleteProfileAlert = () => {
    const theme = useTheme();

    return (
        <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12}>
                <Alert severity="warning" sx={{ borderColor: theme.palette.warning.dark }}>
                    Please complete your profile!
                </Alert>
            </Grid>
        </Grid>
    );
};

export default IncompleteProfileAlert;
