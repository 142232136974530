// third-party
import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    taRequest: {},
    taRequests: [],
    error: null,
    success: false
};

const slice = createSlice({
    name: 'taRequest',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getTASuccess(state, action) {
            state.taRequest = action.payload;
            state.success = true;
        },
        getAllTASuccess(state, action) {
            state.taRequests = action.payload;
            state.success = true;
        },
        postTASuccess(state, action) {
            state.taRequest = action.payload;
            state.success = true;
        },
        deleteTASuccess(state) {
            state.taRequest = {};
            state.success = true;
        }
    }
});

// Reducer
export default slice.reducer;

export function getTARequest(id) {
    return async () => {
        try {
            const response = await axios.get(`/technical-assistant-request/request/${id}`);
            dispatch(slice.actions.getTASuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllTARequests() {
    return async () => {
        try {
            const response = await axios.get(`/technical-assistant-request/requests`);
            dispatch(slice.actions.getAllTASuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function createTARequest(data) {
    return async () => {
        try {
            const response = await axios.post(`/technical-assistant-request/request`, data);
            dispatch(slice.actions.postTASuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateTARequest(id, data) {
    return async () => {
        try {
            const formData = new FormData();

            Object.keys(data).forEach(key => {
                if (key === 'documents' && Array.isArray(data[key])) {
                    data[key].forEach((file, index) => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, data[key]);
                }
            });
            const response = await axios.post(`/technical-assistant-request/request/${id}`, formData);
            dispatch(slice.actions.postTASuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteTARequest(id) {
    return async () => {
        try {
            await axios.delete(`/technical-assistant-request/request/${id}`);
            dispatch(slice.actions.deleteTASuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
