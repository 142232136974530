/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import { useDispatch, useSelector } from 'store';
import AnimateButton from 'ui-component/extended/AnimateButton';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import usePlacesAutocomplete from 'use-places-autocomplete';

// material-ui
import { Autocomplete, Avatar, Box, Button, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third party
import * as yup from 'yup';

// project imports
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { handleBack, handleNext, postInvestor, updateInvestor } from 'store/slices/investor';
import { openSnackbar } from 'store/slices/snackbar';
import FileUpload from 'ui-component/FileUpload';
import Loader from 'ui-component/Loader';

yup.addMethod(yup.mixed, 'fileSize', function (maxSize, message) {
    return this.test('fileSize', message || `File size should be less than ${maxSize / (1024 * 1024)} MB`, (value) => {
        if (!value) return true; // if no file, it's valid
        if (!(value instanceof File)) return true;
        return value && value.size <= maxSize;
    });
});

// ===========================|| JWT - REGISTER ||=========================== //

const CompanyInformationForm = ({ ...others }) => {
    const { activeStep, investor } = useSelector((state) => state.investor);
    const [error, setError] = useState(null);
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    const theme = useTheme();
    const dispatch = useDispatch();

    const getInitValues = () => {
        if (investor) {
            return {
                _id: investor?._id || '',
                companyEmail: investor?.companyEmail || '',
                companyName: investor?.companyName || '',
                companyOverview: investor?.companyOverview || '',
                companyWebsite: investor?.companyWebsite || '',
                companyPhone: investor?.companyPhone || '',
                physicalAddress: investor?.physicalAddress || '',
                city: investor?.city || '',
                companyLogo: investor?.companyLogo || ''
            };
        }
        return {
            companyEmail: '',
            companyName: '',
            companyOverview: '',
            companyWebsite: '',
            companyPhone: '',
            physicalAddress: '',
            city: '',
            companyLogo: ''
        };
    };

    const validationSchema = yup.object({
        companyEmail: yup.string().email('Must be a valid email').max(255).required('Email is required'),
        companyName: yup.string().required('Company name is required'),
        companyOverview: yup.string().required('Company Overview is required'),
        companyPhone: yup
            .string()
            .required('Phone number is required')
            .matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                'Must be a valid Phone number is not valid'
            ),
        city: yup
            .string()
            .required('City is required')
            .matches(/^[A-Za-z\s]+$/, 'City must only contain letters and spaces'),
        physicalAddress: yup
            .string()
            .required('Address is required and must be valid')
            .matches(/^[A-Za-z,\s]+$/, 'Address must only contain letters, commas, and spaces'),
        companyLogo: yup.mixed().fileSize(4 * 1024 * 1024, 'File size should be less than 4 MB'),
        companyWebsite: yup
            .string()
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Must be a valid website url with the format https://...'
            )
            .notRequired()
    });

    const formik = useFormik({
        initialValues: getInitValues(),
        validationSchema,
        onSubmit: async (values) => {
            console.log(values);
            if (Object.keys(investor).length === 0) {
                await dispatch(postInvestor(values));
            } else {
                await dispatch(updateInvestor(values));
            }
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Your company information was saved successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            handleNext();
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit} id="validation-forms" encType="multipart/form-data">
                {formik.isSubmitting && <Loader />}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Full Name
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.companyName && formik.errors.companyName)}
                            type="text"
                            placeholder="Company Name"
                            variant="outlined"
                            value={formik.values.companyName}
                            name="companyName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.companyName && formik.errors.companyName && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.companyName}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Website
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.companyWebsite && formik.errors.companyWebsite)}
                            type="text"
                            variant="outlined"
                            placeholder="www.example.com"
                            value={formik.values.companyWebsite}
                            name="companyWebsite"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.companyWebsite && formik.errors.companyWebsite && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.companyWebsite}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <InputLabel id="description">Company Overview</InputLabel>
                        <Stack>
                            <TextField
                                id="description"
                                fullWidth
                                name="companyOverview"
                                error={Boolean(formik.touched.companyOverview && formik.errors.companyOverview)}
                                value={formik.values.companyOverview}
                                aria-label="Company description"
                                placeholder="A short overview of what the company is about"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                multiline
                                rows={4}
                                required
                            />
                        </Stack>
                        {formik.touched.companyOverview && formik.errors.companyOverview && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.companyOverview}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Email Address
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.companyEmail && formik.errors.companyEmail)}
                            type="email"
                            variant="outlined"
                            placeholder="johndoe@example.com"
                            value={formik.values.companyEmail}
                            name="companyEmail"
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.companyEmail && formik.errors.companyEmail && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.companyEmail}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Phone Number
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.companyPhone && formik.errors.companyPhone)}
                            type="text"
                            variant="outlined"
                            placeholder="+254 ********"
                            value={formik.values.companyPhone}
                            name="companyPhone"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.companyPhone && formik.errors.companyPhone && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.companyPhone}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Physical Address
                        </Typography>

                        <Autocomplete
                            id="places"
                            name="physicalAddress"
                            options={status === 'OK' ? data : []}
                            isOptionEqualToValue={(option, value) => option.label === value?.description}
                            autoHighlight
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderOption={(props, option) => (
                                <li {...props} style={{ fontSize: 15 }} value={option.label}>
                                    {option.description}
                                </li>
                            )}
                            onChange={(e, val) => {
                                if (val) {
                                    formik.setFieldValue('physicalAddress', val.description);
                                } else {
                                    formik.setFieldValue('physicalAddress', '');
                                }
                            }}
                            value={value || formik.values.physicalAddress}
                            onInputChange={(e, value) => {
                                setValue(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    disabled={!ready}
                                    error={formik.touched.physicalAddress && Boolean(formik.errors.physicalAddress)}
                                    helperText={formik.touched.physicalAddress && formik.errors.physicalAddress}
                                    placeholder="e.g Kampala, Uganda"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {ready ? null : <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            City
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.city && formik.errors.city)}
                            type="text"
                            variant="outlined"
                            placeholder="Nairobi"
                            value={formik.values.city}
                            name="city"
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.city && formik.errors.city && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.city}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} alignItems="center">
                        <Stack>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Company Logo
                            </Typography>
                            <FileUpload
                                name="companyLogo"
                                label="Company Logo"
                                formik={formik}
                                mandatory={false}
                                acceptedFileTypes=".png,.jpg,.jpeg"
                            />
                        </Stack>
                    </Grid>
                    <Grid container xs={12} sm={12} md={4} justifyContent="center">
                        <Stack sx={{ mt: 3 }}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Logo Preview
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Avatar alt="User Image" src={formik.values?.companyLogo} sx={{ width: 84, height: 84 }} />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                    {/* Bottom Buttons */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    disableElevation
                                    sx={{
                                        width: '200px',
                                        height: '48px',
                                        backgroundColor: theme.palette.grey[500],
                                        color: 'white'
                                    }}
                                    color="secondary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                >
                                    <WestIcon sx={{ mr: 'auto' }} /> Back
                                </Button>
                            </AnimateButton>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <AnimateButton>
                                <Button
                                    type="submit"
                                    disableElevation
                                    fullWidth
                                    sx={{
                                        width: '200px',
                                        height: '48px',
                                        backgroundColor: theme.palette.secondary.main,
                                        color: 'white'
                                    }}
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                >
                                    Save & Continue <EastIcon sx={{ ml: 'auto' }} />
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default CompanyInformationForm;
