import admin from './admin';
import owner from 'menu-items/owner';
import investor from 'menu-items/investor';
import submittingOrganization from 'menu-items/submittingOrganization';
import technicalAssistant from 'menu-items/technicalAssistant';

import { DashboardMenu } from 'menu-items/dashboard';
import useAuth from 'hooks/useAuth';

// const [userRole, setRole] = useState(initialRole);

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    items: [submittingOrganization, admin, owner, investor, technicalAssistant]
};

export default menuItems;