/* eslint-disable no-underscore-dangle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// dataroom imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    dataroom: {},
    activeStep: 0,
    termsAccepted: false,
    technicalAssistant: {},
    technicalAssistants: [],
    request: {},
    requests: []
};

const slice = createSlice({
    name: 'technicalAssistant',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        handleReset(state) {
            state.activeStep = 0;
        },
        handleBack(state) {
            state.activeStep -= 1;
        },
        handleNext(state) {
            state.activeStep += 1;
        },
        getSOSuccess(state, action) {
            state.technicalAssistants = action.payload;
            state.success = true;
        },
        getRequestsSuccess(state, action) {
            state.technicalAssistant.requests = action.payload;
            state.success = true;
        },
        showSuccess(state, action) {
            state.technicalAssistant = action.payload;
            state.success = true;
        },
        postSOSuccess(state, action) {
            state.technicalAssistant = action.payload;
            state.success = true;
        },
        deleteSuccess(state, action) {
            state.technicalAssistant = {};
            state.success = true;
        }
    }
});

// Reducer
export default slice.reducer;

export function setActiveStep(data) {
    dispatch(slice.actions.setActiveStep(data));
}

export function handleReset() {
    dispatch(slice.actions.handleReset());
}
export function handleBack() {
    dispatch(slice.actions.handleBack());
}
export function handleNext() {
    dispatch(slice.actions.handleNext());
}
export function getByIdTechnicalAssistant(id) {
    return async () => {
        try {
            const response = await axios.get(`/technical-assistant/${id}`);
            dispatch(slice.actions.showSuccess(response.data.data));
            return response.data.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getTechnicalAssistants() {
    return async () => {
        try {
            const response = await axios.get(`/technical-assistant`);
            dispatch(slice.actions.getSOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getRequests(id) {
    return async () => {
        try {
            const response = await axios.get(`/technical-assistant-request/requests/${id}`);
            dispatch(slice.actions.getRequestsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postTechnicalAssistant(data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const key in data) {
                formData.append(key, data[key]);
            }

            const response = await axios.post(`/technical-assistant`, formData);
            dispatch(slice.actions.postSOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateTechnicalAssistantAdmin(data) {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/technical-assistant/admin/${data._id}`, data);
            dispatch(slice.actions.postSOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateTechnicalAssistant(data) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            for (const key in data) {
                if (Array.isArray(data[key])) {
                    data[key].forEach((item) => {
                        formData.append(`${key}[]`, item);
                    });
                } else {
                    formData.append(key, data[key]);
                }
            }

            const response = await axios.put(`/technical-assistant/${data._id}`, formData);
            dispatch(slice.actions.postSOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function delTechnicalAssistant(selectedUser) {
    return async () => {
        try {
            await axios.delete(`/technical-assistant/${selectedUser}`);
            dispatch(slice.actions.deleteSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
