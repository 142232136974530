import { useEffect, useState } from 'react';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';

import ThemeCustomization from 'themes';
import { useDispatch } from 'store';
import { getDashboard } from 'store/slices/menu';

//Toast Notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { openSnackbar } from 'store/slices/snackbar';
import { getSocket } from 'utils/socket';
import { getUserConversations } from 'store/slices/chat';

// ==============================|| APP ||============================== //

const App = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDashboard()).then(() => {
            setLoading(true);
        });
    }, []);

    if (!loading) return <Loader />;

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <Routes />
                            <Snackbar />
                            <ToastContainer position="top-center" autoClose={3000} />
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
