/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            switch (user.role) {
                case `admin`:
                    navigate(DASHBOARD_PATH, { replace: true });
                    break;
                case `owner`:
                    if (user?.verified) {
                        navigate(`/owner/projects`, { replace: true });
                    } else {
                        navigate(`/pending-verification-page`, { replace: true });
                    }
                    break;
                case `investor`:
                    // eslint-disable-next-line no-unused-expressions
                    if (user?.investor && user?.investor?.iskyc) {
                        navigate(`/investor/projects`, { replace: true });
                    } else if (user?.investor?.status === 'draft') {
                        navigate(`/investor/onboarding/${user?.investor?._id}`, { replace: true });
                    } else if (!user?.investor?.iskyc) {
                        navigate(`/pending-approval-page`, { replace: true });
                    }
                    break;
                case `submittingOrganization`:
                    // eslint-disable-next-line no-unused-expressions
                    if (user?.organization?.termsAccepted && user?.iskyc) {
                        navigate(`/so/projects`, { replace: true });
                    } else if (!user?.organization?.termsAccepted) {
                        navigate(`/so/onboarding/${user?.organization_id}`, { replace: true });
                    } else if (!user?.iskyc) {
                        navigate(`/pending-approval-page`, { replace: true });
                    }
                    break;
                case `technicalAssistant`:
                    // eslint-disable-next-line no-unused-expressions
                    if (
                        (user?.iskyc && user?.technicalAssistant && user?.technicalAssistant?.profileCompleted) ||
                        (user?.iskyc && user?.technicalAssistant && user?.technicalAssistant?.profileCompleted == null)
                    ) {
                        navigate(`/ta/requests`, { replace: true });
                    } else if (!user?.iskyc) {
                        navigate(`/pending-approval-page`, { replace: true });
                    } else if (!user?.technicalAssistant?.profileCompleted || !user?.technicalAssistant?.termsAccepted) {
                        navigate(`/ta/onboarding/${user?.technicalAssistant?._id}`, { replace: true });
                    }
                    break;

                default:
                    break;
            }
        }
    }, [isLoggedIn, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
