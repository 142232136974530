/* eslint-disable no-underscore-dangle */
import { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import usePlacesAutocomplete from 'use-places-autocomplete';

// material-ui
import { useTheme } from '@mui/material/styles';
import { FormHelperText, Grid, TextField, Typography, Autocomplete, Button, Box } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import CircularProgress from '@mui/material/CircularProgress';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import ImageUpload from 'ui-component/ImageUpload';

import { handleBack, handleNext, postTechnicalAssistant, updateTechnicalAssistant } from 'store/slices/technicalAssistant';
// ===========================|| COMPANY INFO FORM ||=========================== //

const CompanyInformationForm = ({ ...others }) => {
    const { activeStep, technicalAssistant } = useSelector((state) => state.technicalAssistant);

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    const theme = useTheme();
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const getInitValues = () => {
        if (technicalAssistant && technicalAssistant._id) {
            return {
                _id: technicalAssistant?._id ?? '',
                companyEmail: technicalAssistant?.companyEmail ?? '',
                companyName: technicalAssistant?.companyName ?? '',
                companyOverview: technicalAssistant?.companyOverview ?? '',
                companyWebsite: technicalAssistant?.companyWebsite ?? '',
                companyPhone: technicalAssistant?.companyPhone ?? '',
                companyAddress: technicalAssistant?.companyAddress ?? '',
                companyLogo: technicalAssistant?.companyLogo ?? ''
            };
        }
        return {
            companyEmail: '',
            companyName: '',
            companyOverview: '',
            companyWebsite: '',
            companyPhone: '',
            companyAddress: '',
            companyLogo: ''
        };
    };

    const extractedFileName = technicalAssistant?.companyLogo ? technicalAssistant.companyLogo.split('/').pop() : ''; // Extracting file name from path

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={getInitValues()}
                validationSchema={Yup.object().shape({
                    companyEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    companyName: Yup.string().required('Company name is required'),
                    companyOverview: Yup.string().required('Company Overview is required'),
                    companyPhone: Yup.string()
                        .matches(/^\+?\d+$/, 'Phone number can only contain numbers')
                        .min(10, "Phone number can't be less than 10 characters")
                        .max(17, "Phone number can't exceed 17 characters")
                        .required('Company Phone is required'),
                    companyWebsite: Yup.string().url('Must be a valid website url with the format https://...').notRequired(),
                    companyLogo: Yup.mixed().required('A company logo is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    if (Object.keys(technicalAssistant).length === 0) {
                        await dispatch(postTechnicalAssistant(values));
                    } else {
                        dispatch(updateTechnicalAssistant(values));
                    }
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Company Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.companyName && errors.companyName)}
                                    type="text"
                                    placeholder="Company Name"
                                    variant="outlined"
                                    value={values.companyName}
                                    name="companyName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.companyName && errors.companyName && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.companyName}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Company Website (Paste your company website url)
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.companyWebsite && errors.companyWebsite)}
                                    type="text"
                                    variant="outlined"
                                    placeholder="eg: https://www.youtube.com/"
                                    value={values.companyWebsite}
                                    name="companyWebsite"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.companyWebsite && errors.companyWebsite && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.companyWebsite}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <InputLabel id="description">Company Overview</InputLabel>
                                <Stack>
                                    <TextField
                                        id="description"
                                        fullWidth
                                        name="companyOverview"
                                        error={Boolean(touched.companyOverview && errors.companyOverview)}
                                        value={values.companyOverview}
                                        aria-label="Company description"
                                        placeholder="A short overview of what the company is about"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        required
                                    />
                                </Stack>
                                {touched.companyOverview && errors.companyOverview && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.companyOverview}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Email Address
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.companyEmail && errors.companyEmail)}
                                    type="email"
                                    variant="outlined"
                                    placeholder="johndoe@example.com"
                                    value={values.companyEmail}
                                    name="companyEmail"
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.companyEmail && errors.companyEmail && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.companyEmail}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Phone Number
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.companyPhone && errors.companyPhone)}
                                    type="text"
                                    variant="outlined"
                                    placeholder="+254 ********"
                                    value={values.companyPhone}
                                    name="companyPhone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.companyPhone && errors.companyPhone && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.companyPhone}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Physical Address
                                </Typography>

                                <Autocomplete
                                    id="places"
                                    name="companyAddress"
                                    options={status === 'OK' ? data : []}
                                    isOptionEqualToValue={(option, value) => option.label === value?.description}
                                    autoHighlight
                                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ fontSize: 15 }} value={option.label}>
                                            {option.description}
                                        </li>
                                    )}
                                    onChange={(e, val) => {
                                        if (val) {
                                            setFieldValue('companyAddress', val.description);
                                        } else {
                                            setFieldValue('companyAddress', '');
                                        }
                                    }}
                                    value={value || values.companyAddress}
                                    onInputChange={(e, value) => {
                                        setValue(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            disabled={!ready}
                                            error={touched.companyAddress && Boolean(errors.companyAddress)}
                                            helperText={touched.companyAddress && errors.companyAddress}
                                            placeholder="e.g Kampala, Uganda"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {ready ? null : <CircularProgress color="inherit" size={20} />}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                    required
                                />
                                {touched.companyAddress && errors.companyAddress && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.companyAddress}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Company Logo
                                </Typography>
                                <Stack>
                                    <ImageUpload
                                        fieldName="companyLogo"
                                        label={technicalAssistant?.companyLogo ? 'Replace Logo' : 'Company Logo'}
                                        formik={{
                                            setFieldValue,
                                            values
                                        }}
                                        acceptedFileTypes=".png,.jpg,.jpeg"
                                        initialFileName={extractedFileName}
                                    />
                                </Stack>
                                {touched.companyLogo && errors.companyLogo && (
                                    <FormHelperText error id="company-logo-helper-text">
                                        {errors.companyLogo}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Save & Continue <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default CompanyInformationForm;
