import { io } from 'socket.io-client';

let socket;

export const getSocket = () => {
    if (!window.localStorage.getItem('token')) return null;
    if (!socket) {
        const URL = process.env.REACT_APP_SERVER_DOMAIN;
        const token = window.localStorage.getItem('token');
        socket = io(URL, {
            path: process.env.REACT_APP_SOCKET_PATH,
            autoConnect: false,
            extraHeaders: {
                authorization: `Bearer ${token}`
            }
        });

        socket.onAny((event, ...args) => {
            console.log(event, args);
        });
    }

    return socket;
};
