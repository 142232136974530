/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid, Typography, FormControl, FormControlLabel, Button, FormHelperText, Switch } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import { Download } from '@mui/icons-material';
import IframeModal from './IframeDialog';

const FileUpload = ({ formik, label, acceptedFileTypes, name, multiple = false, mandatory = true }) => {
    const [required, setRequired] = React.useState(true);
    return (
        <Grid container spacing={2}>
            {!formik.values[name]?.embedLink && (
                <Grid item xs={12}>
                    {mandatory === true ? (
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    inputProps={{ 'aria-label': 'small' }}
                                    color="secondary"
                                    onChange={(event) => {
                                        setRequired(!event.target.checked);
                                        if (event.target.checked) {
                                            formik.setFieldValue(name, null);
                                        } else {
                                            formik.setFieldError(name, 'This field is required, kindly opt out or submit a file');
                                        }
                                    }}
                                />
                            }
                            label="I do not have this document"
                        />
                    ) : (
                        <></>
                    )}
                </Grid>
            )}
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'center', border: '2px dashed', borderRadius: '40px' }}>
                                <input
                                    type="file"
                                    id={name}
                                    name={name}
                                    disabled={!required}
                                    onChange={(event) => {
                                        formik.setFieldValue(name, event.currentTarget.files[0]);
                                    }}
                                    onDrop={(event) => {
                                        formik.setFieldValue(name, Array.from(event.currentTarget.files));
                                    }}
                                    onDragOver={(event) => {
                                        formik.setFieldValue(name, Array.from(event.currentTarget.files));
                                    }}
                                    accept={acceptedFileTypes}
                                    style={{ display: 'none', height: '100%', width: '100%' }}
                                    multiple={multiple}
                                />
                                <label htmlFor={name}>
                                    <Button variant="text" component="span" fullWidth disabled={!required}>
                                        {Array.isArray(formik.values[name]) && formik.values[name].length > 0 ? (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {formik.values[name].map((file) => (
                                                        <Typography variant="body1" align="center" gutterBottom sx={{ mb: 2 }}>
                                                            {file.name}
                                                        </Typography>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        ) : formik.values[name]?.embedLink || formik.values[name] ? (
                                            <Typography variant="body1" align="center" gutterBottom sx={{ mb: 2 }}>
                                                Click here to replace the uploaded file --{' '}
                                                {formik.values[name]?.fileName || formik.values[name].name}
                                            </Typography>
                                        ) : (
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <CloudUploadIcon />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" align="center" gutterBottom sx={{ mb: 2 }}>
                                                        {label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" align="center">
                                                        You can tap on this upload window
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Button>
                                </label>
                            </div>
                        </Grid>

                        {formik.values[name]?.embedLink && (
                            <Grid item>
                                <IframeModal link={formik.values[name].embedLink} text="Review" />
                            </Grid>
                        )}
                        {formik.values[name]?.name && formik.touched[name] && formik.errors[name] && (
                            <Grid item>
                                <FormHelperText error>{formik.errors[name]}</FormHelperText>
                            </Grid>
                        )}
                    </Grid>
                </FormControl>
            </Grid>
        </Grid>
    );
};

FileUpload.propTypes = {
    formik: PropTypes.object,
    label: PropTypes.string,
    mandatory: PropTypes.bool,
    acceptedFileTypes: PropTypes.string,
    fieldName: PropTypes.string
};

export default FileUpload;
