import PropTypes from 'prop-types';

// material-ui
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import { Grid, InputLabel as MuiInputLabel, Tooltip, Typography } from '@mui/material';
import HelpOutline from '@mui/icons-material/HelpOutline';

const BInputLabel = styled((props) => <MuiInputLabel {...props} />, {
    shouldForwardProp: (prop) => prop !== 'horizontal'
})(({ theme, horizontal }) => ({
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginBottom: horizontal ? 0 : 8
}));

const InputLabel = ({ children, helperText, horizontal, optional, ...others }) => {
    const theme = useTheme();
    return (
        <BInputLabel horizontal={horizontal} {...others} sx={{ color: theme.palette.secondary.main }}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <span>
                        {children}

                        <span style={{ verticalAlign: 'super', fontSize: '0.8em', color: theme.palette.grey[500], marginLeft: '2px' }}>
                            {optional && 'optional'}
                        </span>
                    </span>
                </Grid>
                <Grid item>
                    {helperText && (
                        <Tooltip title={helperText} placement="top">
                            <HelpOutline sx={{ fontSize: '1.3rem' }} />
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </BInputLabel>
    );
};

InputLabel.propTypes = {
    children: PropTypes.node,
    horizontal: PropTypes.bool,
    helperText: PropTypes.string,
    optional: PropTypes.bool
};

InputLabel.defaultProps = {
    horizontal: false,
    optional: false
};

export default InputLabel;
