// third-party
import { FormattedMessage } from 'react-intl';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import BusinessIcon from '@mui/icons-material/Business';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PersonIcon from '@mui/icons-material/Person';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import Groups3Icon from '@mui/icons-material/Groups3';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TuneIcon from '@mui/icons-material/Tune';
import ChairIcon from '@mui/icons-material/Chair';
// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    title: <FormattedMessage id="Admin" defaultMessage="Administrator" />,
    icon: DashboardIcon,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
            type: 'item',
            url: '/admin/dashboard',
            icon: SpaceDashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'admin_projects',
            title: <FormattedMessage id="Users" defaultMessage="Projects" />,
            type: 'item',
            icon: SolarPowerIcon,
            breadcrumbs: false,
            url: '/admin/projects'
        },
        {
            id: 'deal_room',
            title: <FormattedMessage id="Deal Room" defaultMessage="Deal Room" />,
            type: 'item',
            url: '/admin/data-room',
            icon: MeetingRoomIcon,
            breadcrumbs: false
        },

        {
            id: 'investors',
            title: <FormattedMessage id="investors" defaultMessage="Investors" />,
            type: 'item',
            url: '/admin/investors',
            icon: Groups3Icon,
            breadcrumbs: false
        },
        {
            id: 'pitch-room',
            title: <FormattedMessage id="pitch-room" defaultMessage="Virtual Pitch Room" />,
            type: 'item',
            url: '/admin/pitch-room',
            icon: ChairIcon,
            breadcrumbs: false
        },
        {
            id: 'tas',
            title: <FormattedMessage id="tas" defaultMessage="TA Providers Profiles" />,
            type: 'item',
            url: '/admin/tas',
            icon: EngineeringIcon,
            breadcrumbs: false,
        },
        {
            id: 'admin-org-mgt',
            title: <FormattedMessage id="organizations" defaultMessage="Submitting Org" />,
            type: 'collapse',
            url: '/admin/orgs',
            icon: BusinessIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'view_orgs',
                    title: <FormattedMessage id="View Projects" defaultMessage="Add New" />,
                    type: 'item',
                    url: '/admin/orgs/add',
                    icon: DomainAddIcon,
                    breadcrumbs: false
                },
                {
                    id: 'view_orgs_list',
                    title: <FormattedMessage id="View Projects" defaultMessage="List" />,
                    type: 'item',
                    url: '/admin/orgs',
                    icon: CorporateFareIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'admin_users_mgt',
            title: <FormattedMessage id="Users" defaultMessage="Users" />,
            type: 'collapse',
            url: '/admin/users',
            icon: SupervisedUserCircleIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'add_users',
                    title: <FormattedMessage id="Add User" defaultMessage="Add New" />,
                    type: 'item',
                    icon: GroupAddIcon,
                    url: '/admin/users/add',
                    breadcrumbs: false
                },
                {
                    id: 'view_users',
                    title: <FormattedMessage id="View Users" defaultMessage="View All" />,
                    type: 'item',
                    url: '/admin/users',
                    icon: RecentActorsIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'settings',
            title: <FormattedMessage id="Settings" defaultMessage="Settings" />,
            type: 'collapse',
            url: '/settings',
            icon: SettingsSuggestIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'system-setting',
                    title: <FormattedMessage id="Settings" defaultMessage="System" />,
                    type: 'item',
                    icon: TuneIcon,
                    url: '/settings',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'my_profile',
            title: <FormattedMessage id="my_profile" defaultMessage="My Profile" />,
            type: 'item',
            url: '/admin/profile',
            icon: PersonIcon,
            breadcrumbs: false
        },
    ]
};

export default admin;
