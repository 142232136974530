import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/authentication/Register')));
const AuthSelectRole = Loadable(lazy(() => import('views/authentication/SelectRole')));
const AuthForgotPassword = Loadable(lazy(() => import('views/authentication/ForgotPassword')));
const AuthResetPassword = Loadable(lazy(() => import('views/authentication/ResetPassword')));
const UserProfile = Loadable(lazy(() => import('views/authentication/UserProfile')));
const AuthVerifyEmail = Loadable(lazy(() => import('views/authentication/VerifyEmail')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/select-role',
            element: <AuthSelectRole />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/verify-email',
            element: <AuthVerifyEmail />
        },
        {
            path: '/profile',
            element: <UserProfile />
        },
        {
            path: '/reset-link/:resetToken',
            element: <AuthResetPassword />
        },
    ]
};

export default LoginRoutes;
