/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'store';
import { LOGIN, LOGOUT } from 'store/actions';
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';

import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import EmailImg from 'assets/images/icons/email.svg';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Button, Typography, Divider, InputLabel, Tabs, Chip, Tab } from '@mui/material';
import { handleBack, handleReset } from 'store/slices/investor';
import CustomTabPanel from 'ui-component/tabs/CustomTabPanel';
import { updateUser, getUser } from 'store/slices/user';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
// ===========================|| JWT - REGISTER ||=========================== //
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const SuccessCard = ({ ...others }, steps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();
    const { activeStep, investor } = useSelector((state) => state.investor);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleComplete = async () => {
        window.location.href = '/pending-approval-page';
    };

    useEffect(() => {
        console.log('investor', investor);
    }, []);

    return (
        <>
            <Grid spacing={2} sx={{ px: 2 }}>
                <Grid container justifyContent="center" direction="column" alignItems="center">
                    <Typography align="center">
                        <img
                            src={EmailImg}
                            sx={{
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer'
                            }}
                            aria-haspopup="true"
                            color="inherit"
                            loading="lazy"
                        />
                    </Typography>
                    <Typography variant="h2" sx={{ color: theme.palette.grey[800], my: 1 }}>
                        Congratulations
                    </Typography>
                    <Typography variant="caption" sx={{ color: theme.palette.grey[800], mt: 3 }}>
                        You have successfully completed your profile.
                    </Typography>
                    <Divider />
                </Grid>
            </Grid>
            {/* Bottom Buttons */}
            <Grid container item xs={12} sm={12} md={12} justifyContent="center" sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <AnimateButton>
                        <Button
                            onClick={handleComplete}
                            disableElevation
                            fullWidth
                            sx={{
                                height: '48px',
                                backgroundColor: theme.palette.secondary.main,
                                color: 'white',
                                mr: 2
                            }}
                            color="secondary"
                            size="large"
                            variant="contained"
                        >
                            Continue to projects <EastIcon sx={{ ml: 'auto' }} />
                        </Button>
                    </AnimateButton>
                </Box>
            </Grid>
        </>
    );
};

export default SuccessCard;
