/* eslint-disable no-underscore-dangle */
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { showInvestor } from 'store/slices/investor';
import CustomizedSteppers from 'ui-component/CustomizedSteppers';
import Loader from 'ui-component/Loader';
import CompanyInformationForm from './CompanyInformationForm';
import RepresentativeForm from './RepresentativeForm';
import SuccessCard from './SuccessCard';
import SummaryForm from './SummaryForm';
import TermsAndConditions from './TermsAndConditions';
import TypeForm from './TypeForm';

// ==============================|| PRODUCT REVIEW LIST ||============================== //
const InvestorOnboarding = () => {
    const { investor, activeStep } = useSelector((state) => state.investor);
    const [loading, setLoading] = React.useState(false);

    const theme = useTheme();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        dispatch(showInvestor(user?.investor?._id));
    }, [id]);

    useEffect(() => {
        if (investor && loading) {
            setLoading(false);
        }
    }, [investor]);

    const steps = ['Company Information', 'Company Representative', 'Investor Type', 'Terms & Conditions', 'Review'];

    const displayForm = (step) => {
        switch (step) {
            case 0:
                return <CompanyInformationForm />;
            case 1:
                return <RepresentativeForm />;
            case 2:
                return <TypeForm />;
            case 3:
                return <TermsAndConditions />;
            case 4:
                return <SummaryForm />;
            default:
                return <SuccessCard />;
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Card content={false} sx={{ boxShadow: 2 }}>
            <Grid
                container
                spacing={2}
                sx={{
                    backgroundColor: theme.palette.grey[200],
                    padding: '18px 22px 10px 22px'
                }}
            >
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h5">Please complete your investor profile</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', ml: 'auto' }}>
                    <Button sx={{ ml: 'auto', backgroundColor: 'white', px: 3, py: 1 }}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize'
                            }}
                        >
                            Step {activeStep + 1} of {steps.length}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>

            <CardContent>
                {/* START stepper  */}
                <CustomizedSteppers steps={steps} activeStep={activeStep} />
                {/* END stepper  */}
                {/* START: Step One content */}
                <Grid sx={{ mt: 3 }} container justifyContent="center" alignItems="center">
                    <Grid item md={12} lg={12} xs={12}>
                        {displayForm(activeStep)}
                    </Grid>
                </Grid>
                {/* END Step one content  */}
            </CardContent>
        </Card>
    );
};

export default InvestorOnboarding;
