/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, SET_INITIALIZED } from 'store/actions';
import accountReducer from 'store/accountReducer';
import { useNavigate } from 'react-router-dom';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { dispatch as storeDispatch } from 'store';
import { toast } from 'react-toastify';
import { getUserConversations } from 'store/slices/chat';
import { getSocket } from 'utils/socket';

// constant
const initialState = {
    error: null,
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (token) => {
    if (token) {
        localStorage.setItem('token', token);
        if (localStorage.getItem('token') === token) {
            let decoded = jwtDecode(token);
            console.log('decoded', decoded);
        } else {
            console.log('Failed to set token in localStorage');
        }
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        if (axios.defaults.headers.common['Authorization'] === `Bearer ${token}`) {
            console.log('Token successfully set in axios headers');
        } else {
            console.log('Failed to set token in axios headers');
        }
    } else {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common.Authorization;
    }
};

function onConnect() {
    // nothing yet
}

function onDisconnect() {
    // nothing yet
}

function receiveMessage(data) {
    toast.info("You've got a new message");
    storeDispatch(getUserConversations());
}

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    // const [hasInitialized, setHasInitialized] = useState(false);

    const logout = () => {
        const socket = getSocket();
        socket?.off('connect', onConnect);
        socket?.off('disconnect', onDisconnect);
        socket?.off('receive_message', receiveMessage);
        setSession(null);
        dispatch({ type: LOGOUT });
        navigate('/login');
        storeDispatch({ type: 'RESET' });
    };

    const refreshAccessToken = useCallback(async () => {
        try {
            const response = await axios.post(
                '/auth/refresh-token',
                {},
                {
                    withCredentials: true
                }
            );

            setSession(response.data.accessToken);

            // Fetch the user data with the new access token
            // const decoded = jwtDecode(response.data.accessToken);
            // const userId = decoded._id;
        } catch (err) {
            console.error('Error refreshing access token:', err);
            // Update the state to indicate an error occurred during the refresh token process
            dispatch({
                type: LOGOUT
            });
            setSession(null);

            // eslint-disable-next-line consistent-return
            // throw new Error('Error refreshing access token.', err);
            return null;
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const token = window.localStorage.getItem('token');
            if (token) {
                const decoded = jwtDecode(token);
                const timeLeft = decoded.exp * 1000 - Date.now();
                if (timeLeft <= 5) {
                    refreshAccessToken();
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let timerId;
        const init = async () => {
            const token = window.localStorage.getItem('token');

            if (token) {
                const decoded = jwtDecode(token);
                const timeLeft = decoded.exp * 1000 - Date.now();
            }

            const latestToken = window.localStorage.getItem('token');
            if (latestToken) {
                axios.defaults.headers.common.Authorization = `Bearer ${latestToken}`;
                const decoded = jwtDecode(latestToken);
                const userId = decoded._id;

                try {
                    const response = await axios.get(`/users/${userId}`);
                    const user = response.data.data;
                    dispatch({ type: LOGIN, payload: { isLoggedIn: true, user } });
                } catch (err) {
                    console.error('Error fetching user:', err);
                }
            } else {
                dispatch({ type: LOGOUT });
            }
        };

        init();

        return () => {
            clearTimeout(timerId);
        };
    }, [refreshAccessToken, setSession]);

    const login = async (email, password) => {
        try {
            const response = await axios.post('/auth/login', { email, password });

            const { token, user } = response.data;
            setSession(token);

            const socket = getSocket();
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });

            return user;
        } catch (error) {
            throw new Error(error.message || error);
        }
    };

    const register = async (email, password, firstName, lastName, phoneNumber, role, country) => {
        try {
            const response = await axios.post('/auth/register', { email, password, firstName, lastName, phoneNumber, role, country });
            return response.data;
        } catch (error) {
            return error;
            // throw new Error(error.msg || error);
        }
    };

    const handleLogout = async () => {
        try {
            await axios.post('/auth/logout');
            logout();
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const forgotPassword = async (email) => {
        try {
            const response = await axios.post('/auth/forgot-password', { email });
            return response.data;
        } catch (error) {
            throw new Error(error.error);
        }
    };

    const resetPassword = async (formData) => {
        try {
            const response = await axios.post('/auth/reset-password', formData);
            return response.data;
        } catch (error) {
            throw new Error('Error resetting password. Please try again later.');
        }
    };

    const verifyEmail = async (email) => {
        try {
            const response = await axios.post('/auth/resend-link', { email });
            return response.data;
        } catch (error) {
            throw new Error(error.error);
        }
    };

    const updateProfile = () => console.log('Update Profile');

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{ ...state, login, logout: handleLogout, register, forgotPassword, resetPassword, verifyEmail, updateProfile }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
