/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { delTechnicalAssistant, getTechnicalAssistants, handleReset, updateTechnicalAssistantAdmin } from 'store/slices/technicalAssistant';
// material-ui
import {
    Box,
    Button,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
// project imports
import { useDispatch, useSelector } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import Chip from 'ui-component/extended/Chip';

// assets
import DeleteIcon from '@mui/icons-material/Delete';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// table sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function truncateString(str, maxLength) {
    if (str.length <= maxLength) return str;
    return `${str.slice(0, maxLength)}...`;
}

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// table header options
const headCells = [
    {
        id: 'companyName',
        numeric: false,
        label: 'Company Name',
        align: 'left'
    },
    {
        id: 'companyWebsite',
        numeric: true,
        label: 'Website',
        align: 'left'
    },
    {
        id: 'repEmail',
        numeric: true,
        label: 'Representative Email',
        align: 'left'
    },
    {
        id: 'companyAddress',
        numeric: true,
        label: 'Location',
        align: 'left'
    },
    {
        id: 'companyOverview',
        numeric: true,
        label: 'Overview',
        align: 'left'
    },
    {
        id: 'KYC',
        numeric: false,
        label: 'KYC',
        align: 'left'
    }
];

// ==============================|| TABLE HEADER ||============================== //

function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, theme, selected }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" sx={{ pl: 3 }}>
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell>
                {numSelected > 0 && (
                    <TableCell padding="none" colSpan={7}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                    </TableCell>
                )}
                {numSelected <= 0 &&
                    headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{
                                fontWeight: 800
                            }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                sx={{ fontWeight: 'bold' }}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden} fontWeight="fontWeightBold">
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                {numSelected <= 0 && (
                    <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 'bold', color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                        >
                            Action
                        </Typography>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    theme: PropTypes.object,
    selected: PropTypes.array,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};
// ==============================|| TABLE HEADER TOOLBAR ||============================== //
const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 ? (
            <Typography color="inherit" variant="h4">
                {numSelected} Selected
            </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
                Submitting Technical Assistant
            </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {numSelected > 0 && (
            <Tooltip title="Delete">
                <IconButton size="large">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        )}
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

// ==============================|| PRODUCT REVIEW LIST ||============================== //
const AdminTechnicalAssistantsList = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { technicalAssistants } = useSelector((state) => state.technicalAssistant);
    // open dialog to add new user
    const [openAddUser, setOpenAddUser] = useState(false);
    const [selectedTechnicalAssistant, setSelectedTechnicalAssistant] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);

    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState({});
    // Initialize state variables for the search query and filter
    const [searchQuery, setSearchQuery] = useState('');
    const [profileCompletedFilter, setProfileCompletedFilter] = useState('');
    const [iskycFilter, setIskycFilter] = useState(''); // "true", "false", or ""

    const [order, setOrder] = useState('asc');
    const [dense] = useState(false);
    const [orderBy, setOrderBy] = useState('_id');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);

    useEffect(() => {
        dispatch(getTechnicalAssistants());
    }, []);

    useEffect(() => {
        setRows(technicalAssistants);
    }, [technicalAssistants]);

    useEffect(() => {}, [selectedTechnicalAssistant]);

    // open dialog to edit user
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteClick = async (org) => {
        await setSelectedTechnicalAssistant(org);
        setConfirmOpen(true);
    };

    const deleteItem = async (id) => {
        await dispatch(delTechnicalAssistant(id));
        dispatch(getTechnicalAssistants());
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    // Add existing user
    const handleClickOpenAddUserDialog = () => {
        navigate('/admin/tas/add');
    };
    const handleCloseAddUserDialog = () => {
        setOpenAddUser(false);
    };

    // Apply filters and search on the technical assistants list
    useEffect(() => {
        let filteredRows = [...technicalAssistants];

        // Remove rows where the technicalAssistant object is missing
        filteredRows = filteredRows.filter((row) => row?.technicalAssistant !== undefined);

        // Search filtering
        if (searchQuery) {
            const properties = [
                'technicalAssistant.companyName',
                'technicalAssistant.companyWebsite',
                'email',
                'technicalAssistant.companyOverview',
                'technicalAssistant.companyAddress',
                'firstName',
                'lastName'
            ];

            filteredRows = filteredRows.filter((row) =>
                properties.some((property) => {
                    const keys = property.split('.');
                    const value = keys.reduce((o, k) => (o || {})[k], row);
                    return value?.toString().toLowerCase().includes(searchQuery.toLowerCase());
                })
            );
        }
        // Profile completion filtering
        if (profileCompletedFilter === 'true') {
            filteredRows = filteredRows.filter(
                (row) =>
                    row?.technicalAssistant?.profileCompleted === true ||
                    (row?.technicalAssistant?.profileCompleted === undefined && row?.technicalAssistant?.termsAccepted === true)
            );
        } else if (profileCompletedFilter === 'false') {
            filteredRows = filteredRows.filter(
                (row) =>
                    row?.technicalAssistant?.profileCompleted === false ||
                    (row?.technicalAssistant?.profileCompleted === undefined && row?.technicalAssistant?.termsAccepted !== true)
            );
        }

        // Iskyc status filtering
        if (iskycFilter === 'true') {
            filteredRows = filteredRows.filter((row) => row?.iskyc === true);
        } else if (iskycFilter === 'false') {
            filteredRows = filteredRows.filter((row) => row?.iskyc === false);
        }

        setRows(filteredRows);
    }, [technicalAssistants, searchQuery, profileCompletedFilter, iskycFilter]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleProfileCompletedFilterChange = (event) => {
        setProfileCompletedFilter(event.target.value);
    };

    const resetProfileFilters = () => {
        setProfileCompletedFilter('');
        setSearchQuery('');
        setIskycFilter('');
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            if (selected.length > 0) {
                setSelected([]);
            } else {
                const newSelectedId = rows.map((n) => n._id);
                setSelected(newSelectedId);
            }
            return;
        }
        setSelected([]);
    };

    const handleClick = (event) => {
        const row = event.currentTarget.closest('tr');
        const rowIndex = row.getAttribute('data-id');
        const selectedIndex = selected.indexOf(rowIndex);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, rowIndex);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        const selectedRowData = rows.filter((row) => newSelected.includes(row._id.toString()));

        setSelectedValue(selectedRowData);
        setSelected(newSelected);
    };

    const handleSave = async () => {
        try {
            // Prepare the updated project data
            const updatedProject = {
                _id: selectedProject.id,
                iskyc: selectedProject.iskyc,
                verified: selectedProject.verified
            };

            // Dispatch the updateTechnicalAssistant action to update the project
            await dispatch(updateTechnicalAssistantAdmin(updatedProject));

            // Update the technicalAssistants list
            dispatch(getTechnicalAssistants());
            setEditModalOpen(false); // Close the modal
            toast.success('TechnicalAssistant updated successfully', { position: toast.POSITION.TOP_CENTER });
        } catch (error) {
            console.error('Error updating project:', error);
            // Handle error if needed
            toast.error('Failed to update project', { position: toast.POSITION.TOP_CENTER });
        }
    };

    const handlePreview = (event, row) => {
        handleReset();
        navigate(`/admin/tas/view/${row._id}`);
    };

    const handleEdit = (event, row) => {
        handleReset();
        navigate(`/admin/users/add/${row._id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const isSelected = (_id) => selected.indexOf(_id) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    if (isLoading) {
        return <></>;
    }

    return (
        <MainCard title="Technical Assistance Providers" content={false}>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search..."
                            value={searchQuery}
                            size="small"
                        />
                    </Grid>
                    <Grid container item xs={12} sm={6} justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    value={profileCompletedFilter}
                                    onChange={handleProfileCompletedFilterChange}
                                    displayEmpty
                                    name="profileCompleted"
                                >
                                    <MenuItem value="" disabled>
                                        Profile Completed
                                    </MenuItem>
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="outlined">
                                <Select value={iskycFilter} onChange={(e) => setIskycFilter(e.target.value)} displayEmpty name="iskyc">
                                    <MenuItem value="" disabled>
                                        KYC Status
                                    </MenuItem>
                                    <MenuItem value="true">Approved</MenuItem>
                                    <MenuItem value="false">Pending</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button fullWidth variant="outlined" size="large" onClick={resetProfileFilters}>
                                Reset Filters
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} size={dense ? 'small' : 'medium'} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        theme={theme}
                        selected={selected}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                /** Make sure no display bugs if row isn't an OrderData object */
                                if (typeof row === 'number') return null;
                                const isItemSelected = isSelected(row._id);
                                // const isItemSelected = isSelected(row._id);
                                // const isItemSelected = selected.indexOf(row._id) !== -2 ? true : false;
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row._id}
                                        selected={isItemSelected}
                                        data-id={row._id}
                                    >
                                        <TableCell padding="checkbox" onClick={(event) => handleClick(event, row._id)} sx={{ pl: 3 }}>
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId
                                                }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleClick(event, row._id);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            onClick={(event) => handleClick(event, row._id)}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}
                                            >
                                                {row?.technicalAssistant?.companyName
                                                    ? `${row?.technicalAssistant?.companyName}`
                                                    : 'Not Complete'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {row?.technicalAssistant?.companyWebsite
                                                ? row?.technicalAssistant?.companyWebsite
                                                : 'Not Complete'}
                                        </TableCell>
                                        <TableCell>{row?.email ? row?.email : 'Not Complete'}</TableCell>
                                        <TableCell>
                                            {row?.technicalAssistant?.companyAddress
                                                ? row?.technicalAssistant?.companyAddress
                                                : 'Not Complete'}
                                        </TableCell>
                                        <TableCell>
                                            {row?.technicalAssistant?.companyOverview
                                                ? truncateString(`${row?.technicalAssistant?.companyOverview}`, 70)
                                                : 'Not Complete'}
                                        </TableCell>

                                        <TableCell align="left">
                                            {row.iskyc === true && <Chip label="Approved" chipcolor="success" size="small" />}
                                            {row.iskyc === false && <Chip label="Pending" chipcolor="warning" size="small" />}
                                        </TableCell>
                                        <TableCell align="start" sx={{ pr: 3 }}>
                                            <Tooltip title="View Details">
                                                <IconButton color="primary" size="large" onClick={(event) => handlePreview(event, row)}>
                                                    <VisibilityTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Dialog
                                                open={editModalOpen}
                                                onClose={() => setEditModalOpen(false)}
                                                aria-labelledby="edit-project-title"
                                                disableBackdropClick={true}
                                                BackdropProps={{
                                                    style: {
                                                        backgroundColor: 'rgba(0, 0, 0, 0.1)' // Very light overlay, almost transparent
                                                    }
                                                }}
                                                PaperProps={{
                                                    style: {
                                                        width: '400px', // fixed width of the modal
                                                        height: '400px' // fixed height of the modal
                                                    }
                                                }}
                                            >
                                                <DialogTitle>Edit {selectedProject.companyName}</DialogTitle>
                                                <DialogContent>
                                                    <Stack>
                                                        <FormControl fullWidth margin="dense">
                                                            <InputLabel>Verification</InputLabel>
                                                            <Select
                                                                value={selectedProject.verified}
                                                                onChange={(e) =>
                                                                    setSelectedProject((prev) => ({ ...prev, verified: e.target.value }))
                                                                }
                                                            >
                                                                <MenuItem value="true">Verify</MenuItem>
                                                                <MenuItem value="false">Revoke</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl fullWidth margin="dense">
                                                            <InputLabel>KYC</InputLabel>
                                                            <Select
                                                                value={selectedProject.iskyc}
                                                                onChange={(e) =>
                                                                    setSelectedProject((prev) => ({ ...prev, iskyc: e.target.value }))
                                                                }
                                                            >
                                                                <MenuItem value="true">Complete</MenuItem>
                                                                <MenuItem value="false">Incomplete</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => setEditModalOpen(false)} color="primary">
                                                        Cancel
                                                    </Button>
                                                    <Button onClick={handleSave} color="secondary">
                                                        Save
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Tooltip title="Edit ">
                                                <IconButton color="secondary" onClick={(event) => handleEdit(event, row)}>
                                                    <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                {/* User edit dialog */}
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default AdminTechnicalAssistantsList;
