// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const SET_INITIALIZED = 'SET_INITIALIZED';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action dataroom reducer 
export const CREATE_DEALROOM = 'CREATE_DEALROOM';
export const DELETE_DEALROOM = 'DELETE_DEALROOM';
export const UPDATE_DEALROOM = 'UPDATE_DEALROOM';
