import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { COUNTRIES } from 'utils/Constants';
import AnimateButton from 'ui-component/extended/AnimateButton';

// material-ui
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { FormHelperText, Grid, Checkbox, TextField, Typography, FormControlLabel, Box, Button } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { handleBack, handleNext, updateSubmittingOrg } from 'store/slices/submittingOrganization';

// ===========================|| JWT - REGISTER ||=========================== //

const SettingForm = ({ ...others }, steps) => {
    const { activeStep, submittingOrganization } = useSelector((state) => state.submittingOrganization);

    const theme = useTheme();
    const dispatch = useDispatch();

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    _id: submittingOrganization?._id ?? '',
                    iskyc: submittingOrganization.iskyc,
                    verified: submittingOrganization.verified,
                    submit: null
                }}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    dispatch(updateSubmittingOrg(values));
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your company information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    KYC status
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.iskyc}
                                            error={Boolean(touched.iskyc && errors.iskyc)}
                                            name="iskyc"
                                            color="primary"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={() => setFieldValue("iskyc", !values.iskyc)}
                                            sx={{
                                                color: theme.palette.success.main,
                                                '&.Mui-checked': { color: theme.palette.success.main }
                                            }}
                                        />
                                    }
                                    label="Is KYC complete?"
                                />
                                {touched.iskyc && errors.iskyc && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.iskyc}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Verified?
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.verified}
                                            error={Boolean(touched.verified && errors.verified)}
                                            name="verified"
                                            color="primary"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={() => setFieldValue("verified", !values.verified)}
                                            sx={{
                                                color: theme.palette.success.main,
                                                '&.Mui-checked': { color: theme.palette.success.main }
                                            }}
                                        />
                                    }
                                    label="Organisation verified?"
                                />
                                {touched.iskyc && errors.iskyc && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.iskyc}
                                    </FormHelperText>
                                )}
                            </Grid>
                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Save & Complete <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default SettingForm;
