/* eslint-disable no-else-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const RoleGuard = ({ children, role }) => {
    const { user, isLoggedIn } = useAuth();
    const navigate = useNavigate();

    const defaultPaths = {
        admin: '/admin/projects',
        owner: '/owner/projects',
        investor: '/investor/projects',
        submittingOrganization: user?.organization?.termsAccepted ? '/so/projects' : `/so/onboarding/${user?.organization_id}`,
        technicalAssistant: '/ta/projects'
    };

    const roleActions = {
        owner: () => !user?.verified && navigate(`/pending-verification-page`, { replace: true }),
        investor: () => {
            if (user?.investor?.status === 'draft') {
                navigate(`/investor/onboarding/${user?.investor?._id}`, { replace: true });
                return true;
            } else if (!user?.investor?.iskyc) {
                navigate(`/pending-approval-page`, { replace: true });
                return true;
            }
            return false;
        },
        submittingOrganization: () => {
            if (!user?.organization?.termsAccepted) {
                navigate(`/so/onboarding/${user?.organization?._id}`, { replace: true });
                return true;
            }
            return false;
        },
        technicalAssistant: () => {
            if (!user?.technicalAssistant?.profileCompleted) {
                navigate(`/ta/onboarding/${user?.technicalAssistant?._id}`, { replace: true });
                return true;
            } else if (!user?.iskyc) {
                navigate(`/pending-approval-page`, { replace: true });
                return true;
            }
            return false;
        },
        admin: () => false
    };

    useEffect(() => {
        if (isLoggedIn) {
            const userRole = user?.role;
            let navigated = true;

            navigated = roleActions[userRole]();
            if (!navigated && !role.includes(userRole)) {
                navigate(defaultPaths[userRole], { replace: true });
            }
        }
    }, [user, isLoggedIn, navigate]);

    return children;
};

RoleGuard.propTypes = {
    children: PropTypes.node
};

export default RoleGuard;
