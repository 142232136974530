/* eslint-disable jsx-a11y/aria-role */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

import RoleGuard from 'utils/route-guard/RoleGuard';
import InvestorOnboarding from '../views/investor/onboarding';
import PoProjects from '../views/owner/projects';
import SoProjects from '../views/submitting-organization/projects';
import OrganisationOnboarding from '../views/submitting-organization/onboarding';

import ProjectPreviewOwner from 'views/owner/projects/ProjectPreview';
import AdminRoutes from './AdminRoutes';
import InvestorRoutes from './InvestorRoutes';
import Profile1 from 'views/admin/Settings';
import TARoutes from './TechnicalAssistantRoutes';
import User from 'store/slices/user';

// dashboard routing
const DataRoomInvestor = Loadable(lazy(() => import('views/investor/dataroom')));
const DataRoomOwner = Loadable(lazy(() => import('views/owner/dataroom')));
const ProjectsInvestor = Loadable(lazy(() => import('views/investor/projects')));
const SelectedProjectsInvestor = Loadable(lazy(() => import('views/investor/selectedProjects')));

const MaintenanceError = Loadable(lazy(() => import('views/maintenance/Error')));
// const UserProfile = Loadable(lazy(() => import('views/authentication/UserProfile')));
const PoUserProfile = Loadable(lazy(() => import('views/owner/UserProfile')));
const AddDataRoom = Loadable(lazy(() => import('views/admin/dataroom/add')));

const IncompleteProjects = Loadable(lazy(() => import('views/authentication/IncompleteProjects')));
const PoIncompleteProjects = Loadable(lazy(() => import('views/owner/IncompleteProjects')));
const GrantAccess = Loadable(lazy(() => import('views/owner/requests/GrantAccess')));
const MeetInvestor = Loadable(lazy(() => import('views/owner/requests/MeetInvestor')));
const InsideDataRoomOwner = Loadable(lazy(() => import('views/owner/projects/InsideDataRoom')));
const TestPage = Loadable(lazy(() => import('views/default-pages/pendingApproval')));
const Projects = Loadable(lazy(() => import('views/project/list')));
// Technical Assistant List for Owner
const TAsListForOwner = Loadable(lazy(() => import('views/owner/listTechnicalAssistants')));
const TAsOverviewForOwner = Loadable(lazy(() => import('views/owner/listTechnicalAssistants/view')));
const TAsQuotesForOwner = Loadable(lazy(() => import('views/owner/quotes')));
const TAsQuoteOverviewForOwner = Loadable(lazy(() => import('views/owner/quotes/view')));
const PendingApproval = Loadable(lazy(() => import('views/default-pages/pendingApproval')));
const PendingVerification = Loadable(lazy(() => import('views/default-pages/pendingVerification')));
const EndMeeting = Loadable(lazy(() => import('views/default-pages/endMeeting')));

const Requests = Loadable(lazy(() => import('views/AccessRequests')));
const RequestView = Loadable(lazy(() => import('views/AccessRequests/view')));
const UserProfile = Loadable(lazy(() => import('views/account-profile')));

// Owner Pitch Events
const OwnerCalendar = Loadable(lazy(() => import('views/owner/pitchEvents/calendar')));

const Calendar = Loadable(lazy(() => import('views/calendar')));
const PitchRoom = Loadable(lazy(() => import('views/pitchRoom')));
const Chat = Loadable(lazy(() => import('views/chat')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout onboardProject={false} />
        </AuthGuard>
    ),
    children: [
        // Admin routes
        ...AdminRoutes,
        {
            path: '/so/onboarding/:id?',
            element: (
                <RoleGuard role="submittingOrganization">
                    <OrganisationOnboarding />
                </RoleGuard>
            )
        },
        {
            path: '/so/projects',
            element: (
                <RoleGuard role="submittingOrganization">
                    <Projects />
                </RoleGuard>
            )
        },
        {
            path: '/settings',
            element: (
                <RoleGuard role="admin">
                    <Profile1 />
                </RoleGuard>
            )
        },

        /* Technical Assistant Routes */
        ...TARoutes,
        /* Technical Assistant Pages End */

        {
            path: '/admin/incomplete-projects',
            element: (
                <RoleGuard role="admin">
                    <IncompleteProjects />
                </RoleGuard>
            )
        },
        {
            path: '/admin/profile',
            element: (
                <RoleGuard role="admin">
                    <UserProfile />
                </RoleGuard>
            )
        },
        {
            path: '/admin/dataroom/add',
            element: (
                <RoleGuard role="admin">
                    <AddDataRoom />
                </RoleGuard>
            )
        },

        // Project owner
        {
            path: '/owner/projects',
            element: (
                <RoleGuard role="owner">
                    <Projects />
                </RoleGuard>
            )
        },
        {
            path: '/owner/requests',
            element: (
                <RoleGuard role="owner">
                    <Requests />
                </RoleGuard>
            )
        },
        {
            path: '/owner/list-technical-assistants',
            element: (
                <RoleGuard role="owner">
                    <TAsListForOwner />
                </RoleGuard>
            )
        },
        {
            path: '/owner/list-technical-assistants/:id',
            element: (
                <RoleGuard role="owner">
                    <TAsOverviewForOwner />
                </RoleGuard>
            )
        },
        {
            path: '/owner/technical-assistants-quotes',
            element: (
                <RoleGuard role="owner">
                    <TAsQuotesForOwner />
                </RoleGuard>
            )
        },
        {
            path: '/owner/technical-assistants-quotes/:id',
            element: (
                <RoleGuard role="owner">
                    <TAsQuoteOverviewForOwner />
                </RoleGuard>
            )
        },
        {
            path: '/owner/data-room',
            element: (
                <RoleGuard role="owner">
                    <DataRoomOwner />
                </RoleGuard>
            )
        },
        {
            path: '/owner/profile',
            element: (
                <RoleGuard role="owner">
                    <PoUserProfile />
                </RoleGuard>
            )
        },
        {
            path: '/owner/incomplete-projects',
            element: (
                <RoleGuard role="owner">
                    <PoIncompleteProjects />
                </RoleGuard>
            )
        },
        {
            path: '/owner/grant-access',
            element: (
                <RoleGuard role="owner">
                    <GrantAccess />
                </RoleGuard>
            )
        },
        {
            path: '/owner/meet-investor',
            element: (
                <RoleGuard role="owner">
                    <MeetInvestor />
                </RoleGuard>
            )
        },
        {
            path: '/owner/inside-data-room',
            element: (
                <RoleGuard role="owner">
                    <InsideDataRoomOwner />
                </RoleGuard>
            )
        },

        {
            path: '/owner/project/preview/',
            element: (
                <RoleGuard role="owner">
                    <ProjectPreviewOwner />
                </RoleGuard>
            )
        },
        {
            path: '/owner/requests/:id',
            element: (
                <RoleGuard role="owner">
                    <RequestView />
                </RoleGuard>
            )
        },
        // Owner Pitch Events
        {
            path: '/owner/calendar/',
            element: (
                <RoleGuard role={['investor', 'owner', 'technicalAssistant']}>
                    <OwnerCalendar />
                </RoleGuard>
            )
        },
        {
            path: '/calendar/',
            element: (
                <RoleGuard role={['investor', 'owner', 'technicalAssistant']}>
                    <Calendar />
                </RoleGuard>
            )
        },
        {
            path: '/pitch-room',
            element: (
                <RoleGuard role={['investor', 'owner', 'technicalAssistant']}>
                    <PitchRoom />
                </RoleGuard>
            )
        },
        {
            path: '/chat',
            element: (
                <RoleGuard role={['investor', 'owner', 'technicalAssistant']}>
                    <Chat />
                </RoleGuard>
            )
        },

        // Investor routes
        ...InvestorRoutes,
        {
            path: '/user/account-profile',
            element: <UserProfile />
        },

        {
            path: 'pending-approval-page',
            element: (
                <RoleGuard role={['investor', 'submittingOrganization', 'owner', 'technicalAssistant']}>
                    <PendingApproval />
                </RoleGuard>
            )
        },
        {
            path: 'pending-verification-page',
            element: <PendingVerification />
        },
        {
            path: 'end-meeting',
            element: <EndMeeting />
        },

        {
            path: '*',
            element: <MaintenanceError />
        }
    ]
};

export default MainRoutes;
