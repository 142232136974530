/* eslint-disable jsx-a11y/aria-role */
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/route-guard/RoleGuard';

const ProjectOnboarding = Loadable(lazy(() => import('views/project/onboarding')));
const ProjectEdit = Loadable(lazy(() => import('views/project/onboarding/edit')));
const Projects = Loadable(lazy(() => import('views/project/list')));
const ProjectView = Loadable(lazy(() => import('views/project/view')));

const DashboardAdmin = Loadable(lazy(() => import('views/dashboard/Admin')));
const DataRoomAdmin = Loadable(lazy(() => import('views/admin/dataroom')));

const UserAdd = Loadable(lazy(() => import('views/admin/users/onboarding/UserAdd')));
const UserList = Loadable(lazy(() => import('views/admin/users/onboarding/UserList')));
const UserView = Loadable(lazy(() => import('views/admin/users/onboarding/UserView')));
const InvestorView = Loadable(lazy(() => import('views/admin/investor/onboarding/InvestorView')));

const AdminOrganisationOnboarding = Loadable(lazy(() => import('views/submitting-organization/onboarding')));
const AdminInvestorOnboarding = Loadable(lazy(() => import('views/admin/investor/onboarding')));
const OrgPreview = Loadable(lazy(() => import('views/submitting-organization/onboarding/Preview')));
const ProjectPreviewAdmin = Loadable(lazy(() => import('views/admin/projects/ProjectPreview')));
const AdminProjectsList = Loadable(lazy(() => import('views/admin/projects/list')));
const AdminInvestorsList = Loadable(lazy(() => import('views/admin/investor/list')));
const PitchRoomCalendar = Loadable(lazy(() => import('views/pitchRoom/calendar')));

const AdminSubmittingOrgsList = Loadable(lazy(() => import('views/admin/submittingOrganization/list')));
const TechnicalAssistantsList = Loadable(lazy(() => import('views/technicalAssistant/TAList')));
const TechnicalAssistantView = Loadable(lazy(() => import('views/technicalAssistant/TAList/technicalAssistant')));

const AdminRoutes = [
    {
        path: '/admin/dashboard/',
        element: (
            <RoleGuard role="admin">
                <DashboardAdmin />
            </RoleGuard>
        )
    },
    {
        path: '/admin/projects',
        element: (
            <RoleGuard role={['admin']}>
                <AdminProjectsList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/investors',
        element: (
            <RoleGuard role="admin">
                <AdminInvestorsList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/pitch-room',
        element: (
            <RoleGuard role="admin">
                <PitchRoomCalendar />
            </RoleGuard>
        )
    },
    {
        path: '/admin/orgs',
        element: (
            <RoleGuard role="admin">
                <AdminSubmittingOrgsList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/tas',
        element: (
            <RoleGuard role="admin">
                <TechnicalAssistantsList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/tas/view/:id',
        element: (
            <RoleGuard role="admin">
                <TechnicalAssistantView />
            </RoleGuard>
        )
    },
    {
        path: '/projects/add',
        element: (
            <RoleGuard role={['submittingOrganization', 'owner', 'admin']}>
                <ProjectOnboarding />
            </RoleGuard>
        )
    },
    {
        path: '/projects/add/:id',
        element: (
            <RoleGuard role={['submittingOrganization', 'owner', 'admin']}>
                <ProjectEdit />
            </RoleGuard>
        )
    },
    {
        path: '/projects/:id',
        element: (
            <RoleGuard role={['submittingOrganization', 'owner', 'admin', 'investor', 'technicalAssistant']}>
                <ProjectView />
            </RoleGuard>
        )
    },
    {
        path: '/admin/dataroom/preview/:id',
        element: (
            <RoleGuard role="admin">
                <ProjectPreviewAdmin />
            </RoleGuard>
        )
    },
    {
        path: '/admin/data-room/',
        element: (
            <RoleGuard role="admin">
                <DataRoomAdmin />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/',
        element: (
            <RoleGuard role="admin">
                <UserList />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/add/:id?',
        element: (
            <RoleGuard role="admin">
                <UserAdd />
            </RoleGuard>
        )
    },
    {
        path: '/admin/users/view/:id?',
        element: (
            <RoleGuard role="admin">
                <UserView />
            </RoleGuard>
        )
    },

    {
        path: '/admin/investors/view/:id?',
        element: (
            <RoleGuard role="admin">
                <InvestorView />
            </RoleGuard>
        )
    },
    {
        path: '/admin/orgs/add/:id?',
        element: (
            <RoleGuard role="admin">
                <AdminOrganisationOnboarding />
            </RoleGuard>
        )
    },
    {
        path: '/admin/orgs/view/:id',
        element: (
            <RoleGuard role="admin">
                <OrgPreview />
            </RoleGuard>
        )
    },
    {
        path: '/admin/investor/add/:id?',
        element: (
            <RoleGuard role="admin">
                <AdminInvestorOnboarding />
            </RoleGuard>
        )
    },
    {
        path: '/admin/project-preview/selected',
        element: (
            <RoleGuard role="admin">
                <ProjectPreviewAdmin />
            </RoleGuard>
        )
    }
];

export default AdminRoutes;
