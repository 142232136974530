// third-party
import { FormattedMessage } from 'react-intl';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonIcon from '@mui/icons-material/Person';
import ListAltIcon from '@mui/icons-material/ListAlt';
// import icon to represent deal room
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChatIcon from '@mui/icons-material/Chat';
// ==============================|| PROJECT OWNER MENU ITEMS ||============================== //
const investor = {
    id: 'investor',
    title: <FormattedMessage id="investor" defaultMessage="Investor" />,
    icon: SpaceDashboardIcon,
    type: 'group',
    children: [
        {
            id: 'inv-all-projects',
            title: <FormattedMessage id="Projects" defaultMessage="All Projects" />,
            type: 'item',
            url: '/investor/projects',
            icon: SolarPowerIcon,
            breadcrumbs: false
        },
        {
            id: 'inv-requested-projects',
            title: <FormattedMessage id="Projects" defaultMessage="Requested" />,
            type: 'item',
            url: '/investor/requested-projects',
            icon: SolarPowerIcon,
            breadcrumbs: false
        },
        {
            id: 'po-chat',
            title: <FormattedMessage id="chat" defaultMessage="Chat" />,
            type: 'item',
            url: '/chat',
            icon: ChatIcon,
            breadcrumbs: false
        },
        {
            id: 'deal_room',
            title: <FormattedMessage id="Deal Room" defaultMessage="Deal Room" />,
            type: 'collapse',
            icon: MeetingRoomIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'deals',
                    title: <FormattedMessage id="Deals" defaultMessage="Deals" />,
                    type: 'item',
                    icon: AssignmentIcon,
                    url: '/investor/deals',
                    breadcrumbs: false
                },
                {
                    id: 'my_calendar',
                    title: <FormattedMessage id="my-calendar" defaultMessage="My Calendar" />,
                    type: 'item',
                    url: '/calendar',
                    icon: CalendarMonthOutlinedIcon,
                    breadcrumbs: false
                },
                {
                    id: 'pitch_room',
                    title: <FormattedMessage id="Pitch Room" defaultMessage="Pitch Room" />,
                    type: 'item',
                    url: '/pitch-room',
                    icon: MeetingRoomIcon,
                    breadcrumbs: false
                }
            ]
        }
        // {
        //     id: 'my_profile',
        //     title: <FormattedMessage id="my_profile" defaultMessage="My Profile" />,
        //     type: 'item',
        //     url: '/investor/profile',
        //     icon: PersonIcon,
        //     breadcrumbs: false
        // }
    ]
};

export default investor;
