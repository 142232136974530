/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    project: {},
    success: false,
    projects: []
};

const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        postProjectSuccess(state, action) {
            state.project = action.payload;
            state.success = true;
        },
        patchProjectSuccess(state, action) {
            state.project = action.payload;
        },
        getProjectSuccess(state, action) {
            state.project = action.payload;
        },
        deleteSuccess(state, action) {
            state.user = action.payload;
        },
        getProjectsSuccess(state, action) {
            state.projects = action.payload;
        },
        getUserSpecificProjectsSuccess(state, action) {
            state.projects = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getProject(id) {
    return async () => {
        try {
            const response = await axios.get(`/projects/${id}`);
            dispatch(slice.actions.getProjectSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProjects() {
    return async () => {
        try {
            const response = await axios.get(`/projects`);
            dispatch(slice.actions.getProjectsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postProject(id) {
    return async () => {
        try {
            const response = await axios.post(`/projects`, id);
            dispatch(slice.actions.postProjectSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateProject(data) {
    return async () => {
        try {
            const response = await axios.post(`/projects/update/${data._id}`, data);
            dispatch(slice.actions.getProjectSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteProject(id) {
    return async () => {
        try {
            const response = await axios.delete(`/projects/${id}`);
            dispatch(slice.actions.deleteSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function saveProjectStepOne(data) {
    const formData = new FormData();
    data = { ...data, location: JSON.stringify(data.location), otherThematicAreas: JSON.stringify(data.otherThematicAreas) };
    for (const field in data) {
        if ((data[field] !== null && typeof data[field] !== 'object') || data[field] instanceof File || data[field] instanceof Blob) {
            formData.append(field, data[field]);
        }
    }
    return axios.post(`/projects`, formData);
}
export function patchProjectStepOne(id, data) {
    data = { ...data, location: JSON.stringify(data.location), otherThematicAreas: JSON.stringify(data.otherThematicAreas) };
    const { image, ...rest } = data;
    if (image instanceof File) {
        rest.image = image;
    }
    const formData = new FormData();
    for (const field in rest) {
        if ((data[field] !== null && typeof data[field] !== 'object') || data[field] instanceof File || data[field] instanceof Blob) {
            formData.append(field, data[field]);
        }
    }
    return axios.patch(`/projects/${id}`, formData);
}
export function saveProjectStepTwo(id, data) {
    const formData = new FormData();
    data = { ...data, keyStakeholders: JSON.stringify(data.keyStakeholders) };
    for (const field in data) {
        if ((data[field] !== null && typeof data[field] !== 'object') || data[field] instanceof File || data[field] instanceof Blob) {
            formData.append(field, data[field]);
        }
    }
    formData.append('step', 2);
    return axios.patch(`/projects/step-two/${id}`, formData);
}
export function saveProjectStepThree(id, data) {
    const formData = new FormData();
    for (const field in data) {
        if ((data[field] !== null && typeof data[field] !== 'object') || data[field] instanceof File || data[field] instanceof Blob) {
            formData.append(field, data[field]);
        }
    }
    formData.append('step', 3);
    return axios.patch(`/projects/step-three/${id}`, formData);
}
export function saveProjectStepFour(id, data) {
    const formData = new FormData();
    data = { ...data, capitalRaises: JSON.stringify(data.capitalRaises) };
    for (const field in data) {
        if ((data[field] !== null && typeof data[field] !== 'object') || data[field] instanceof File || data[field] instanceof Blob) {
            formData.append(field, data[field]);
        }
    }
    formData.append('step', 4);
    return axios.patch(`/projects/step-four/${id}`, formData);
}
export function saveProjectStepFive(id, data) {
    const formData = new FormData();
    data = { ...data, SDGGoals: JSON.stringify(data.SDGGoals) };
    for (const field in data) {
        if ((data[field] !== null && typeof data[field] !== 'object') || data[field] instanceof File || data[field] instanceof Blob) {
            formData.append(field, data[field]);
        }
    }
    formData.append('step', 5);
    return axios.patch(`/projects/step-five/${id}`, formData);
}
export function submitProject(id) {
    return axios.post(`/projects/submit/${id}`);
}

export function resetProject() {
    return async () => {
        dispatch(slice.actions.getProjectSuccess({}));
    };
}

export function setProject(project) {
    return async () => {
        dispatch(slice.actions.getProjectSuccess(project));
    };
}

export function getUserSpecificProjects(userId) {
    return async () => {
        try {
            const response = await axios.get(`/projects/owner/${userId}`);
            dispatch(slice.actions.getUserSpecificProjectsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function sendReminderToIncompleteProjects() {
    return axios.post(`/projects/send-reminder`);
}
