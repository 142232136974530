import PropTypes from 'prop-types';

// material-ui
import { FormControl, FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import useConfig from 'hooks/useConfig';

const BFormControlLabel = styled((props) => <MuiFormControlLabel {...props} />)(({ theme, checked }) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.borderRadius,
    padding: '4px',
    backgroundColor: checked ? theme.palette.success.main : 'transparent', // Change background color when checked
    borderColor: checked ? theme.palette.success.light : theme.palette.grey[400] // Change border color when checked
}));

const FormControlLabel = ({ children, ...others }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    return (
        <BFormControlLabel {...others} sx={{ borderRadius: borderRadius }}>
            {children}
        </BFormControlLabel>
    );
};

FormControlLabel.propTypes = {
    children: PropTypes.node
};

export default FormControlLabel;
