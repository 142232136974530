/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { ALL_THEMATIC_GROUPS, DEAL_TYPES, PROJECT_STATUS } from 'utils/Constants';
// material-ui
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';

// third party
import { useFormik } from 'formik';
import FormControlLabel from 'ui-component/extended/Form/FormControlLabel';
import * as yup from 'yup';

// project imports
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { handleBack, handleNext, updateInvestor } from 'store/slices/investor';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import usePlacesAutocomplete from 'use-places-autocomplete';

// ===========================|| JWT - REGISTER ||=========================== //

const TypeForm = ({ ...others }, steps) => {
    const { activeStep, investor } = useSelector((state) => state.investor);
    const [selected, setSelected] = useState(``);
    const [selectedInvestorType, setSelectedInvestorType] = useState([]);

    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        setSelected(investor.investorType);
    }, []);

    const investorTypes = [
        {
            value: 'institutionalInvestor',
            label: 'Institutional Investor'
        },
        {
            value: 'ventureCapitalFirm',
            label: 'Venture Capital Firm'
        },
        {
            value: 'privateEquityFirm',
            label: 'Private Equity Firm'
        },
        {
            value: 'DFI',
            label: 'DFI'
        },
        {
            value: 'Donor',
            label: 'Donor'
        },
        {
            value: 'Government',
            label: 'Government'
        },
        {
            value: 'other',
            label: 'Other(Please Specify)'
        }
    ];

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    const getInitValues = () => ({
        _id: investor?._id || '',
        repEmail: investor?.repEmail || '',
        repAddress: investor?.repAddress || '',
        repPhone: investor?.repPhone || '',
        repName: investor?.repName || '',
        investorType: investor?.investorType || '',
        projectStage: investor?.projectStage || '',
        otherInvestorType: investor?.otherInvestorType || '',
        projectLocation: investor?.projectLocation ?? '',
        previousRaise: investor?.previousRaise || '',
        dealType: investor?.dealType || '',
        targetAmount: investor?.targetAmount || '',
        otherThematicAreas: investor?.otherThematicAreas || [],
        submit: null
    });
    const validationSchema = yup.object({
        otherThematicAreas: yup.array().of(yup.string().required('Thematic area is required')).min(1, 'At least one thematic  area is required'),
        projectLocation: yup
            .string()
            .required('Project Location is required and must be valid')
            .matches(/^[A-Za-z,\s]+$/, 'Project Location must only contain letters, commas, and spaces'),
        investorType: yup.string().required('Investor type is required')
    });
    const formik = useFormik({
        initialValues: getInitValues(),
        validationSchema,
        onSubmit: async (values) => {
            console.log('values', values);
            await dispatch(updateInvestor(values));
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Your company information was saved successfully',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            handleNext();
        }
    });
    return (
        <>
            <form onSubmit={formik.handleSubmit} id="validation-forms" encType="multipart/form-data">
                {formik.isSubmitting && <Loader />}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Select the type of investor you are.
                        </Typography>
                        <FormControl sx={{ mt: 3 }} error={formik.touched.scope && Boolean(formik.errors.scope)}>
                            <FormGroup value={formik.values.scope} onChange={formik.handleChange} name="scope">
                                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={gridSpacing}>
                                    {investorTypes.map((el) => (
                                        <AnimateButton xs={12} sm={12} md={6}>
                                            <Button
                                                size="small"
                                                disableElevation
                                                justifyContent="start"
                                                sx={{
                                                    color: selected === el.value ? theme.palette.primary.dark : '',
                                                    backgroundColor:
                                                        selected === el.value
                                                            ? theme.palette.success.light
                                                            : theme.palette.primary.primaryLight,
                                                    borderRadius: 10,
                                                    mr: 1
                                                }}
                                                key={el.value}
                                                value={el.value}
                                                variant="outlined"
                                                name="investorType"
                                                onClick={(event) => {
                                                    const type = event.currentTarget.value;
                                                    setSelected(type);
                                                    formik.setFieldValue('investorType', type);
                                                    if (type !== `other`) {
                                                        formik.setFieldValue('otherInvestorType', ``);
                                                    }
                                                }}
                                            >
                                                <Radio
                                                    error={formik.touched.investorType && Boolean(formik.errors.investorType)}
                                                    helperText={formik.touched.investorType && formik.errors.investorType}
                                                    onChange={(event) => {
                                                        const type = event.currentTarget.value;
                                                        setSelected(type);
                                                        formik.setFieldValue('investorType', type);
                                                        if (type !== `other`) {
                                                            formik.setFieldValue('otherInvestorType', ``);
                                                        }
                                                    }}
                                                    key={el.value}
                                                    value={el.value}
                                                    checked={selected === el.value}
                                                    sx={{
                                                        color: theme.palette.success.main,
                                                        '&.Mui-checked': { color: theme.palette.success.main }
                                                    }}
                                                />
                                                {el.label}
                                            </Button>
                                        </AnimateButton>
                                    ))}
                                </Stack>
                            </FormGroup>
                        </FormControl>
                        {formik.touched.investorType && formik.errors.investorType && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.investorType}
                            </FormHelperText>
                        )}
                    </Grid>
                    {selected === `other` ? (
                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                error={Boolean(formik.touched.otherInvestorType && formik.errors.otherInvestorType)}
                                type="text"
                                placeholder="Please specify the type of investor you are"
                                variant="standard"
                                value={formik.values.otherInvestorType}
                                name="otherInvestorType"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {formik.touched.otherInvestorType && formik.errors.otherInvestorType && (
                        <FormHelperText error id="standard-weight-helper-text--register">
                            {formik.errors.otherInvestorType}
                        </FormHelperText>
                    )}

                    <Grid item xs={12} sm={12} md={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Thematic Areas
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel> Secondary thematic areas </InputLabel>
                        <FormControl error={formik.touched.otherThematicAreas && Boolean(formik.errors.otherThematicAreas)} sx={{ mr: 2 }}>
                            <FormGroup
                                value={formik.values.otherThematicAreas}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        // Add the value to the array
                                        formik.setFieldValue('otherThematicAreas', [
                                            ...formik.values.otherThematicAreas,
                                            event.target.value
                                        ]);
                                    } else {
                                        // Remove the value from the array
                                        formik.setFieldValue(
                                            'otherThematicAreas',
                                            formik.values.otherThematicAreas.filter((item) => item !== event.target.value)
                                        );
                                    }
                                }}
                                name="otherThematicAreas"
                            >
                                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={gridSpacing}>
                                    {ALL_THEMATIC_GROUPS.map((type, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={type}
                                            control={<Checkbox checked={formik.values.otherThematicAreas.includes(type)} />}
                                            label={type}
                                            sx={{ margin: 2, padding: 2 }}
                                        />
                                    ))}
                                </Stack>
                            </FormGroup>
                        </FormControl>
                        {formik.touched.otherThematicAreas && formik.errors.otherThematicAreas && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.otherThematicAreas}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Project Stage
                        </Typography>
                        <Stack>
                            <TextField
                                id="projectStage"
                                name="projectStage"
                                fullWidth
                                select
                                value={formik.values.projectStage}
                                onChange={formik.handleChange}
                                error={formik.touched.projectStage && Boolean(formik.errors.projectStage)}
                                helperText={formik.touched.projectStage && formik.errors.projectStage}
                                required
                            >
                                {PROJECT_STATUS.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                        {formik.touched.projectStage && formik.errors.projectStage && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.projectStage}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Project Location
                        </Typography>
                        <Autocomplete
                            id="projectLocation"
                            name="projectLocation"
                            options={status === 'OK' ? data : []}
                            isOptionEqualToValue={(option, value) => option.label === value?.description}
                            autoHighlight
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                            renderOption={(props, option) => (
                                <li {...props} style={{ fontSize: 15 }} value={option.label}>
                                    {option.description}
                                </li>
                            )}
                            onChange={(e, val) => {
                                if (val) {
                                    formik.setFieldValue('projectLocation', val.description);
                                } else {
                                    formik.setFieldValue('projectLocation', '');
                                }
                            }}
                            value={value || formik.values.projectLocation}
                            onInputChange={(e, value) => {
                                setValue(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    disabled={!ready}
                                    error={formik.touched.projectLocation && Boolean(formik.errors.projectLocation)}
                                    helperText={formik.touched.projectLocation && formik.errors.projectLocation}
                                    placeholder="e.g Kampala, Uganda"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {ready ? null : <CircularProgress color="inherit" size={20} />}
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Previous raise
                        </Typography>
                        <Stack>
                            <TextField
                                id="previousRaise"
                                name="previousRaise"
                                fullWidth
                                select
                                value={formik.values.previousRaise}
                                onChange={formik.handleChange}
                                error={formik.touched.previousRaise && Boolean(formik.errors.previousRaise)}
                                helperText={formik.touched.previousRaise && formik.errors.previousRaise}
                                required
                            >
                                {[`No Prior Raise`, `Atleast 1 Prior Raise`].map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                        {formik.touched.previousRaise && formik.errors.previousRaise && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.previousRaise}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Deal Type
                        </Typography>
                        <Select
                            id="dealType"
                            name="dealType"
                            onChange={(event) => {
                                formik.setFieldValue('fundingType', '');
                                formik.handleChange(event);
                            }}
                            value={formik.values.dealType}
                            error={formik.touched.dealType && Boolean(formik.errors.dealType)}
                            fullWidth
                            required
                        >
                            {Object.keys(DEAL_TYPES).map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.touched.dealType && formik.errors.dealType && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.dealType}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Project Target Amount
                        </Typography>
                        <TextField
                            fullWidth
                            error={Boolean(formik.touched.targetAmount && formik.errors.targetAmount)}
                            type="number"
                            variant="outlined"
                            placeholder="e.g 20,0000,000"
                            value={formik.values.targetAmount}
                            name="targetAmount"
                            autoComplete="off"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.targetAmount && formik.errors.targetAmount && (
                            <FormHelperText error id="standard-weight-helper-text--register">
                                {formik.errors.targetAmount}
                            </FormHelperText>
                        )}
                    </Grid>

                    {/* Bottom Buttons */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    disableElevation
                                    sx={{
                                        width: '200px',
                                        height: '48px',
                                        backgroundColor: theme.palette.grey[500],
                                        color: 'white'
                                    }}
                                    color="secondary"
                                    fullWidth
                                    size="large"
                                    variant="contained"
                                >
                                    <WestIcon sx={{ mr: 'auto' }} /> Back
                                </Button>
                            </AnimateButton>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <AnimateButton>
                                <Button
                                    type="submit"
                                    disableElevation
                                    fullWidth
                                    sx={{
                                        width: '200px',
                                        height: '48px',
                                        backgroundColor: theme.palette.secondary.main,
                                        color: 'white'
                                    }}
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                >
                                    Save & Complete <EastIcon sx={{ ml: 'auto' }} />
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default TypeForm;
