import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StepConnector from '@mui/material/StepConnector';
import { Box, Stack } from '@mui/system';
import { Button } from '@mui/material';

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    active: {
        color: '#ceeac7'
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    },
    completed: {
        color: '#5dba47',
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? <DoneAllIcon className={classes.completed} /> : <Button className={classes.circle}></Button>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,#8be09f 0%,#5dba47 50%, #ceeac7 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,#8be09f 0%,#5dba47 50%,#ceeac7 100%)'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#ceeac7',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        border: '2px solid #5dba47',
        backgroundColor: '#ceeac7',
        color: 'black'
    },
    completed: {
        backgroundImage: 'linear-gradient( 136deg, #8be09f 0%, #5dba47 50%, #418231 100%)'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, icon } = props;
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {completed === true ? <DoneAllIcon /> : String(props.icon)}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function CustomizedSteppers(props) {
    const classes = useStyles();
    const steps = props.steps;
    useEffect(() => {
    }, [props.activeStep]);

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={props.activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                            {/* {steps[index]}
                            {typeof label === 'string'} */}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}
