/* eslint-disable import/no-named-as-default-member */
// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import menuReducer from './slices/menu';

import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';
import projectReducer from './slices/project';
import calendarReducer from './slices/calendar';
import dealRoomReducer from './slices/dataroom';
import investorReducer from './slices/investor';
import technicalAssistantReducer from './slices/technicalAssistant';
import submittingOrganizationReducer from './slices/submittingOrganization';
import requestReducer from './slices/request';
import notificationReducer from './slices/notification';
import taReducer from './slices/taRequest';
import chatReducer from './slices/chat';
import itineraryReducer from './slices/itinerary';
import projectOwnerCalendarReducer from './slices/projectOwnerCalendar';
import dashboardReducer from './slices/dashboard';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: persistReducer(
        {
            key: 'snackbar',
            storage,
            keyPrefix: 'acs-'
        },
        snackbarReducer
    ),
    user: userReducer,
    itinerary: itineraryReducer,
    menu: menuReducer,
    project: projectReducer,
    calendar: calendarReducer,
    dataroom: dealRoomReducer,
    investor: investorReducer,
    request: requestReducer,
    technicalAssistant: technicalAssistantReducer,
    submittingOrganization: submittingOrganizationReducer,
    notification: notificationReducer,
    taRequest: taReducer,
    chat: chatReducer,
    projectOwnerCalendar: projectOwnerCalendarReducer,
    dashboard: dashboardReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        state = undefined;
    }
    return reducer(state, action);
};

export default rootReducer;
