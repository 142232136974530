/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import RepresentativeForm from './RepresentativeForm';
import CompanyInformationForm from './CompanyInformationForm';
import PreferredProjectsForm from './PreferredProjectsForm';
import TypesOfTAForm from './TypesOfTAForm';
import TermsAndConditions from './TermsAndConditions';
import SummaryForm from './SummaryForm';
import { useTheme } from '@mui/material/styles';
import { getByIdTechnicalAssistant } from 'store/slices/technicalAssistant';
import { Step, Card, Button, CardContent, Grid, Stepper, Typography, StepLabel } from '@mui/material';
import IncompleteProfileAlert from 'ui-component/IncompleteProfileAlert';
import CustomizedSteppers from 'ui-component/CustomizedSteppers';
import Loader from 'ui-component/Loader';
import useAuth from 'hooks/useAuth';

// ==============================|| PRODUCT REVIEW LIST ||============================== //
const TechnicalAssistantOnboarding = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { user } = useAuth();

    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const { activeStep, technicalAssistant } = useSelector((state) => state.technicalAssistant);

    useEffect(() => {
        // setLoading(true);
        const fetchTechnicalAssistant = async () => {
            try {
                await dispatch(getByIdTechnicalAssistant(user?._id));
            } catch (error) {
                console.error('Failed to fetch technical assistant', error);
            }
            // finally {
            //     setLoading(false);
            // }
        };
        fetchTechnicalAssistant();
    }, [id, user]);

    const steps = ['Company Information', 'Company Representative', 'Preferred Projects', 'Types of TA', 'Terms and Conditions', 'Review'];
    const displayForm = (step) => {
        switch (step) {
            case 0:
                return <CompanyInformationForm />;
            case 1:
                return <RepresentativeForm />;
            case 2:
                return <PreferredProjectsForm />;
            case 3:
                return <TypesOfTAForm />;
            case 4:
                return <TermsAndConditions />;
            case 5:
                return <SummaryForm />;
            default:
                throw new Error('Unknown step');
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <IncompleteProfileAlert />
            <Card content={false} sx={{ boxShadow: 2 }}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        backgroundColor: theme.palette.grey[200],
                        padding: '18px 22px 10px 22px'
                    }}
                >
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography variant="h4">Technical Assistance Consultant Onboarding</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', ml: 'auto' }}>
                        <Button sx={{ ml: 'auto', backgroundColor: 'white', px: 3, py: 1 }}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize'
                                }}
                            >
                                Step {activeStep + 1} of {steps.length}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>

                <CardContent>
                    {/* START stepper  */}
                    <CustomizedSteppers steps={steps} activeStep={activeStep} />
                    {/* END stepper  */}

                    {/* START: Step One content */}
                    <Grid sx={{ mt: 3 }} container justifyContent="center" alignItems="center">
                        <Grid item md={12} lg={12} xs={12}>
                            {displayForm(activeStep)}
                        </Grid>
                    </Grid>
                    {/* END Step one content  */}
                </CardContent>
            </Card>
        </>
    );
};

export default TechnicalAssistantOnboarding;
