import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';

import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Button, Typography, Chip } from '@mui/material';
import { handleBack, handleNext } from 'store/slices/submittingOrganization';
// ===========================|| JWT - REGISTER ||=========================== //

const SummaryForm = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();

    const dispatch = useDispatch();

    const { activeStep, submittingOrganization } = useSelector((state) => state.submittingOrganization);

    const handleDone = () => {
        handleNext();
        // dispatch(handleReset());
        // if (user.role === 'admin') {
        //     navigate(`/admin/orgs/`);
        // } else {
        //     navigate(`/so/projects/`);
        // }
    };

    useEffect(() => {
        console.log('submittingOrganization', submittingOrganization);
    }, []);

    return (
        <>
            <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '5px',
                        pb: 2,
                        my: 2
                    }}
                >
                    <Typography variant="h4">Company Information</Typography>
                </Grid>

                <Grid container spacing={2} sx={{ px: 1 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Name
                        </Typography>
                        <Chip label={submittingOrganization.companyName ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Website
                        </Typography>
                        <Chip label={submittingOrganization.companyWebsite ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Overview
                        </Typography>
                        <Chip label={submittingOrganization.companyOverview ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Email Address
                        </Typography>
                        <Chip label={submittingOrganization.companyEmail ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Phone Number
                        </Typography>
                        <Chip label={submittingOrganization.companyPhone ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Physical Address
                        </Typography>
                        <Chip label={submittingOrganization.physicalAddress ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            City
                        </Typography>
                        <Chip label={submittingOrganization.city ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '5px',
                        pb: 2,
                        my: 2
                    }}
                >
                    <Typography variant="h4">Company Representative</Typography>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Full Name
                        </Typography>
                        <Chip label={submittingOrganization.repName ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Email
                        </Typography>
                        <Chip label={submittingOrganization.repEmail ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Phone Number
                        </Typography>
                        <Chip label={submittingOrganization.repPhone ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Physical Address
                        </Typography>
                        <Chip label={submittingOrganization.repAddress ?? `Not set`} sx={{ px: 2 }} />
                    </Grid>
                </Grid>
            </Grid>
            {/* Bottom Buttons */}
            <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <AnimateButton>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            disableElevation
                            sx={{
                                width: '200px',
                                height: '48px',
                                backgroundColor: theme.palette.grey[500],
                                color: 'white'
                            }}
                            color="secondary"
                            fullWidth
                            size="large"
                            variant="contained"
                        >
                            <WestIcon sx={{ mr: 'auto' }} /> Back
                        </Button>
                    </AnimateButton>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <AnimateButton>
                        <Button
                            onClick={handleDone}
                            disableElevation
                            fullWidth
                            sx={{
                                width: '200px',
                                height: '48px',
                                backgroundColor: theme.palette.secondary.main,
                                color: 'white',
                                mr: 2
                            }}
                            color="secondary"
                            size="large"
                            variant="contained"
                        >
                            Done <EastIcon sx={{ ml: 'auto' }} />
                        </Button>
                    </AnimateButton>
                </Box>
            </Grid>
        </>
    );
};

export default SummaryForm;
