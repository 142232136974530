/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { COUNTRIES } from 'utils/Constants';
import AnimateButton from 'ui-component/extended/AnimateButton';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { FormHelperText, Grid, InputLabel, Button, Typography, Select, Box, Checkbox, FormControlLabel, MenuItem } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project importsBackupIcon
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { handleBack, handleNext, updateTechnicalAssistant } from 'store/slices/technicalAssistant';

// ===========================|| Terms And Conditions ||=========================== //

const TermsAndConditions = ({ ...others }, steps) => {
    const { activeStep, technicalAssistant } = useSelector((state) => state.technicalAssistant);

    const theme = useTheme();
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.user);

    return (
        <>
            <Formik
                initialValues={{
                    _id: technicalAssistant?._id ?? '',
                    termsAccepted: technicalAssistant.termsAccepted ?? false,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    termsAccepted: Yup.boolean().required('You must accept the Terms of Service to proceed')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    dispatch(updateTechnicalAssistant(values));
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h3">Technical Assistance Consultants | Terms & Conditions</Typography>

                                <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    1.0 Associated Policy
                                </Typography>

                                {/* 1.1 Reference to the Mission 300 Deal Room Terms of Use */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    1.1 Reference to the Mission 300 Deal Room Terms of Use
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.1.1 Incorporation by Reference: The Mission 300 Deal Room Terms of Use, as available on the Mission 300 Deal Room
                                    website, are incorporated by reference into these Terms and Conditions. By agreeing to these Terms and
                                    Conditions, the Technical Assistant also agrees to be bound by the Mission 300 Deal Room Terms of Use.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.1.2 Precedence: In the event of any inconsistency or conflict between these Terms and Conditions and
                                    the Mission 300 Deal Room Terms of Use, the provisions of the Mission 300 Deal Room Terms of Use shall prevail to the
                                    extent of such inconsistency or conflict.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.1.3 Periodic Review: The Technical Assistant is responsible for periodically reviewing the Mission 300 Deal
                                    Room Terms of Use to stay informed of any updates or changes that may affect their use of the platform.
                                </Typography>

                                {/* 1.2 Code of Conduct as per the Technical Assistant's Profession */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    1.2 Code of Conduct as per the Technical Assistant&apos;s Profession
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.1 Adherence to Professional Standards: The Technical Assistant agrees to conduct themselves in
                                    accordance with the Code of Conduct or ethical guidelines associated with their specific profession or
                                    industry.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.2 Representation of Qualifications: The Technical Assistant represents and warrants that they
                                    possess the necessary qualifications, certifications, or licenses required by their profession and will
                                    provide evidence of the same upon request by Mission 300 or any other relevant party.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.3 Reporting of Ethical Violations: Any violations of the professional Code of Conduct, including but
                                    not limited to malpractice, misrepresentation, or fraud, should be promptly reported to the Mission 300
                                    Secretariat. Such violations may result in the suspension or termination of the Technical
                                    Assistant&apos;s access to the Mission 300 Deal Room.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.4 Continuous Professional Development: The Technical Assistant commits to ongoing professional
                                    development and staying updated with any changes or amendments to the Code of Conduct of their
                                    profession.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.5 Liability: The Technical Assistant acknowledges that any breach of their professional Code of
                                    Conduct may result in legal or disciplinary actions by their professional body, and Mission 300 shall not be
                                    held liable for any consequences arising from such breaches.
                                </Typography>

                                {/* 2.0 Role and Responsibilities of the Technical Assistant */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    2.0 Role and Responsibilities of the Technical Assistant
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.1.1 Role Definition: The Technical Assistant is a professional who offers expertise, guidance, and
                                    support to project owners within the Mission 300 Deal Room, facilitating the successful execution and completion
                                    of projects.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.1.2 Scope of Services: The Technical Assistant&apos;s services may include, but are not limited to,
                                    technical evaluations, feasibility studies, project planning, and other specialized services as required
                                    by project owners.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.1.3 Limitations: The Technical Assistant is not an employee or agent of Mission 300 and does not have the
                                    authority to make commitments or decisions on behalf of Mission 300.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.2.1 Accurate Representation: The Technical Assistant must accurately represent their qualifications,
                                    experience, and areas of expertise within the Mission 300 Deal Room.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.2.2 Avoidance of Misrepresentation: Any false or misleading representation regarding qualifications or
                                    expertise may result in the suspension or termination of the Technical Assistant&apos;s access to the
                                    Mission 300 Deal Room.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.3.1 Proactive Engagement: The Technical Assistant should actively engage with project owners,
                                    understanding their needs, and offering tailored solutions and guidance.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.3.2 Quality of Service: Services provided by the Technical Assistant should meet professional
                                    standards, ensuring that project owners receive valuable and actionable insights.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.3.3 Feedback and Reviews: The Technical Assistant should be open to receiving feedback from project
                                    owners and continuously strive to improve the quality of their services.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.4.1 Duty of Confidentiality: The Technical Assistant must maintain the confidentiality of all
                                    information, data, and documents shared by project owners or accessed within the Mission 300 Deal Room.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.4.2 Data Protection Compliance: The Technical Assistant must comply with the Data Protection Act
                                    (2019) of the Republic of Kenya, GDPR standards, and any other relevant data protection regulations when
                                    handling personal data.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.4.3 Non-Disclosure Agreements (NDAs): The Technical Assistant may be required to enter into NDAs with
                                    project owners or other parties to ensure the protection of sensitive or proprietary information.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.4.4 Data Retention and Deletion: The Technical Assistant should retain data only for the duration
                                    necessary to fulfill their role and should securely delete any data that is no longer required, in
                                    compliance with data protection regulations.
                                </Typography>

                                {/* 3.0 Professional Conduct */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    3.0 Professional Conduct
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.1 Adherence to Ethical Standards: The Technical Assistant is expected to uphold the highest
                                    standards of ethical behavior, honesty, and professionalism at all times while using the Mission 300 Deal Room.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.2 Transparency: The Technical Assistant should be transparent in all dealings, ensuring that project
                                    owners and investors are provided with accurate and unbiased information.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.3 Accountability: The Technical Assistant is accountable for their actions and decisions within the
                                    Mission 300 Deal Room and should be prepared to justify and explain their professional judgments when required.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.4 Fairness: The Technical Assistant should treat all users of the Mission 300 Deal Room with fairness and
                                    equity, irrespective of race, gender, age, or any other characteristic protected by law.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.5 Respect for Intellectual Property: The Technical Assistant must respect the intellectual property
                                    rights of project owners, Mission 300, and third parties, and should not reproduce, disseminate, or use such
                                    property without proper authorization.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.termsAccepted}
                                            error={Boolean(touched.termsAccepted && errors.termsAccepted)}
                                            name="termsAccepted"
                                            color="primary"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={() => setFieldValue('termsAccepted', !values.termsAccepted)}
                                        />
                                    }
                                    label="I agree to the terms and conditions"
                                />
                                {touched.termsAccepted && errors.termsAccepted && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.termsAccepted}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                            disabled={!values.termsAccepted}
                                        >
                                            Agree & Continue <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default TermsAndConditions;
