import React from 'react';
import { Grid, Typography, FormControl, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { toast } from 'react-toastify';

const ImageUpload = ({ formik, label, acceptedFileTypes, fieldName, initialFileName, multiple = false, maxFileSize, maxFiles }) => {
    const handleFileChange = (event) => {
        const files = Array.from(event.currentTarget.files);

        // Error Checks
        if (files.some((file) => file.size > maxFileSize)) {
            toast.error('File size exceeds the maximum limit!');
            return;
        }

        if (multiple && files.length > maxFiles) {
            toast.error(`You can only upload up to ${maxFiles} files.`);
            return;
        }
        // Setting Formik Fields
        if (multiple) {
            formik.setFieldValue(fieldName, files);
            formik.setFieldValue(
                `${fieldName}Names`,
                files.map((file) => file.name)
            );
        } else {
            formik.setFieldValue(fieldName, files[0]);
            formik.setFieldValue(`${fieldName}Name`, files[0].name);
        }

        // Reset the input for the next possible file upload
        event.currentTarget.value = null;
    };

    return (
        <FormControl fullWidth>
            <div style={{ display: 'flex', justifyContent: 'center', border: '2px dashed', borderRadius: '40px' }}>
                <input
                    type="file"
                    id={fieldName}
                    name={fieldName}
                    onChange={handleFileChange}
                    accept={acceptedFileTypes}
                    style={{ display: 'none', height: '100%', width: '100%' }}
                    multiple={multiple}
                />
                <label htmlFor={fieldName}>
                    <Button variant="text" component="span" fullWidth>
                        {(formik.values[`${fieldName}Names`] && formik.values[`${fieldName}Names`].length > 0) ||
                        formik.values[`${fieldName}Name`] ||
                        initialFileName ? (
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <InsertPhotoIcon color="action" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="center" fontWeight="bold">
                                        {multiple
                                            ? formik.values[`${fieldName}Names`].join(', ')
                                            : formik.values[`${fieldName}Name`] || initialFileName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption" align="center">
                                        Click to replace the uploaded file
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container>
                                <Grid item xs={12}>
                                    <CloudUploadIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="center" gutterBottom>
                                        {label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption" align="center">
                                        Tap on this upload window or drag and drop the file here
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Button>
                </label>
            </div>
        </FormControl>
    );
};

ImageUpload.propTypes = {
    formik: PropTypes.object.isRequired,
    label: PropTypes.string,
    acceptedFileTypes: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    maxFileSize: PropTypes.number,
    multiple: PropTypes.bool,
    maxFiles: PropTypes.number,
    initialFileName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};

export default ImageUpload;
