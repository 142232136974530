/* eslint-disable no-underscore-dangle */
import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { COUNTRIES } from 'utils/Constants';
import { Stack } from '@mui/system';
import AnimateButton from 'ui-component/extended/AnimateButton';
import InputLabel from 'ui-component/extended/Form/InputLabel';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    FormHelperText,
    Grid,
    TextField,
    Typography,
    Button,
    Box,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormControl,
    MenuItem
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import CircularProgress from '@mui/material/CircularProgress';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { handleBack, handleNext, postTechnicalAssistant, updateTechnicalAssistant } from 'store/slices/technicalAssistant';

// ===========================|| JWT - REGISTER ||=========================== //

const TypesOfTAForm = ({ ...others }) => {
    const { activeStep, technicalAssistant } = useSelector((state) => state.technicalAssistant);

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    const theme = useTheme();
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [isOtherSelected, setOtherSelected] = useState(false);

    const getInitValues = () => {
        if (technicalAssistant && technicalAssistant._id) {
            return {
                _id: technicalAssistant?._id ?? '',
                technicalAssistanceProvided: technicalAssistant?.technicalAssistanceProvided ?? [],
                grantDescription: technicalAssistant?.grantDescription ?? '',
                applicationAndEvaluationCriteria: technicalAssistant?.applicationAndEvaluationCriteria ?? '',
                applicationLink: technicalAssistant?.applicationLink ?? '',
                grantOption: technicalAssistant?.grantOption ?? '',
                grantType: technicalAssistant?.grantType ?? '',
                otherValue: technicalAssistant?.otherValue ?? ''
            };
        }
        return {
            technicalAssistanceProvided: [],
            grantDescription: '',
            applicationAndEvaluationCriteria: '',
            applicationLink: '',
            grantOption: '',
            grantType: '',
            otherValue: ''
        };
    };

    const menuItems = [
        {
            value: 'technical_feasibility_assessment',
            label: 'Technical Feasibility Assessment'
        },
        {
            value: 'project_design_and_planning',
            label: 'Project Design and Planning'
        },
        {
            value: 'permitting_and_regulatory_support',
            label: 'Permitting and Regulatory Support'
        },
        {
            value: 'financial_modelling_and_analysis',
            label: 'Financial Modelling and Analysis'
        },
        {
            value: 'project_management_and_coordination',
            label: 'Project Management and Coordination'
        },
        {
            value: 'technology_selection_and_evaluation',
            label: 'Technology Selection and Evaluation'
        },
        {
            value: 'project_construction',
            label: 'Project Construction'
        },
        {
            value: 'training_and_capacity_building',
            label: 'Training and Capacity Building'
        },
        {
            value: 'market_analysis',
            label: 'Market Analysis'
        },
        {
            value: 'performance_monitoring_and_optimization',
            label: 'Performance Monitoring and Optimization'
        },
        {
            value: 'ESIA_report',
            label: 'ESIA Report'
        },
        {
            value: 'other',
            label: 'Other (Please specify)'
        }
    ];

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={getInitValues()}
                validationSchema={Yup.object().shape({
                    technicalAssistanceProvided: Yup.array().min(1, 'Please select at least one option'),
                    grantOption: Yup.string().required('Please select an option'),
                    applicationLink: Yup.string()
                        .url('Must be a valid grant application URL')
                        .when('grantOption', {
                            is: 'yes',
                            then: Yup.string().required('Grant application link is required')
                        }),
                    grantType: Yup.string().when('grantOption', {
                        is: 'yes',
                        then: Yup.string().required('Grant Type is required')
                    }),
                    grantDescription: Yup.string().when('grantOption', {
                        is: 'yes',
                        then: Yup.string().required('Grant Description is required')
                    }),
                    applicationAndEvaluationCriteria: Yup.string().when('grantOption', {
                        is: 'yes',
                        then: Yup.string().required('Application & Evaluation Criteria is required')
                    })
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    // Updates the 'other' value to include the user's input
                    if (values.technicalAssistanceProvided.includes('other')) {
                        const index = values.technicalAssistanceProvided.indexOf('other');
                        values.technicalAssistanceProvided[index] = `other - ${values.otherValue}`;
                    }
                    if (Object.keys(technicalAssistant).length === 0) {
                        await dispatch(postTechnicalAssistant(values));
                    } else {
                        dispatch(updateTechnicalAssistant(values));
                    }
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => {
                    const isGrantSelected = values.grantOption === 'yes';
                    const handleGrantOptionChange = (event, setFieldValue) => {
                        handleChange(event);

                        if (event.target.value === 'no') {
                            // Clear out related fields
                            setFieldValue('grantType', '');
                            setFieldValue('grantDescription', '');
                            setFieldValue('applicationAndEvaluationCriteria', '');
                            setFieldValue('applicationLink', '');
                        }
                    };
                    return (
                        <form noValidate onSubmit={handleSubmit} {...others}>
                            {isSubmitting && <Loader />}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h4" sx={{ mb: 3, mt: 3 }}>
                                        Please select the type(s) of Technical Assistance your company can provide (check all that apply)
                                    </Typography>
                                    <FormControl error={Boolean(touched.technicalAssistanceProvided && errors.technicalAssistanceProvided)}>
                                        <FormGroup>
                                            <Grid container spacing={2}>
                                                {menuItems.map((item) => (
                                                    <Grid item xs={3} key={item.value}>
                                                        <Box border={1} borderRadius="22px" borderColor="divider" p={2} alignItems="center">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={
                                                                            values.technicalAssistanceProvided &&
                                                                            values.technicalAssistanceProvided.includes(item.value)
                                                                        }
                                                                        onChange={() => {
                                                                            const set = new Set(values.technicalAssistanceProvided || []);
                                                                            if (item.value === 'other') {
                                                                                setOtherSelected(!set.has(item.value));
                                                                            }
                                                                            if (set.has(item.value)) {
                                                                                set.delete(item.value);
                                                                            } else {
                                                                                set.add(item.value);
                                                                            }
                                                                            setFieldValue('technicalAssistanceProvided', [...set]);
                                                                        }}
                                                                    />
                                                                }
                                                                label={item.label}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </FormGroup>
                                    </FormControl>
                                    {touched.technicalAssistanceProvided && errors.technicalAssistanceProvided && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.technicalAssistanceProvided}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                {isOtherSelected && (
                                    <Grid item xs={12} sx={{ mb: 3, mt: 2 }}>
                                        <TextField
                                            fullWidth
                                            id="other-scope"
                                            variant="standard"
                                            name="otherValue"
                                            value={values.otherValue || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Please specify the other type of technical assistance your company can provide"
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h4" sx={{ mb: 3, mt: 3 }}>
                                        Is this a grant?
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-label="grant"
                                        name="grantOption"
                                        value={values.grantOption}
                                        onChange={(event) => handleGrantOptionChange(event, setFieldValue)}
                                        error={touched.grantOption && Boolean(errors.grantOption)}
                                    >
                                        <Box border={1} borderRadius="22px" borderColor="divider" px={1.5} m={1} alignItems="center">
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" labelPlacement="end" />
                                        </Box>
                                        <Box border={1} borderRadius="22px" borderColor="divider" px={1.5} m={1} alignItems="center">
                                            <FormControlLabel value="no" control={<Radio />} label="No" labelPlacement="end" />
                                        </Box>
                                    </RadioGroup>
                                    {touched.grantOption && errors.grantOption && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.grantOption}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                {isGrantSelected && (
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Stack>
                                                <InputLabel id="grantType">Grant Type</InputLabel>
                                                <TextField
                                                    id="grantType"
                                                    name="grantType"
                                                    select
                                                    fullWidth
                                                    value={values.grantType}
                                                    onChange={handleChange}
                                                    error={touched.grantType && Boolean(errors.grantType)}
                                                    helperText={touched.grantType && errors.grantType}
                                                >
                                                    <MenuItem value="recoverable">Recoverable</MenuItem>
                                                    <MenuItem value="non-recoverable">Non Recoverable</MenuItem>
                                                </TextField>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack>
                                                <InputLabel id="grantDescription">Grant Description</InputLabel>
                                                <TextField
                                                    id="grantDescription"
                                                    name="grantDescription"
                                                    fullWidth
                                                    value={values.grantDescription}
                                                    onChange={handleChange}
                                                    error={touched.grantDescription && Boolean(errors.grantDescription)}
                                                    helperText={touched.grantDescription && errors.grantDescription}
                                                    placeholder="e.g What is your grant about..."
                                                    multiline
                                                    rows={4}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack>
                                                <InputLabel id="applicationAndEvaluationCriteria">
                                                    Application & Evaluation Criteria
                                                </InputLabel>
                                                <TextField
                                                    id="applicationAndEvaluationCriteria"
                                                    name="applicationAndEvaluationCriteria"
                                                    fullWidth
                                                    value={values.applicationAndEvaluationCriteria}
                                                    onChange={handleChange}
                                                    error={
                                                        touched.applicationAndEvaluationCriteria &&
                                                        Boolean(errors.applicationAndEvaluationCriteria)
                                                    }
                                                    helperText={
                                                        touched.applicationAndEvaluationCriteria && errors.applicationAndEvaluationCriteria
                                                    }
                                                    placeholder="e.g Type a criteria..."
                                                    multiline
                                                    rows={4}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack>
                                                <InputLabel id="applicationLink">Application Link</InputLabel>
                                                <TextField
                                                    id="applicationLink"
                                                    name="applicationLink"
                                                    fullWidth
                                                    value={values.applicationLink}
                                                    onChange={handleChange}
                                                    error={touched.applicationLink && Boolean(errors.applicationLink)}
                                                    helperText={touched.applicationLink && errors.applicationLink}
                                                    placeholder="e.g Type a link..."
                                                />
                                            </Stack>
                                        </Grid>
                                    </>
                                )}

                                {/* Bottom Buttons */}
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                disableElevation
                                                sx={{
                                                    width: '200px',
                                                    height: '48px',
                                                    backgroundColor: theme.palette.grey[500],
                                                    color: 'white'
                                                }}
                                                color="secondary"
                                                fullWidth
                                                size="large"
                                                variant="contained"
                                            >
                                                <WestIcon sx={{ mr: 'auto' }} /> Back
                                            </Button>
                                        </AnimateButton>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <AnimateButton>
                                            <Button
                                                type="submit"
                                                disableElevation
                                                fullWidth
                                                sx={{
                                                    width: '200px',
                                                    height: '48px',
                                                    backgroundColor: theme.palette.secondary.main,
                                                    color: 'white'
                                                }}
                                                color="secondary"
                                                size="large"
                                                variant="contained"
                                            >
                                                Save & Continue <EastIcon sx={{ ml: 'auto' }} />
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

export default TypesOfTAForm;
