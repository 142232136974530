/**
 * axios setup to use mock service
 */

import axios from 'axios';
// Assuming you have a Redux or Context setup, import your dispatch or logout action
import { dispatch } from 'store';
import { LOGOUT } from 'store/actions';

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_SERVER_BASE_URL,
    withCredentials: true
});
const handleTokenExpiration = () => {
    localStorage.removeItem('token');
    dispatch({
        type: LOGOUT
    });
    window.location.href = '/login';
};
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log('error', error.response);
        if (error.response && error.response.status === 410) {
            // Handle token expiration or forbidden access
            handleTokenExpiration();
        }
        return Promise.reject((error.response && error.response.data) || error);
    }
);

export default axiosServices;
