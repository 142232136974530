import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { COUNTRIES } from 'utils/Constants';
import AnimateButton from 'ui-component/extended/AnimateButton';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack } from '@mui/system';
import { FormHelperText, Grid, TextareaAutosize, Button, Typography, styled, Box, Checkbox, FormControlLabel } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';

// project importsBackupIcon
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { updateUser } from 'store/slices/user';
import { handleBack, handleNext, updateSubmittingOrg } from 'store/slices/submittingOrganization';

// ===========================|| JWT - REGISTER ||=========================== //

const TermsAndConditions = ({ ...others }, steps) => {

    const { activeStep, submittingOrganization } = useSelector((state) => state.submittingOrganization);

    const theme = useTheme();
    const dispatch = useDispatch();
    const { logout, user } = useAuth();

    return (
        <>
            <Formik
                initialValues={{
                    _id: submittingOrganization?._id,
                    termsAccepted: submittingOrganization.termsAccepted,
                    iskyc: true,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    termsAccepted: Yup.boolean().oneOf([true], 'You must accept the Terms of Service to proceed')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    dispatch(updateUser({ _id: user._id, iskyc: true }));
                    dispatch(updateSubmittingOrg(values));
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Information saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h4">Terms and Conditions</Typography>
                                <Typography variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    Financial Obligation
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    As a buyer, you agree to fulfill the financial obligation of purchasing carbon credits from the selected
                                    project (undefined), which is managed by the Project Developer (undefined undefined). Your support to
                                    initiatives that aim to reduce global carbon emissions is appreciated. Please understand that this
                                    commitment carries a financial responsibility, and we encourage you to review all project details before
                                    making a purchase.
                                </Typography>
                                <Typography variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    Understanding of the Project
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    By proceeding with this purchase, you affirm that you have thoroughly reviewed and understand the
                                    objectives, scope, and timelines of the project. Each carbon credit purchase directly contributes to the
                                    successful completion of the project and ultimately, the reduction of carbon emissions.
                                </Typography>
                                <Typography variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    Changes and Redemption
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    We understand that circumstances can change. Therefore, should you need to redeem your funds from the
                                    carbon credits purchase, please reach out to our customer service team. While we aim to be as
                                    accommodating as possible, please note that redemptions will be subject to applicable rules and
                                    regulations, and may incur processing fees. We advise all our buyers to make informed decisions before
                                    proceeding with a purchase.
                                </Typography>
                                <Typography variant="h5" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    Legal and Compliance
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}></Typography>
                                The buyer agrees to comply with all applicable local and international laws and regulations related to the
                                purchase and use of carbon credits. Any misuse or misrepresentation of carbon credits can result in legal
                                action.
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.termsAccepted}
                                            error={Boolean(touched.termsAccepted && errors.termsAccepted)}
                                            name="termsAccepted"
                                            color="primary"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={() => setFieldValue("termsAccepted", !values.termsAccepted)}
                                        />
                                    }
                                    label="Accept these terms and conditions?"
                                />
                                {touched.termsAccepted && errors.termsAccepted && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.termsAccepted}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Save & Complete <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default TermsAndConditions;
