/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import RepresentativeForm from './RepresentativeForm';
import CompanyInformationForm from './CompanyInformationForm';
import TermsAndConditions from './TermsAndConditions';
import SettingForm from './SettingForm';
import SummaryForm from './SummaryForm';
import SuccessCard from './SuccessCard';
import { useTheme } from '@mui/material/styles';
import { showSubmittingOrg } from 'store/slices/submittingOrganization';
import { Step, Card, Button, CardContent, Grid, Typography, StepLabel } from '@mui/material';
import CustomizedSteppers from 'ui-component/CustomizedSteppers';

// ==============================|| PRODUCT REVIEW LIST ||============================== //
const OrganisationOnboarding = ({ isAdmin }) => {
    const { submittingOrganization } = useSelector((state) => state.submittingOrganization);
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        if (typeof id !== `undefined`) {
            dispatch(showSubmittingOrg(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        console.log('running 1');
    }, [submittingOrganization]);

    const { activeStep } = useSelector((state) => state.submittingOrganization);
    const theme = useTheme();
    const steps = ['Company Information', 'Company Representative', 'Terms and condition', 'Preview'];
    const displayForm = (step) => {
        switch (step) {
            case 0:
                return <CompanyInformationForm />;
            case 1:
                return <RepresentativeForm />;
            case 2:
                let page = <TermsAndConditions />;
                if (isAdmin) {
                    page = <SettingForm />;
                }
                return page;
            case 3:
                return <SummaryForm isAdmin={isAdmin} />;
            default:
                return <SuccessCard />;
        }
    };

    return (
        <Card content={false} sx={{ boxShadow: 2 }}>
            <Grid
                container
                spacing={2}
                sx={{
                    backgroundColor: theme.palette.grey[200],
                    padding: '18px 22px 10px 22px'
                }}
            >
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h4">Submitting Organisation</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', ml: 'auto' }}>
                    <Button sx={{ ml: 'auto', backgroundColor: 'white', px: 3, py: 1 }}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize'
                            }}
                        >
                            Step {activeStep + 1} of {steps.length}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>

            <CardContent>
                {/* START stepper  */}
                <CustomizedSteppers steps={steps} activeStep={activeStep} />
                {/* END stepper  */}
                {/* START: Step One content */}
                <Grid sx={{ mt: 3 }} container justifyContent="center" alignItems="center">
                    <Grid item md={12} lg={12} xs={12}>
                        {displayForm(activeStep)}
                    </Grid>
                </Grid>
                {/* END Step one content  */}
            </CardContent>
        </Card>
    );
};
SummaryForm.propTypes = {
    isAdmin: PropTypes.boolean
};
export default OrganisationOnboarding;
