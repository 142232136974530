/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { COUNTRIES } from 'utils/Constants';
import AnimateButton from 'ui-component/extended/AnimateButton';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { FormHelperText, Grid, TextareaAutosize, Button, Typography, styled, Box, Checkbox, FormControlLabel } from '@mui/material';

// third party
import * as Yup from 'yup';
import { useFormik, Formik } from 'formik';

// project importsBackupIcon
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { handleBack, handleNext, updateInvestor } from 'store/slices/investor';
import { updateUser } from 'store/slices/user';
import useAuth from 'hooks/useAuth';

// ===========================|| JWT - REGISTER ||=========================== //

const TermsAndConditions = ({ ...others }, steps) => {
    const { activeStep, investor } = useSelector((state) => state.investor);

    const theme = useTheme();
    const dispatch = useDispatch();
    const { logout, user } = useAuth();

    return (
        <>
            <Formik
                initialValues={{
                    _id: investor?._id,
                    termsAccepted: investor.termsAccepted,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    termsAccepted: Yup.boolean().required('You must accept the Terms of Service to proceed').oneOf([true], 'Error')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    console.log(user);
                    await dispatch(updateInvestor({ ...values, status: 'submitted' }));
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant="h3">Investors | Terms & Conditions</Typography>

                                {/* 1.0 Associated Policy */}
                                <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }}>
                                    1.0 Associated Policy
                                </Typography>

                                {/* 1.1 Reference to the Mission 300 Deal Room Terms of Use */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    1.1 Reference to the Mission 300 Deal Room Terms of Use
                                </Typography>
                                {/* ... Your existing content here ... */}

                                {/* 1.1.1 Incorporation by Reference */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.1.1 Incorporation by Reference: The Mission 300 Deal Room Terms of Use are hereby incorporated by reference
                                    and form an integral part of these Investor Terms and Conditions. All provisions, rights, and
                                    obligations outlined in the Terms of Use apply to Investors unless otherwise specified in this document.
                                </Typography>

                                {/* 1.1.2 Precedence */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.1.2 Precedence: In the event of any inconsistency or conflict between the provisions of these Investor
                                    Terms and Conditions and the Mission 300 Deal Room Terms of Use, the provisions of the Mission 300 Deal Room Terms of
                                    Use shall prevail to the extent of such inconsistency or conflict.
                                </Typography>

                                {/* 1.1.3 Accessibility */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.1.3 Accessibility: The Mission 300 Deal Room Terms of Use can be accessed directly from the platform`s main
                                    page or upon request from the Mission 300 Secretariat. Investors are encouraged to review the Terms of Use
                                    periodically to stay informed.
                                </Typography>

                                {/* 1.1.4 Updates */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.1.4 Updates: Any updates or modifications to the Mission 300 Deal Room Terms of Use will be communicated to
                                    Investors in a timely manner, and continued use of the platform signifies acceptance of such updates.
                                </Typography>

                                {/* 1.2 Code of Conduct as per the Investor's Profession */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    1.2 Code of Conduct as per the Investor`s Profession
                                </Typography>

                                {/* 1.2.1 Adherence to Professional Standards */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.1 Adherence to Professional Standards: Investors are expected to adhere to the Code of Conduct or
                                    ethical guidelines associated with their professional affiliations or regulatory bodies. This includes,
                                    but is not limited to, principles of fairness, transparency, and integrity in investment practices.
                                </Typography>

                                {/* 1.2.2 Reporting Violations */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.2 Reporting Violations: Any observed violations of the Code of Conduct by other Investors or users
                                    should be reported to the Mission 300 Secretariat. The platform aims to maintain a high standard of professional
                                    conduct among all its users.
                                </Typography>

                                {/* 1.2.3 Consequences of Breach */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    1.2.3 Consequences of Breach: Violation of the professional Code of Conduct may result in penalties,
                                    including suspension or termination of access to the Mission 300 Deal Room, in addition to any legal or
                                    professional consequences outside the platform.
                                </Typography>

                                {/* 2.0 Role and Responsibilities of the Investor */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    2.0 Role and Responsibilities of the Investor
                                </Typography>

                                {/* 2.1 Investment Objectives and Criteria */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.1.1 Clear Definition: Investors should have a clear and well-defined set of investment objectives and
                                    criteria when engaging with the Mission 300 Deal Room. This includes target sectors, investment size,
                                    geographical preferences, and expected returns.
                                </Typography>

                                {/* 2.1.2 Transparency */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.1.2 Transparency: While maintaining confidentiality where necessary, Investors are encouraged to be
                                    transparent about their investment criteria to facilitate more effective matching with potential Project
                                    Owners.
                                </Typography>

                                {/* 2.1.3 Periodic Review */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.1.3 Periodic Review: Investors should periodically review and update their investment objectives and
                                    criteria to reflect any changes in their strategy or market conditions.
                                </Typography>

                                {/* 2.2 Due Diligence and Decision Making */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.2.1 Comprehensive Analysis: Investors are responsible for conducting thorough due diligence on
                                    potential investment opportunities presented in the Mission 300 Deal Room. This includes financial, operational,
                                    legal, and other relevant assessments.
                                </Typography>

                                {/* 2.2.2 Independent Verification */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.2.2 Independent Verification: While the Mission 300 Deal Room may provide certain data or information,
                                    Investors should independently verify such information and not solely rely on the platform&apos;s
                                    provided data.
                                </Typography>

                                {/* 2.2.3 Informed Decisions */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.2.3 Informed Decisions: Investment decisions should be made based on comprehensive analysis,
                                    independent verification, and the Investor&apos;s own judgment. The Mission 300 Deal Room or Mission 300 Secretariat is
                                    not responsible for any investment decisions made by Investors.
                                </Typography>

                                {/* 2.3 Confidentiality and Data Protection */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.3.1 Respect for Confidential Information: Investors must respect the confidentiality of information
                                    shared by Project Owners and not disclose such information without explicit permission.
                                </Typography>

                                {/* 2.3.2 Data Protection Compliance */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.3.2 Data Protection Compliance: Investors should comply with all relevant data protection laws and
                                    regulations, including the GDPR and the Data Protection Act (2019) of Kenya, when handling personal data
                                    obtained through the Mission 300 Deal Room.
                                </Typography>

                                {/* 2.3.3 Secure Storage */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    2.3.3 Secure Storage: Confidential information and personal data should be stored securely, with
                                    appropriate measures to prevent unauthorized access, disclosure, or loss.
                                </Typography>

                                {/* 3.0 Ethical Investment Practices */}
                                <Typography variant="h4" sx={{ mt: 2 }}>
                                    3.0 Ethical Investment Practices
                                </Typography>

                                {/* 3.1 Adherence to Standards */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.1 Investors should adhere to the highest standards of ethical conduct in all their investment
                                    activities, ensuring fairness, transparency, and integrity in their dealings.
                                </Typography>

                                {/* 3.1.2 Responsible Investment */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.2 Responsible Investment: Investors are encouraged to consider environmental, social, and governance
                                    (ESG) factors in their investment decisions, promoting sustainable and responsible investment practices.
                                </Typography>

                                {/* 3.1.3 Non-Discriminatory Practices */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.3 Non-Discriminatory Practices: Investors should ensure that their investment decisions are free
                                    from any form of discrimination based on race, gender, nationality, or other non-business-related
                                    factors.
                                </Typography>

                                {/* 3.1.4 Compliance with Laws */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.1.4 Compliance with Laws: All investment activities should be in compliance with local and
                                    international laws, regulations, and standards.
                                </Typography>

                                {/* 3.2 Avoidance of Conflicts of Interest */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.2.1 Disclosure: Investors should disclose any potential or actual conflicts of interest that might
                                    influence their investment decisions or interactions within the Mission 300 Deal Room.
                                </Typography>

                                {/* 3.2.2 Recusal */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.2.2 Recusal: In situations where a conflict of interest is identified, Investors should recuse
                                    themselves from decision-making processes related to the conflicted matter.
                                </Typography>

                                {/* 3.2.3 Transparency */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.2.3 Transparency: Investors should maintain transparency with Project Owners, Technical Assistants,
                                    and other stakeholders regarding any affiliations or interests that might be perceived as conflicts.
                                </Typography>

                                {/* 3.2.4 Periodic Review */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.2.4 Periodic Review: Investors should periodically review their engagements and affiliations to
                                    identify and manage any emerging conflicts of interest.
                                </Typography>

                                {/* 3.3 Respect for Project Owners, Technical Assistants, and Other Stakeholders */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.3.1 Professional Interactions: Investors should engage with all stakeholders in the Mission 300 Deal Room in a
                                    professional, courteous, and respectful manner, fostering a collaborative environment.
                                </Typography>

                                {/* 3.3.2 Constructive Feedback */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.3.2 Constructive Feedback: When providing feedback or comments, Investors should be constructive,
                                    aiming to support the growth and development of Project Owners and their initiatives.
                                </Typography>

                                {/* 3.3.3 Recognition of Expertise */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.3.3 Recognition of Expertise: Investors should recognize and respect the expertise and contributions
                                    of Technical Assistants, valuing their insights and recommendations in the investment process.
                                </Typography>

                                {/* 3.3.4 Confidentiality */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    3.3.4 Confidentiality: Investors must maintain the confidentiality of sensitive information shared by
                                    Project Owners, Technical Assistants, or other stakeholders, ensuring that such information is not
                                    disclosed without proper authorization.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.termsAccepted}
                                            error={Boolean(touched.termsAccepted && errors.termsAccepted)}
                                            name="termsAccepted"
                                            color="primary"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={() => setFieldValue('termsAccepted', !values.termsAccepted)}
                                        />
                                    }
                                    label="Accept these terms and conditions?"
                                />
                                {touched.termsAccepted && errors.termsAccepted && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.termsAccepted}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Complete <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default TermsAndConditions;
