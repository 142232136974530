/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    events: [],
    event: null,
    meetingInfo: null
};

const slice = createSlice({
    name: 'projectOwnerCalendar',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            state.events = action.payload;
        },

        getEventSuccess(state, action) {
            state.event = action.payload;
        },

        getMeetingInfoSuccess(state, action) {
            state.meetingInfo = action.payload;
        },

        // GET EVENTS BY USER
        getEventsByUserSuccess(state, action) {
            state.events = action.payload;
        },

        getEventsByProjectSuccess(state, action) {
            state.events = action.payload;
        },

        // ADD EVENT
        addEventSuccess(state, action) {
            state.events.push(action.payload);
        },

        // UPDATE EVENT
        updateEventSuccess: (state, action) => {
            const updatedEvent = action.payload;
            const index = state.events.findIndex((event) => event._id === updatedEvent._id);
            if (index > -1) {
                state.events[index] = updatedEvent; // This should cause a re-render of components using this data
            }
        },

        // REMOVE EVENT
        removeEventSuccess: (state, action) => {
            const idToRemove = action.payload._id;
            state.events = state.events.filter((event) => event._id !== idToRemove);
        },

        // ADD ATTENDEE
        addAttendeeSuccess: (state, action) => {
            const updatedEvent = action.payload;
            const index = state.events.findIndex((event) => event._id === updatedEvent._id);
            if (index > -1) {
                state.events[index] = updatedEvent;
            }
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents() {
    return async (dispatch) => {
        try {
            const response = await axios.get('/events');
            dispatch(slice.actions.getEventsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEvent(id) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/events/${id}`);
            dispatch(slice.actions.getEventSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMeetingInfo(meetingID, password) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/events/meeting-info`, {
                meetingID: meetingID,
                password: password
            });
            dispatch(slice.actions.getMeetingInfoSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEventsByUser(userId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/events/user/${userId}`);
            dispatch(slice.actions.getEventsByUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEventsByProject(projectId) {
    return async (dispatch) => {
        try {
            const response = await axios.get(`/events/project/${projectId}`);
            dispatch(slice.actions.getEventsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addEvent(event) {
    return async (dispatch) => {
        // Optimistically add the event locally
        const optimisticEvent = { ...event, _id: 'optimisticId' }; // using a temporary id
        dispatch(slice.actions.addEventSuccess(optimisticEvent));

        try {
            const response = await axios.post('/events', event);
            // Remove the optimistically added event and replace it with the actual one
            dispatch(slice.actions.removeEventSuccess({ _id: 'optimisticId' }));
            dispatch(slice.actions.addEventSuccess(response.data));
        } catch (error) {
            // If the network request fails, remove the optimistically added event
            dispatch(slice.actions.removeEventSuccess({ _id: 'optimisticId' }));
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addEventAdmin(event) {
    return async () => {
        try {
            const response = await axios.post('/events/admin', event);
            dispatch(slice.actions.addEventSuccess(response.data.data));
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function updateEvent({ id, update }) {
    return async (dispatch) => {
        try {
            const response = await axios.put(`/events/${id}`, update);
            dispatch(slice.actions.updateEventSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeEvent(eventId) {
    return async (dispatch) => {
        try {
            const response = await axios.delete(`/events/${eventId}`);
            dispatch(slice.actions.removeEventSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addAttendee({ eventId, attendeeId }) {
    return async (dispatch) => {
        try {
            const response = await axios.post(`/events/${eventId}/attendees`, { attendeeId });
            dispatch(slice.actions.addAttendeeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
