import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import axios from 'utils/axios';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ProjectSummaryCard from 'ui-component/cards/ProjectSummaryCard';
import ProjectOwnerSummaryCard from 'ui-component/cards/ProjectOwnerSummaryCard';

import { Grid, Card, Typography, Chip, Box, Button, CardContent, Divider } from '@mui/material';

// project imports
import { useDispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { getUsers } from 'store/slices/user';
import { useNavigate } from 'react-router-dom';

// ==============================|| DEFAULT DASHBOARD ||============================== //

const ProjectPreviewOwner = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.user);

    const [isLoading, setLoading] = useState(true);
    const [dataRooms, setDataRooms] = useState([
        {
            DataRoomName: 'Turkan Power Plant Project',
            details:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan"
        },
        {
            DataRoomName: 'Turkan Power Plant Project',
            details:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan"
        },
        {
            DataRoomName: 'Turkan Power Plant Project',
            details:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan"
        },
        {
            DataRoomName: 'Turkan Power Plant Project',
            details:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan"
        }
    ]);

    const filters = [
        {
            value: 'agriculture',
            label: 'Agriculture',
            description: 'The Project Owner is the entity that owns the project and is responsible for its implementation.'
        },
        {
            value: 'renewable_energy',
            label: 'Renewable Energy'
        },
        {
            value: 'food_security',
            label: 'Food Security'
        },
        {
            value: 'sustainability',
            label: 'Sustainability'
        }
    ];

    useEffect(() => {
        // dispatch(getDataRooms());
        setLoading(false);
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));

    const rejectRequest = () => {
        // TODO
        console.log('Canceling request');
    };

    const acceptRequest = () => {
        // TODO
        console.log('Signing request');
        navigate('/po/meet-investor');
        investor / sign - nda;
    };

    const getDataRooms = () => {
        return async () => {
            try {
                const response = await axios.get(`/digify/data-room`);
                setDataRooms(response.data.data);
            } catch (error) {
                setDataRooms({});
            }
        };
    };
    const goToProjectDataRoom = () => {
        navigate('/po/inside-data-room');
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography sx={{ fontSize: 18 }}>Kilifi Solar Project</Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={7} md={7}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="h3">
                                    Project Summary
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={goToProjectDataRoom}
                                    sx={{
                                        ml: 1,
                                        px: 2,
                                        backgroundColor: theme.palette.primary.main,
                                        color: 'white',
                                        '&:hover': {
                                            color: theme.palette.primary.main
                                        }
                                    }}
                                >
                                    Visit Data Room
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Typography sx={{ mb: 2, fontWeight: 'bold' }} gutterBottom>
                            Company Description
                        </Typography>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Duis aute irure dolor
                            in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu Excepteur sint occaecat cupidatat
                            non proident, sunt in culpa qui officia deserunt mollit anim id es
                        </Typography>
                        <Divider sx={{ my: 2 }} />

                        <Typography sx={{ mb: 2, fontWeight: 'bold' }} gutterBottom>
                            Project Summary
                        </Typography>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Duis aute irure dolor
                            in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu Excepteur sint occaecat cupidatat
                            non proident, sunt in culpa qui officia deserunt mollit anim id es
                        </Typography>
                        <Divider sx={{ my: 2 }} />

                        <Typography sx={{ mb: 2, fontWeight: 'bold' }} gutterBottom>
                            More Detail
                        </Typography>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Duis aute irure dolor
                            in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu Excepteur sint occaecat cupidatat
                            non proident, sunt in culpa qui officia deserunt mollit anim id es
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={5} md={5}>
                <Grid item xs={12} lg={12}></Grid>
                <Card sx={{ minWidth: 275, boxShadow: 2 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 18, mb: 2, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                            Project Owner Details
                        </Typography>
                        <ProjectOwnerSummaryCard />
                    </CardContent>
                </Card>
                <Card sx={{ minWidth: 275, boxShadow: 2, mt: 3 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 18, mb: 2, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                            Project Details
                        </Typography>
                        <ProjectSummaryCard />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ProjectPreviewOwner;
