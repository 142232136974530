/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { COUNTRIES, PROJECT_STATUS, THEMATIC_GROUPS } from 'utils/Constants';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CircularProgress from '@mui/material/CircularProgress';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Autocomplete,
    Divider,
    Checkbox,
    FormHelperText,
    Grid,
    FormControl,
    InputLabel,
    TextField,
    RadioGroup,
    Typography,
    FormControlLabel,
    MenuItem,
    Stack,
    Box,
    Button,
    Radio,
    FormGroup
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { handleBack, handleNext, updateTechnicalAssistant } from 'store/slices/technicalAssistant';

// ===========================|| Preferred Projects Form ||=========================== //

const PreferredProjectsForm = ({ ...others }, steps) => {
    const { activeStep, technicalAssistant } = useSelector((state) => state.technicalAssistant);

    const theme = useTheme();
    const dispatch = useDispatch();
    const [autocompleteInput, setAutocompleteInput] = useState('');
    const [selectedCountries, setSelectedCountries] = useState(technicalAssistant?.preferredProjectCountry ?? []);

    useEffect(() => {
        setSelectedCountries(technicalAssistant?.preferredProjectCountry ?? []);
    }, [technicalAssistant]);

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    const handleInputChange = (event, newInputValue) => {
        setValue(newInputValue);
    };

    const menuItems = [
        {
            value: 'adaptation_and_resilience',
            label: 'Adaptation and Resilience'
        },
        {
            value: 'renewable_energy',
            label: 'Renewable Energy'
        },
        {
            value: 'climate_finance',
            label: 'Climate Finance'
        },
        {
            value: 'natural_capital',
            label: 'Natural Capital'
        },
        {
            value: 'green_manufacturing_or_minerals',
            label: 'Green Manufacturing or Minerals'
        },
        {
            value: 'sustainable_infrastructure_and_urbanization',
            label: 'Sustainable Infrastructure and Urbanization'
        }
    ];

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    _id: technicalAssistant?._id ?? '',
                    preferredProjectsScope: technicalAssistant?.preferredProjectsScope ?? [],
                    preferredProjectScale: technicalAssistant?.preferredProjectScale ?? '',
                    preferredProjectStatus: technicalAssistant?.preferredProjectStatus ?? '',
                    preferredProjectCountry: technicalAssistant?.preferredProjectCountry ?? [],
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    preferredProjectsScope: Yup.array().min(1, 'Please select at least one option'),
                    preferredProjectStatus: Yup.string().required('Project status is required'),
                    preferredProjectScale: Yup.string().required('Project scale is required'),
                    preferredProjectCountry: Yup.array().min(1, 'Preferred project country is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    dispatch(updateTechnicalAssistant(values));

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h4" sx={{ mb: 3, mt: 3 }}>
                                    Please select the preferred types of projects your company is interested in.
                                </Typography>
                                <FormControl error={Boolean(touched.preferredProjectsScope && errors.preferredProjectsScope)}>
                                    <FormGroup>
                                        <Grid container spacing={2}>
                                            {menuItems.map((item) => (
                                                <Grid item xs={3} key={item.value}>
                                                    <Box border={1} borderRadius="22px" borderColor="divider" p={2} alignItems="center">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        values.preferredProjectsScope &&
                                                                        values.preferredProjectsScope.includes(item.value)
                                                                    }
                                                                    onChange={() => {
                                                                        const set = new Set(values.preferredProjectsScope || []);
                                                                        if (set.has(item.value)) {
                                                                            set.delete(item.value);
                                                                        } else {
                                                                            set.add(item.value);
                                                                        }
                                                                        setFieldValue('preferredProjectsScope', [...set]);
                                                                    }}
                                                                />
                                                            }
                                                            label={item.label}
                                                        />
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </FormGroup>
                                </FormControl>
                                {touched.preferredProjectsScope && errors.preferredProjectsScope && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.preferredProjectsScope}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Divider />
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h4" sx={{ mt: 3 }}>
                                    Project Preferences
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack>
                                    <Typography color={theme.palette.secondary.main} gutterBottom>
                                        Project Stage
                                    </Typography>
                                    <TextField
                                        id="preferredProjectStatus"
                                        name="preferredProjectStatus"
                                        select
                                        fullWidth
                                        value={values.preferredProjectStatus}
                                        onChange={handleChange}
                                        required
                                    >
                                        {PROJECT_STATUS.map((option, index) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Stack>
                                {touched.preferredProjectStatus && errors.preferredProjectStatus && (
                                    <FormHelperText error id="project-status-helper-text">
                                        {errors.preferredProjectStatus}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Stack>
                                    <Typography color={theme.palette.secondary.main} gutterBottom>
                                        Project Scale
                                    </Typography>
                                    <TextField
                                        id="preferredProjectScale"
                                        name="preferredProjectScale"
                                        select
                                        fullWidth
                                        value={values.preferredProjectScale}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="small_scale">Small Scale</MenuItem>
                                        <MenuItem value="large_scale">Large Scale</MenuItem>
                                    </TextField>
                                </Stack>
                                {touched.preferredProjectScale && errors.preferredProjectScale && (
                                    <FormHelperText error id="project-scale-helper-text">
                                        {errors.preferredProjectScale}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Preferred Project Country
                                </Typography>
                                <Autocomplete
                                    id="places"
                                    name="preferredProjectCountry"
                                    multiple
                                    options={status === 'OK' ? data : []}
                                    isOptionEqualToValue={(option, value) => option.description === value}
                                    autoHighlight
                                    getOptionLabel={(option) => (option ? (typeof option === 'string' ? option : option.description) : '')}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ fontSize: 15 }} value={option.label}>
                                            {option.description}
                                        </li>
                                    )}
                                    onChange={(e, vals) => {
                                        const newCountries = vals.map((val) => (typeof val === 'string' ? val : val.description));
                                        setSelectedCountries(newCountries);
                                        setFieldValue('preferredProjectCountry', newCountries);
                                    }}
                                    value={selectedCountries}
                                    inputValue={autocompleteInput}
                                    onInputChange={(event, newInputValue) => {
                                        setAutocompleteInput(newInputValue);
                                        handleInputChange(event, newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            disabled={!ready}
                                            placeholder="e.g Kenya"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {ready ? null : <CircularProgress color="inherit" size={20} />}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                    required
                                />

                                {touched.preferredProjectCountry && errors.preferredProjectCountry && (
                                    <FormHelperText error id="project-country-helper-text">
                                        {errors.preferredProjectCountry}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Save & Complete <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default PreferredProjectsForm;
