import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { openSnackbar } from 'store/slices/snackbar';

import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Grid,
    Chip,
    Button,
    Typography,
    TextField,
    InputLabel,
    Stack,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { handleBack, handleReset } from 'store/slices/technicalAssistant';

// ===========================|| SUMMARY FORM ||=========================== //

const SummaryForm = ({ ...others }, steps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { activeStep, technicalAssistant } = useSelector((state) => state.technicalAssistant);

    const handleComplete = () => {
        dispatch(
            openSnackbar({
                open: true,
                message:
                    'Profile completed successfully. Please note that you will receive an email once the admin has approved your account to access other features.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );

        // Redirect the user to the new route after a small delay
        setTimeout(() => {
            navigate(`/pending-approval-page`);
            window.location.reload();
        }, 4000);
    };

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    const menuItems = [
        {
            value: 'adaptation_and_resilience',
            label: 'Adaptation and Resilience'
        },
        {
            value: 'renewable_energy',
            label: 'Renewable Energy'
        },
        {
            value: 'climate_finance',
            label: 'Climate Finance'
        },
        {
            value: 'natural_capital',
            label: 'Natural Capital'
        },
        {
            value: 'green_manufacturing_or_minerals',
            label: 'Green Manufacturing or Minerals'
        },
        {
            value: 'sustainable_infrastructure_and_urbanization',
            label: 'Sustainable Infrastructure and Urbanization'
        }
    ];

    const technicalAssistanceMenuItems = [
        {
            value: 'technical_feasibility_assessment',
            label: 'Technical Feasibility Assessment'
        },
        {
            value: 'project_design_and_planning',
            label: 'Project Design and Planning'
        },
        {
            value: 'permitting_and_regulatory_support',
            label: 'Permitting and Regulatory Support'
        },
        {
            value: 'financial_modelling_and_analysis',
            label: 'Financial Modelling and Analysis'
        },
        {
            value: 'project_management_and_coordination',
            label: 'Project Management and Coordination'
        },
        {
            value: 'technology_selection_and_evaluation',
            label: 'Technology Selection and Evaluation'
        },
        {
            value: 'project_construction',
            label: 'Project Construction'
        },
        {
            value: 'training_and_capacity_building',
            label: 'Training and Capacity Building'
        },
        {
            value: 'market_analysis',
            label: 'Market Analysis'
        },
        {
            value: 'performance_monitoring_and_optimization',
            label: 'Performance Monitoring and Optimization'
        },
        {
            value: 'ESIA_report',
            label: 'ESIA Report'
        },
        {
            value: 'other',
            label: 'Other (Please specify)'
        }
    ];

    return (
        <>
            {/* Company Information */}
            <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '5px',
                        pb: 2,
                        my: 2
                    }}
                >
                    <Typography variant="h4">Company Information</Typography>
                </Grid>
                <Grid container spacing={2} sx={{ px: 1 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Name
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.companyName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Website
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.companyWebsite}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Overview
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.companyOverview}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Email Address
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.companyEmail}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Phone Number
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.companyPhone}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Company Address
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.companyAddress}</Typography>
                    </Grid>
                </Grid>

                {/* Company Representative */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '5px',
                        pb: 2,
                        my: 2
                    }}
                >
                    <Typography variant="h4">Company Representative</Typography>
                </Grid>
                <Grid container spacing={2} sx={{ px: 1 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Full Name
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.repName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Email
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.repEmail}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Phone Number
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.repPhone}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Linked Profile
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.repLinkedIn}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={technicalAssistant?.canRepCompany} name="canRepCompany" color="primary" fullWidth />
                            }
                            label="I have the authority to represent the company?"
                            disabled
                        />
                    </Grid>
                </Grid>

                {/* Preferred Projects */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '5px',
                        pb: 2,
                        my: 2
                    }}
                >
                    <Typography variant="h4">Preferred Projects</Typography>
                </Grid>
                <Grid container spacing={2} sx={{ px: 1 }}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h4" sx={{ mb: 3, mt: 3 }}>
                            Preferred types of projects your company is interested in.
                        </Typography>
                        <FormControl>
                            <FormGroup>
                                <Grid container spacing={2}>
                                    {menuItems.map((item) => (
                                        <Grid item xs={3} key={item.value}>
                                            <Box border={1} borderRadius="22px" borderColor="divider" p={2} alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                technicalAssistant?.preferredProjectsScope &&
                                                                technicalAssistant?.preferredProjectsScope.includes(item.value)
                                                            }
                                                            onChange={() => {
                                                                const set = new Set(technicalAssistant?.preferredProjectsScope || []);
                                                                if (set.has(item.value)) {
                                                                    set.delete(item.value);
                                                                } else {
                                                                    set.add(item.value);
                                                                }
                                                                // setFieldValue('preferredProjectsScope', [...set]);
                                                            }}
                                                        />
                                                    }
                                                    label={item.label}
                                                    disabled
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Preferred Project Status
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.preferredProjectStatus}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Preferred Project Scale
                        </Typography>
                        <Typography variant="body1">{technicalAssistant?.preferredProjectScale}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h4">Preferred Project Country</Typography>
                        {Array.isArray(technicalAssistant?.preferredProjectCountry) && (
                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                {technicalAssistant?.preferredProjectCountry.map((country, index) => (
                                    <Grid item xs={6} key={index}>
                                        <Chip sx={chipSuccessSX} label={country} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {/* Types of Technical Assistance */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '5px',
                        pb: 2,
                        my: 2
                    }}
                >
                    <Typography variant="h4">Types of Technical Assistance</Typography>
                </Grid>
                <Grid container spacing={2} sx={{ px: 1 }}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h4" sx={{ mb: 3, mt: 3 }}>
                            Technical Assistance your company can provide
                        </Typography>
                        <FormControl>
                            <FormGroup>
                                <Grid container spacing={2}>
                                    {technicalAssistanceMenuItems.map((item) => (
                                        <Grid item xs={3} key={item.value}>
                                            <Box border={1} borderRadius="22px" borderColor="divider" p={2} alignItems="center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                technicalAssistant?.technicalAssistanceProvided &&
                                                                technicalAssistant?.technicalAssistanceProvided.includes(item.value)
                                                            }
                                                            onChange={() => {
                                                                const set = new Set(technicalAssistant?.technicalAssistanceProvided || []);
                                                                if (set.has(item.value)) {
                                                                    set.delete(item.value);
                                                                } else {
                                                                    set.add(item.value);
                                                                }
                                                                // setFieldValue('technicalAssistanceProvided', [...set]);
                                                            }}
                                                        />
                                                    }
                                                    label={item.label}
                                                    disabled
                                                />
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    {technicalAssistant?.technicalAssistanceProvided &&
                        technicalAssistant?.technicalAssistanceProvided.some((value) => value.startsWith('other -')) && (
                            <Grid item xs={12}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Additional Technical Assistance Provided:
                                </Typography>
                                <Typography variant="body1">
                                    {
                                        technicalAssistant?.technicalAssistanceProvided
                                            .find((value) => value.startsWith('other -'))
                                            .split(' - ')[1]
                                    }
                                </Typography>
                            </Grid>
                        )}

                    <Grid item xs={12} sm={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Is this a grant?
                        </Typography>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                            {technicalAssistant?.grantOption}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Grant Type
                        </Typography>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                            {technicalAssistant?.grantType}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Grant Description
                        </Typography>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                            {technicalAssistant?.grantDescription}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Application and Evaluation Criteria
                        </Typography>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                            {technicalAssistant?.applicationAndEvaluationCriteria}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography color={theme.palette.secondary.main} gutterBottom>
                            Application Link
                        </Typography>
                        <Typography variant="body1">
                            <a
                                href={technicalAssistant?.applicationLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'underline', color: theme.palette.primary.main }}
                            >
                                {technicalAssistant?.applicationLink}
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* Bottom Buttons */}
            <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <AnimateButton>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            disableElevation
                            sx={{
                                width: '200px',
                                height: '48px',
                                backgroundColor: theme.palette.grey[500],
                                color: 'white'
                            }}
                            color="secondary"
                            fullWidth
                            size="large"
                            variant="contained"
                        >
                            <WestIcon sx={{ mr: 'auto' }} /> Back
                        </Button>
                    </AnimateButton>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <AnimateButton>
                        <Button
                            onClick={handleComplete}
                            disableElevation
                            fullWidth
                            sx={{
                                width: '200px',
                                height: '48px',
                                backgroundColor: theme.palette.secondary.main,
                                color: 'white',
                                mr: 2
                            }}
                            color="secondary"
                            size="large"
                            variant="contained"
                        >
                            Complete <EastIcon sx={{ ml: 'auto' }} />
                        </Button>
                    </AnimateButton>
                </Box>
            </Grid>
        </>
    );
};

export default SummaryForm;
