import LAYOUT_CONST from 'constant';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const REACT_APP_GOOGLE_MAPS_API_KEY = 'AIzaSyDqvg4GMJt8jVH4r52s6-Iu_ULkF7qa4BU';

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/verst-carbon-client/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/admin/dashboard';

export const HORIZONTAL_MAX_ITEM = 6;

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Nunito Sans', sans-serif`,
    borderRadius: 22,
    outlinedFilled: false,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
