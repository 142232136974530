// third-party
import { FormattedMessage } from 'react-intl';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Groups3Icon from '@mui/icons-material/Groups3';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChatIcon from '@mui/icons-material/Chat';

// ==============================|| PROJECT OWNER MENU ITEMS ||============================== //

const owner = {
    id: 'owner',
    title: <FormattedMessage id="Admin" defaultMessage="Owner" />,
    icon: SpaceDashboardIcon,
    type: 'group',
    children: [
        {
            id: 'po_projects',
            title: <FormattedMessage id="Users" defaultMessage="Projects" />,
            type: 'collapse',
            icon: SolarPowerIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'add_projects',
                    title: <FormattedMessage id="Add Project" defaultMessage="Add Project" />,
                    type: 'item',
                    icon: PersonAddAltIcon,
                    url: '/projects/add',
                    breadcrumbs: false
                },
                {
                    id: 'view_projects',
                    title: <FormattedMessage id="View Projects" defaultMessage="View Projects" />,
                    type: 'item',
                    url: '/owner/projects',
                    icon: ListAltIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'po-requests',
            title: <FormattedMessage id="Requests" defaultMessage="Requests" />,
            type: 'item',
            url: '/owner/requests',
            icon: VolunteerActivismIcon,
            breadcrumbs: false
        },
        {
            id: 'list-technical-assistants',
            title: <FormattedMessage id="Technical Assistants" defaultMessage="TA Providers" />,
            type: 'item',
            url: '/owner/list-technical-assistants',
            icon: Groups3Icon,
            breadcrumbs: false
        },
        {
            id: 'list-technical-assistants-quotes',
            title: <FormattedMessage id="TA Quotes" defaultMessage="TA Quotes" />,
            type: 'item',
            url: '/owner/technical-assistants-quotes',
            icon: AssignmentIcon,
            breadcrumbs: false
        },
        {
            id: 'po-chat',
            title: <FormattedMessage id="chat" defaultMessage="Chat" />,
            type: 'item',
            url: '/chat',
            icon: ChatIcon,
            breadcrumbs: false
        },
        {
            id: 'deal-room',
            title: <FormattedMessage id="Users" defaultMessage="Deal Room" />,
            type: 'collapse',
            icon: SolarPowerIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'calendar',
                    title: <FormattedMessage id="Calendar" defaultMessage="Calendar" />,
                    type: 'item',
                    icon: CalendarMonthOutlinedIcon,
                    url: '/owner/calendar',
                    breadcrumbs: false
                },
                {
                    id: `pitch-room`,
                    title: <FormattedMessage id="Pitch Room" defaultMessage="Pitch Room" />,
                    type: 'item',
                    url: '/pitch-room',
                    icon: ListAltIcon,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default owner;
