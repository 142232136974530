import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    Chip,
    IconButton,
    List,
    Grid,
    Stack,
    Paper,
    Popper,
    Divider,
    Typography,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    ListItemButton,
    ClickAwayListener
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import User1 from 'assets/images/users/avatar.svg';
import { useDispatch, useSelector } from 'store';
import { fetchNotifications, markAllNotificationsAsRead } from 'store/slices/notification';

// assets
import { IconLogout, IconSettings, IconBell } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import NotificationList from './NotificationList';
import { ROLES } from 'utils/Constants';
import { getRoleName } from 'utils/helpers';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { logout, user } = useAuth();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [openNotification, setOpenNotification] = useState(false);
    const { notifications } = useSelector((state) => state.notification);

    useEffect(() => {
        dispatch(fetchNotifications());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleCloseNotification = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenNotification(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleToggleNotification = () => {
        setOpenNotification((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleChange = (event) => setValue(event?.target.value);

    const hasUnreadNotifications = notifications?.data?.some((notification) => !notification.isRead);

    return (
        <>
            {/* Notification section  */}
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Badge variant="dot" color="success" invisible={!hasUnreadNotifications}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={openNotification ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggleNotification}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="20px" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={openNotification}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [matchesXs ? 5 : 0, 20]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={openNotification} {...TransitionProps}>
                            <Paper>
                                {openNotification && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                    <Grid item>
                                                        <Stack direction="row" spacing={2} sx={{ p: 1.25 }}>
                                                            <Typography variant="subtitle1">Notifications</Typography>
                                                        </Stack>
                                                    </Grid>
                                                    {hasUnreadNotifications ? (
                                                        <Typography
                                                            component={Link}
                                                            to="#"
                                                            variant="subtitle2"
                                                            color="primary"
                                                            onClick={() => dispatch(markAllNotificationsAsRead())}
                                                        >
                                                            Mark as all read
                                                        </Typography>
                                                    ) : (
                                                        <IconButton aria-label="delete">
                                                            <CloseIcon onClick={handleCloseNotification} />
                                                        </IconButton>
                                                    )}

                                                    {/* <Grid item>
                                                        <IconButton aria-label="delete">
                                                            <CloseIcon onClick={handleCloseNotification} />
                                                        </IconButton>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                            <Grid item xs={12}>
                                                <PerfectScrollbar
                                                    style={{
                                                        height: '100%',
                                                        maxHeight: 'calc(100vh - 205px)',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    <NotificationList notifications={notifications} />
                                                </PerfectScrollbar>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {/* <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                                            <Button size="small" disableElevation>
                                                View All
                                            </Button>
                                        </CardActions> */}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
            {/* End Notification Section */}

            {/* Start Profile & Settings  */}
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Box display="flex" alignItems="center">
                        <Stack spacing={0.1} sx={{ p: 2 }}>
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                Hello {user?.firstName}
                            </Typography>
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                {getRoleName(user?.role)}
                            </Typography>
                        </Stack>

                        <Avatar
                            src={User1}
                            sx={{
                                ...theme.typography.mediumAvatar,
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer'
                            }}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            color="inherit"
                        />
                    </Box>
                }
                label={<IconSettings stroke={1.5} size="24px" color={theme.palette.primary.main} />}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 14]
                        }
                    }
                ]}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center" sx={{ p: 1, pb: 2 }}>
                                                    <Typography variant="h4">My Account</Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {user?.name}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            <Divider />
                                        </Box>
                                        <PerfectScrollbar
                                            style={{
                                                height: '100%',
                                                maxHeight: 'calc(100vh - 250px)',
                                                overflowX: 'hidden'
                                            }}
                                        >
                                            <Box sx={{ p: 2, pt: 0 }}>
                                                <List
                                                    component="nav"
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 350,
                                                        minWidth: 300,
                                                        backgroundColor: theme.palette.background.paper,
                                                        borderRadius: '10px',
                                                        [theme.breakpoints.down('md')]: {
                                                            minWidth: '100%'
                                                        },
                                                        '& .MuiListItemButton-root': {
                                                            mt: 0.5
                                                        }
                                                    }}
                                                >
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/')}
                                                    >
                                                        <ListItemIcon>
                                                            <IconSettings stroke={1.5} size="20px" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="account-settings" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>

                                                    <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleLogout}
                                                    >
                                                        <ListItemIcon>
                                                            <IconLogout stroke={1.5} size="20px" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2">
                                                                    <FormattedMessage id="logout" />
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </List>
                                            </Box>
                                        </PerfectScrollbar>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
            {/* End Profile & Setting  */}
        </>
    );
};

export default ProfileSection;
