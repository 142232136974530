/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { datePickerToolbarClasses } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    activeStep: 0,
    itinerary: {},
    events: [],
    itineraries: []
};

const slice = createSlice({
    name: 'itinerary',
    initialState,
    reducers: {
        handleBack(state) {
            state.activeStep -= 1;
        },
        handleNext(state) {
            state.activeStep += 1;
        },
        handleReset(state) {
            state.activeStep = 0;
        },
        setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        addItinerarySuccess(state, action) {
            state.itinerary = action.payload;
        },
        getItinerarySuccess(state, action) {
            state.itinerary = action.payload;
        },

        // GET EVENTS
        getItinerariesSuccess(state, action) {
            state.itineraries = action.payload;
        },
        addEventSuccess(state, action) {
            state.events = [...state.events, action.payload];
        },

        // ADD EVENT
        addSuccess(state, action) {
            state.events = action.payload;
        },

        // UPDATE EVENT
        updateItinerarySuccess(state, action) {
            state.itineraries = action.payload;
        },

        // REMOVE EVENT
        removeItinerarySuccess(state, action) {
            state.itineraries = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function handleBack() {
    dispatch(slice.actions.handleBack());
}
export function handleNext() {
    dispatch(slice.actions.handleNext());
}

export function handleReset() {
    return async () => {
        dispatch(slice.actions.handleResetSuccess());
    };
}

export function setActiveStep(data) {
    dispatch(slice.actions.setActiveStep(data));
}

// Functions
// ----------------------------------------------------------------------
export function getItinerary() {
    return async () => {
        try {
            const response = await axios.get('/itinerary');
            dispatch(slice.actions.getItinerariesSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getItineraryByDate(date) {
    return async () => {
        try {
            const data = { selectedDate: date };
            const response = await axios.post(`/itinerary/byDate`, data);
            dispatch(slice.actions.getItinerariesSuccess(response.data.data));
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getItineraryById(id) {
    return async () => {
        try {
            const response = await axios.get(`/itinerary/${id}`);
            dispatch(slice.actions.getItinerarySuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addTempItinerary(data) {
    try {
        // dispatch(slice.actions.addEventSuccess(data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}
export function addItinerary(itinerary) {
    return async () => {
        try {
            const response = await axios.post('/itinerary', itinerary);
            dispatch(slice.actions.addItinerarySuccess(response.data.data));
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function setItinerary(itinerary) {
    return dispatch(slice.actions.addItinerarySuccess(itinerary));
}
export function updateItinerary(itinerary) {
    return async () => {
        try {
            const response = await axios.put(`/itinerary/${itinerary._id}`, itinerary);
            dispatch(slice.actions.updateItinerarySuccess(response.data.itineraries));
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function removeItinerary(itineraryId) {
    return async () => {
        try {
            const response = await axios.delete('/itinerary', { itineraryId });
            dispatch(slice.actions.removeItinerarySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteEvent(itineraryId, eventId) {
    return async () => {
        try {
            const response = await axios.put('/itinerary/deleteEvent', { itineraryId, eventId });
            dispatch(slice.actions.removeItinerarySuccess(response.data));
            return response;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}
