import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'utils/axios';

// material-ui
import ProjectCard from 'ui-component/cards/ProjectCard';

import { Grid, Card, Typography, OutlinedInput, Box, Button, MenuItem, TextField } from '@mui/material';

import { appDrawerWidth, gridSpacing } from 'store/constant';
import FilterListIcon from '@mui/icons-material/FilterList';
import SkeletonProductPlaceholder from 'ui-component/cards/Skeleton/ProductPlaceholder';
// project imports
import { useDispatch, useSelector } from 'store';
import { getUsers } from 'store/slices/user';
import { getProjects } from 'store/slices/project';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getDataRooms } from 'store/slices/dataroom';

// project list container
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
    }),
    marginRight: -appDrawerWidth,
    [theme.breakpoints.down('xl')]: {
        paddingRight: 0,
        marginRight: 0
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginRight: 0
    })
}));
// ==============================|| DEFAULT DASHBOARD ||============================== //

const DataRoom = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    // drawer
    const { projects } = useSelector((state) => state.project);

    const [selectedFilter, setSelectedFilter] = useState(`agriculture`);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getProjects());
        setIsLoading(false);
    }, []);
    useEffect(() => {
        console.log(projects)
    }, [projects]);

    const handleNewProject = () => {
        navigate('/so/projects/add');
    };

    const filters = [
        {
            value: 'agriculture',
            label: 'Agriculture',
            description: 'The Project Owner is the entity that owns the project and is responsible for its implementation.'
        },
        {
            value: 'renewable_energy',
            label: 'Renewable Energy'
        },
        {
            value: 'food_security',
            label: 'Food Security'
        },
        {
            value: 'sustainability',
            label: 'Sustainability'
        }
    ];

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));

    return (
        <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
            <Grid item>
                <AnimateButton>
                    <Button
                        disableElevation
                        sx={{
                            // backgroundColor: theme.palette.secondary.main,
                            color: 'white',
                            height: '48px'
                        }}
                        variant="contained"
                        onClick={handleNewProject}
                        color="secondary"
                        size="small"
                    >
                        Create Project <AddCircleOutlineIcon sx={{ ml: 1 }} />
                    </Button>
                </AnimateButton>
            </Grid>
            <Grid item>
                <TextField
                    id="outlined-adornment-role-register"
                    select
                    size="large"
                    value={selectedFilter}
                    name="role"
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            backgroundColor: theme.palette.grey[300],
                            borderRadius: '40px',
                            height: '48px',
                            paddingLeft: '14px'
                        }
                    }}
                    variant="standard"
                    SelectProps={{
                        IconComponent: () => <FilterListIcon sx={{ mr: 1, color: theme.palette.grey[800] }} />
                    }}
                    MenuProps={{
                        borderRadius: '40px',
                        border: '1px solid black',
                        IconComponent: () => <FilterListIcon sx={{ mr: 1, color: theme.palette.grey[800] }} />
                    }}
                >
                    {filters.map((el) => (
                        <MenuItem key={el.value} value={el.value} onChange={(event) => setSelectedFilter(event.target.value)}>
                            {el.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {isLoading ? (
                <Grid item xs={12} lg={12} md={12}>
                    <Grid container spacing={2}>
                        {[1, 2, 3, 4].map(() => (
                            <Grid item xs={3}>
                                <SkeletonProductPlaceholder />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={12} lg={12} md={12}>
                    {projects.length > 0 ? (
                        <Grid container spacing={2}>
                            {projects.map((project) => (
                                <Grid item xs={6}>
                                    <ProjectCard data={project} link={`/admin/dataroom/preview/${project._id}`} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Grid container spacing={2}>
                            <Typography color="primary">No item found!</Typography>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default DataRoom;
