/* eslint-disable jsx-a11y/aria-role */
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/route-guard/RoleGuard';

const ProjectView = Loadable(lazy(() => import('views/project/view')));
const ProjectPreviewSelectedInvestor = Loadable(lazy(() => import('views/investor/projects/ProjectPreviewSelectedInvestor')));
const ProjectSignNDA = Loadable(lazy(() => import('views/investor/projects/ProjectSignNDA')));
const ProjectSignNDASuccess = Loadable(lazy(() => import('views/investor/projects/ProjectSignNDASuccess')));
const DataRoomInvestor = Loadable(lazy(() => import('views/investor/dataroom')));
const ProjectsInvestor = Loadable(lazy(() => import('views/project/list')));
const InvestorOnboarding = Loadable(lazy(() => import('views/investor/onboarding')));
const SelectedProjectsInvestor = Loadable(lazy(() => import('views/investor/selectedProjects')));
const RequestedProjectsInvestor = Loadable(lazy(() => import('views/project/list/projectsRequestedByInvestor')));
const Deals = Loadable(lazy(() => import('views/project/list/deals')));
const UserProfile = Loadable(lazy(() => import('views/authentication/UserProfile')));
const ComingSoon = Loadable(lazy(() => import('views/maintenance/ComingSoon')));
const MeetingView = Loadable(lazy(() => import('views/pitchRoom/meeting')));

const InvestorRoutes = [
    {
        path: '/investor/projects',
        element: (
            <RoleGuard role="investor">
                <ProjectsInvestor />
            </RoleGuard>
        )
    },
    {
        path: '/investor/projects/:id',
        element: (
            <RoleGuard role="investor">
                <ProjectView />
            </RoleGuard>
        )
    },
    {
        path: '/investor/requested-projects',
        element: (
            <RoleGuard role="investor">
                <RequestedProjectsInvestor />
            </RoleGuard>
        )
    },
    {
        path: '/investor/deals',
        element: (
            <RoleGuard role="investor">
                <Deals />
            </RoleGuard>
        )
    },
    {
        path: '/investor/calendar/',
        element: (
            <RoleGuard role="investor">
                <ComingSoon />
            </RoleGuard>
        )
    },
    {
        path: '/investor/meeting/',
        element: (
            <RoleGuard role={['owner', 'investor']}>
                <MeetingView />
            </RoleGuard>
        )
    },
    {
        path: '/investor/pitch-room',
        element: (
            <RoleGuard role="investor">
                <ComingSoon />
            </RoleGuard>
        )
    },
    {
        path: '/investor/onboarding/:id',
        element: (
            <RoleGuard role="investor">
                <InvestorOnboarding />
            </RoleGuard>
        )
    },

    {
        path: '/investor/selected-projects',
        element: (
            <RoleGuard role="investor">
                <SelectedProjectsInvestor />
            </RoleGuard>
        )
    },
    {
        path: '/investor/project/preview/selected',
        element: (
            <RoleGuard role="investor">
                <ProjectPreviewSelectedInvestor />
            </RoleGuard>
        )
    },
    {
        path: '/investor/sign-nda',
        element: (
            <RoleGuard role="investor">
                <ProjectSignNDA />
            </RoleGuard>
        )
    },
    {
        path: '/investor/sign-nda-success',
        element: (
            <RoleGuard role="investor">
                <ProjectSignNDASuccess />
            </RoleGuard>
        )
    },

    {
        path: '/investor/profile',
        element: (
            <RoleGuard role="investor">
                <UserProfile />
            </RoleGuard>
        )
    }
];

export default InvestorRoutes;
