// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    request: {},
    success: false,
    requests: []
};

const slice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        getRequestsSuccess(state, action) {
            state.requests = action.payload;
        },
        getRequestSuccess(state, action) {
            state.request = action.payload;
        },
        postRequestSuccess(state, action) {
            state.request = action.payload;
        },
        putRequestSuccess(state, action) {
            state.request = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRequest(id) {
    return async () => {
        const response = await axios.get(`/access-request/${id}`);
        dispatch(slice.actions.getRequestSuccess(response.data.data));
    };
}
export function getRequests() {
    return async () => {
        const response = await axios.get(`/access-request/`);
        dispatch(slice.actions.getRequestsSuccess(response.data.data));
    };
}

export function updateRequest(id, data) {
    return axios.put(`/access-request/${id}`, data);
}

export function postRequest(data) {
    return axios.post('/access-request', data);
}
