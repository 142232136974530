// material-ui
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports

// assets
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import ShareLocationOutlinedIcon from '@mui/icons-material/ShareLocationOutlined';
import useAuth from 'hooks/useAuth';
// ===========================|| WIDGET STATISTICS - ICON GRID CARD ||=========================== //

const ProjectSummaryCard = ({ project }) => {
    const { user } = useAuth();
    const theme = useTheme();
    const matchDownXs = useMediaQuery(theme.breakpoints.down('sm'));

    const blockSX = {
        p: 2.5
    };

    return (
        <Grid container alignItems="center" spacing={0}>
            <Grid item xs={12} sm={6} sx={blockSX}>
                <Grid container alignItems="center" spacing={1} justifyContent={matchDownXs ? 'space-between' : 'start'}>
                    <Grid item>
                        <AccountCircleOutlinedIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />
                    </Grid>
                    <Grid item sx={{ maxWidth: '60%' }}>
                        <Typography variant="h5" align="start">
                            Owner's Name
                        </Typography>
                        <Typography variant="subtitle2" align="start">
                            {(project?.owner && `${project?.owner?.firstName} ${project?.owner?.lastName}`) || 'Request access'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={blockSX}>
                <Grid container alignItems="center" spacing={1} justifyContent={matchDownXs ? 'space-between' : 'start'}>
                    <Grid item>
                        <ShareLocationOutlinedIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />
                    </Grid>
                    <Grid item sx={{ maxWidth: '60%' }}>
                        <Typography variant="h5" align="start">
                            Project Location
                        </Typography>
                        <Typography variant="subtitle2" align="start">
                            {user?.role === 'investor' ? project?.location?.address || 'Request access' : project?.location?.address}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={blockSX}>
                <Grid container alignItems="center" spacing={1} justifyContent={matchDownXs ? 'space-between' : 'start'}>
                    <Grid item>
                        <PhoneIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />
                    </Grid>
                    <Grid item sx={{ maxWidth: '60%' }}>
                        <Typography variant="h5" align="start">
                            Phone Number
                        </Typography>
                        <Typography variant="subtitle2" align="start">
                            {user?.role === 'investor' ? project?.owner?.phoneNumber || 'Request access' : project?.owner?.phoneNumber}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={blockSX}>
                <Grid container alignItems="center" spacing={1} justifyContent={matchDownXs ? 'space-between' : 'start'}>
                    <Grid item>
                        <MailOutlineIcon sx={{ color: theme.palette.secondary.main }} fontSize="large" />
                    </Grid>
                    <Grid item sx={{ maxWidth: '60%' }}>
                        <Typography variant="h5" align="start">
                            Email Address
                        </Typography>
                        <Typography variant="subtitle2" align="start">
                            {user?.role === 'investor' ? project?.owner?.email || 'Request access' : project?.owner?.email}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProjectSummaryCard;
