/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { COUNTRIES } from 'utils/Constants';
import AnimateButton from 'ui-component/extended/AnimateButton';

// material-ui
import { useTheme } from '@mui/material/styles';
import { FormHelperText, FormControlLabel, Checkbox, Grid, TextField, Typography, Box, Button, Stack } from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { handleBack, handleNext, updateTechnicalAssistant } from 'store/slices/technicalAssistant';
import ImageUpload from 'ui-component/ImageUpload';
import useAuth from 'hooks/useAuth';

// ===========================|| JWT - REGISTER ||=========================== //

const RepresentativeForm = ({ ...others }, steps) => {
    const { activeStep, technicalAssistant } = useSelector((state) => state.technicalAssistant);

    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useAuth();

    // Extracting file name from path
    const extractedFileName = technicalAssistant?.repProfilePhoto ? technicalAssistant.repProfilePhoto.split('/').pop() : '';

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete();

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    _id: technicalAssistant?._id ?? '',
                    repEmail: user?.email ?? '',
                    repPhone: user?.phoneNumber ?? '',
                    repName: `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || '',
                    repLinkedIn: technicalAssistant?.repLinkedIn ?? '',
                    repProfilePhoto: technicalAssistant?.repProfilePhoto ?? '',
                    canRepCompany: technicalAssistant?.canRepCompany ?? false,
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    repEmail: Yup.string().email('Must be a valid email').max(255).required('Representative Name is required'),
                    repName: Yup.string().required('Representative Name is required'),
                    canRepCompany: Yup.boolean().oneOf([true], 'This is a required input.'),
                    repLinkedIn: Yup.string().url('Must be a valid LinkedIn URL with the format https://...').notRequired(),
                    repPhone: Yup.string()
                        .matches(/^\+?\d+$/, 'Phone number can only contain numbers')
                        .max(17, "Phone number can't exceed 17 characters")
                        .required('Phone number is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    dispatch(updateTechnicalAssistant(values));

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Your information was saved successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    handleNext();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        {isSubmitting && <Loader />}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Full Names
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.repName && errors.repName)}
                                    type="text"
                                    placeholder="Representative Full Names"
                                    variant="outlined"
                                    value={values.repName}
                                    name="repName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled
                                />
                                {touched.repName && errors.repName && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repName}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Email Address
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.repEmail && errors.repEmail)}
                                    type="email"
                                    variant="outlined"
                                    placeholder="johndoe@example.com"
                                    value={values.repEmail}
                                    name="repEmail"
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled
                                />
                                {touched.repEmail && errors.repEmail && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repEmail}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Phone Number
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.repPhone && errors.repPhone)}
                                    type="text"
                                    variant="outlined"
                                    placeholder="+254 ********"
                                    value={values.repPhone}
                                    name="repPhone"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled
                                />
                                {touched.repPhone && errors.repPhone && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repPhone}
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    LinkedIn Profile (Paste your LinkedIn profile link)
                                </Typography>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.repLinkedIn && errors.repLinkedIn)}
                                    type="text"
                                    placeholder="Paste the link to your LinkedIn profile"
                                    variant="outlined"
                                    value={values.repLinkedIn}
                                    name="repLinkedIn"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                />
                                {touched.repLinkedIn && errors.repLinkedIn && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.repLinkedIn}
                                    </FormHelperText>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Typography color={theme.palette.secondary.main} gutterBottom>
                                    Representative Profile Photo
                                </Typography>
                                <Stack>
                                    <ImageUpload
                                        fieldName="repProfilePhoto"
                                        label={
                                            technicalAssistant?.repProfilePhoto ? 'Replace Profile Photo' : 'Representative Profile Photo'
                                        }
                                        formik={{
                                            setFieldValue,
                                            values
                                        }}
                                        acceptedFileTypes=".png,.jpg,.jpeg"
                                        initialFileName={extractedFileName}
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.canRepCompany}
                                            error={Boolean(touched.canRepCompany && errors.canRepCompany)}
                                            name="canRepCompany"
                                            color="primary"
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={() => setFieldValue('canRepCompany', !values.canRepCompany)}
                                        />
                                    }
                                    label="I have the authority to represent the company?"
                                />
                                {touched.canRepCompany && errors.canRepCompany && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {errors.canRepCompany}
                                    </FormHelperText>
                                )}
                            </Grid>

                            {/* Bottom Buttons */}
                            <Grid item xs={12} sm={12} md={12}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <AnimateButton>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            disableElevation
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.grey[500],
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            fullWidth
                                            size="large"
                                            variant="contained"
                                        >
                                            <WestIcon sx={{ mr: 'auto' }} /> Back
                                        </Button>
                                    </AnimateButton>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                width: '200px',
                                                height: '48px',
                                                backgroundColor: theme.palette.secondary.main,
                                                color: 'white'
                                            }}
                                            color="secondary"
                                            size="large"
                                            variant="contained"
                                        >
                                            Save & Complete <EastIcon sx={{ ml: 'auto' }} />
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default RepresentativeForm;
