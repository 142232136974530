/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-unknown-property */
import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'store';
import { Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import AnimateButton from 'ui-component/extended/AnimateButton';

import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import EmailImg from 'assets/images/icons/email.svg';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Button, Typography, TextField, InputLabel, Tabs, Chip, Tab } from '@mui/material';
import { handleBack, handleNext, handleReset } from 'store/slices/investor';
import CustomTabPanel from 'ui-component/tabs/CustomTabPanel';
import useAuth from 'hooks/useAuth';
// ===========================|| JWT - REGISTER ||=========================== //
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const SummaryForm = ({ ...others }, steps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { user } = useAuth();
    const { activeStep, investor } = useSelector((state) => state.investor);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleComplete = () => {
        handleNext();
    };

    return (
        <>
            <Grid spacing={2} sx={{ px: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs color="success" value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Company Information" {...a11yProps(0)} />
                        <Tab label="Company Representative" {...a11yProps(1)} />
                        <Tab label="Investor Type" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2} sx={{ px: 1 }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Company Name
                            </Typography>
                            <Chip label={investor.companyName ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Company Website
                            </Typography>
                            <Chip label={investor.companyWebsite ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Company Overview
                            </Typography>
                            <Chip label={investor.companyOverview ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Company Email Address
                            </Typography>
                            <Chip label={investor.companyEmail ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Phone Number
                            </Typography>
                            <Chip label={investor.companyPhone ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Physical Address
                            </Typography>
                            <Chip label={investor.physicalAddress ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                City
                            </Typography>
                            <Chip label={investor.city ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Full Name
                            </Typography>
                            <Chip label={investor.repName ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Email
                            </Typography>
                            <Chip label={investor.repEmail ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Phone Number
                            </Typography>
                            <Chip label={investor.repPhone ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Physical Address
                            </Typography>
                            <Chip label={investor.repAddress ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Type of investor
                            </Typography>
                            <Chip label={investor.scope ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Thematic Group
                            </Typography>
                            <Chip label={investor.thematicGroup ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Project Stage
                            </Typography>
                            <Chip label={investor.projectStage ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Project Location
                            </Typography>
                            <Chip label={investor.projectLocation ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Previous raise
                            </Typography>
                            <Chip label={investor.previousRaise ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Deal Type
                            </Typography>
                            <Chip label={investor.thematicGroup ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Typography color={theme.palette.secondary.main} gutterBottom>
                                Project Target Amount
                            </Typography>
                            <Chip label={investor.targetAmount ?? `Not set`} sx={{ px: 2 }} />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
            </Grid>
            {/* Bottom Buttons */}
            <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <AnimateButton>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            disableElevation
                            sx={{
                                width: '200px',
                                height: '48px',
                                backgroundColor: theme.palette.grey[500],
                                color: 'white'
                            }}
                            color="secondary"
                            fullWidth
                            size="large"
                            variant="contained"
                        >
                            <WestIcon sx={{ mr: 'auto' }} /> Back
                        </Button>
                    </AnimateButton>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <AnimateButton>
                        <Button
                            onClick={handleComplete}
                            disableElevation
                            fullWidth
                            sx={{
                                width: '200px',
                                height: '48px',
                                backgroundColor: theme.palette.secondary.main,
                                color: 'white',
                                mr: 2
                            }}
                            color="secondary"
                            size="large"
                            variant="contained"
                        >
                            Complete <EastIcon sx={{ ml: 'auto' }} />
                        </Button>
                    </AnimateButton>
                </Box>
            </Grid>
        </>
    );
};

export default SummaryForm;
