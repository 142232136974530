// third-party
import { FormattedMessage } from 'react-intl';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
// ==============================|| PROJECT OWNER MENU ITEMS ||============================== //
const submittingOrganization = {
    id: 'submittingOrganization',
    title: <FormattedMessage id="Admin" defaultMessage="Submitting Organisation" />,
    icon: SpaceDashboardIcon,
    type: 'group',
    children: [
        {
            id: 'so_projects',
            title: <FormattedMessage id="Users" defaultMessage="Projects" />,
            type: 'collapse',
            icon: SolarPowerIcon,
            breadcrumbs: false,
            children: [
                {
                    id: 'add_projects',
                    title: <FormattedMessage id="Add project" defaultMessage="Add project" />,
                    type: 'item',
                    icon: PersonAddAltIcon,
                    url: '/projects/add',
                    breadcrumbs: false
                },
                {
                    id: 'view_projects',
                    title: <FormattedMessage id="View Projects" defaultMessage="View Projects" />,
                    type: 'item',
                    url: '/so/projects',
                    icon: ListAltIcon,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default submittingOrganization;
