import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Customization from '../Customization';
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import navigation from 'menu-items';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import Header from './Header';
import HorizontalBar from './HorizontalBar';
import Sidebar from './Sidebar';
import { getSocket } from 'utils/socket';

// assets
import { IconChevronRight } from '@tabler/icons';
import { toast } from 'react-toastify';
import { getUserConversations } from 'store/slices/chat';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter + 200
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: layout === 'horizontal' ? 135 : 88
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
            marginTop: 88
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter + 200
        }),
        marginLeft: layout === 'horizontal' ? '20px' : 0,
        marginTop: layout === 'horizontal' ? 135 : 88,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('md')]: {
            marginTop: layout === 'horizontal' ? 135 : 88
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginTop: 88
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ onboardProject }) => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container, drawerType, layout } = useConfig();

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        } else {
            dispatch(openDrawer(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerType]);
    function onConnect() {
        // nothing yet
    }

    function onDisconnect() {
        // nothing yet
    }

    function receiveMessage(data) {
        toast.info("You've got a new message");
        dispatch(getUserConversations());
    }
    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        }
        const socket = getSocket();
        socket?.on('connect', onConnect);
        socket?.on('disconnect', onDisconnect);
        socket?.on('receive_message', receiveMessage);
        socket?.connect();
        return () => {
            socket?.off('connect', onConnect);
            socket?.off('disconnect', onDisconnect);
            socket?.off('receive_message', receiveMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            dispatch(openDrawer(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const header = useMemo(
        () => (
            <Toolbar sx={{ p: condition ? '10px' : '16px' }}>
                <Header />
            </Toolbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [layout, matchDownMd]
    );

    //get state from parent component that tells us whether the user had finished onboarding the project
    return (
        <>
            {!onboardProject ? (
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />

                    {/* header */}
                    <AppBar
                        position="fixed"
                        elevation={0}
                        sx={{
                            bgcolor: 'background.default',
                            transition: drawerOpen ? theme.transitions.create('width') : 'none'
                        }}
                    >
                        {header}
                    </AppBar>

                    {/* horizontal menu-list bar */}
                    {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && <HorizontalBar />}

                    {/* drawer */}
                    {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar />}

                    {/* main content */}
                    <Main theme={theme} open={drawerOpen} layout={layout}>
                        <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
                            {/* breadcrumb */}
                            <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                            <Outlet />
                        </Container>
                    </Main>

                    {/* Removed live customization option */}
                    {/* <Customization /> */}
                </Box>
            ) : (
                <>
                    <Outlet />
                </>
            )}
        </>
    );
};

export default MainLayout;
