// third-party
import { createSlice } from '@reduxjs/toolkit';

// dataroom imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    dataroom: {},
    activeStep: 0,
    termsAccepted: false,
    submittingOrganization: {},
    submittingOrganizations: []
};

const slice = createSlice({
    name: 'submittingOrganization',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        handleResetSuccess(state) {
            state.activeStep = 0;
            state.submittingOrganization = {};
        },
        handleBack(state) {
            state.activeStep -= 1;
        },
        handleNext(state) {
            state.activeStep += 1;
        },
        getSOSuccess(state, action) {
            state.submittingOrganizations = action.payload;
            state.success = true;
        },
        showSuccess(state, action) {
            state.submittingOrganization = action.payload;
            state.success = true;
        },
        postSOSuccess(state, action) {
            state.submittingOrganization = action.payload;
            state.success = true;
        },
        deleteSuccess(state, action) {
            state.submittingOrganization = {};
            state.success = true;
        }
    }
});

// Reducer
export default slice.reducer;

export function setActiveStep(data) {
    dispatch(slice.actions.setActiveStep(data));
}

export function handleReset() {
    return async () => {
        dispatch(slice.actions.handleResetSuccess());
    };
}

export function handleBack() {
    dispatch(slice.actions.handleBack());
}
export function handleNext() {
    dispatch(slice.actions.handleNext());
}
export function showSubmittingOrg(id) {
    return async () => {
        try {
            const response = await axios.get(`/orgs/${id}`);
            dispatch(slice.actions.showSuccess(response.data.data));
            return response.data.data;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            return error;
        }
    };
}

export function getSubmittingOrg() {
    return async () => {
        try {
            const response = await axios.get(`/orgs`);
            dispatch(slice.actions.getSOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postSubmittingOrg(data) {
    return async () => {
        try {
            data.iskyc = false;
            data.verified = false;
            const response = await axios.post(`/orgs`, data);
            dispatch(slice.actions.postSOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateSubmittingOrg(data) {
    return async () => {
        try {
            const response = await axios.put(`/orgs/${data._id}`, data);
            dispatch(slice.actions.postSOSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function delSubmittingOrg(selectedUser) {
    return async () => {
        try {
            await axios.delete(`/orgs/${selectedUser}`);
            dispatch(slice.actions.deleteSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}