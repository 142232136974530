import goal1 from 'assets/images/sdg/goal1.png';
import goal2 from 'assets/images/sdg/goal2.png';
import goal3 from 'assets/images/sdg/goal3.png';
import goal4 from 'assets/images/sdg/goal4.png';
import goal5 from 'assets/images/sdg/goal5.png';
import goal6 from 'assets/images/sdg/goal6.png';
import goal7 from 'assets/images/sdg/goal7.png';
import goal8 from 'assets/images/sdg/goal8.png';
import goal9 from 'assets/images/sdg/goal9.png';
import goal10 from 'assets/images/sdg/goal10.png';
import goal11 from 'assets/images/sdg/goal11.png';
import goal12 from 'assets/images/sdg/goal12.png';
import goal13 from 'assets/images/sdg/goal13.png';
import goal14 from 'assets/images/sdg/goal14.png';
import goal15 from 'assets/images/sdg/goal15.png';
import goal16 from 'assets/images/sdg/goal16.png';
import goal17 from 'assets/images/sdg/goal17.png';

export const STRENGTH_TEXT = ['Weak', 'Weak', 'Okay', 'Good', 'Strong'];

export const year = new Date().getFullYear();

export const TIME_ZONE_MAPPING = {
    'Africa/Nairobi': 'East African Time (EAT)',
    'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time (HAST)',
    'America/Anchorage': 'Alaska Standard Time (AKST)',
    'America/Los_Angeles': 'Pacific Standard Time (PST)',
    'America/Denver': 'Mountain Standard Time (MST)',
    'America/Chicago': 'Central Standard Time (CST)',
    'America/New_York': 'Eastern Standard Time (EST)',
    'America/Puerto_Rico': 'Atlantic Standard Time (AST)',
    'Atlantic/South_Georgia': 'South Georgia and the South Sandwich Islands',
    'Atlantic/Azores': 'Azores Time',
    'Etc/GMT': 'Greenwich Mean Time (West Africa Time)',
    'Africa/Lagos': 'West Central Africa Time (WAT)',
    'Africa/Johannesburg': 'Central Africa Time (CAT)',
    'Indian/Mauritius': 'Mauritius Time'
};

export const HELPER_TEXTS = {
    thematicGroup:
        "A thematic area refers to the primary topic or focus of the particular context or content. For instance, in our context, the thematic area could be 'Climate Finance', 'Urbanization', 'Sustainable Agriculture', etc. Choose the area that best describes the main theme or subject of your entry.",
    declarationOfPEP:
        "PEP stands for 'Politically Exposed Person'. A PEP might be an individual who holds a significant public position or has a close association with someone who does, and therefore could be more vulnerable to being involved in bribery or corruption due to their position. Please declare if you or any associated party can be categorized as a PEP.",
    offtakeAgreement:
        "An offtake agreement is a formal contract between two parties where one party commits to buying or taking the product from the other. It's commonly used in natural resource industries, like mining or agriculture. Indicate whether such an agreement is in place: 'Yes' if there is an existing agreement and 'No' if there isn't.",
    dealType:
        "'Deal Type' designates the nature of the transaction or arrangement. 'Grant' refers to monetary support given either with expectations of return ('Recoverable') or without ('Non-recovery'). 'Technical Assistance' offers specialized expertise for different project phases, from feasibility assessments to execution. Meanwhile, a 'Pitch' is a presentation of an idea or project, which can be conducted either virtually or in-person.",
    keyStakeholders:
        "Key stakeholders are individuals or groups who have a significant interest in or influence over the project. They can affect or be affected by the project's outcomes. ",
    projectRisksAndMitigation:
        "Please outline the potential risks associated with the project and the strategies or steps you've taken (or plan to take) to mitigate or manage those risks.",
    attendees: 'Email addresses for the parties you want to extend the pitch invitation to.',
    moderators: 'Name of Master of Ceremony(s) and the Moderator(s) you need in the pitch room.'
};

export const ROLES = [
    {
        title: 'Administrator',
        slug: 'admin'
    },
    {
        title: 'Project Owner',
        slug: 'owner'
    },
    {
        title: 'Submitting Organisation',
        slug: 'submittingOrganization'
    },
    {
        title: 'Investor',
        slug: 'investor'
    }
];

export const PROJECT_TYPES = [
    'Afforestation',
    'Agroforestry',
    'Grassland Restoration',
    'Mangrove Conservation',
    'Mangrove Restoration',
    'Peatland Restoration',
    'Reforestation',
    'Seagrass Beds',
    'Soil Conservation'
];
export const SDG_TARGETS = [
    {
        id: 1,
        name: 'No Poverty',
        image: goal1,
        info: 'Projects such as sustainable agriculture, forestry, and fisheries can contribute to poverty reduction by providing sustainable livelihoods and income generation opportunities for local communities.',
        description: 'End poverty in all its forms and dimensions.',
        color: '#E5243B'
    },
    {
        id: 2,
        name: 'Zero Hunger',
        image: goal2,
        info: 'Solutions such as sustainable agricultural practices, agroforestry, and fisheries management can help to increase food production and improve food security while also protecting natural resources and ecosystems.',
        description: 'End hunger, achieve food security and improved nutrition.',
        color: '#DDA63A'
    },
    {
        id: 3,
        name: 'Good Health and Well-being',
        image: goal3,
        info: 'Solutions such as green infrastructure, urban parks, and green roofs can also help to mitigate the impacts of air pollution and extreme weather events, improving public health and well-being.',
        description: 'Ensure healthy lives and promote well-being for all at all ages.',
        color: '#4C9F38'
    },
    {
        id: 4,
        name: 'Quality Education',
        image: goal4,
        info: 'Nature-based education programs can help to foster a deeper understanding and appreciation of the natural world, leading to greater environmental awareness and action.',
        description: 'Ensure inclusive and equitable quality education. ',
        color: '#C5192D'
    },
    {
        id: 5,
        name: 'Gender Equality',
        image: goal5,
        info: 'Nature-based projects and activities can help to address gender inequality by providing women with access to land, resources, and economic opportunities.',
        description: 'Achieve gender equality and empower all women and girls. ',
        color: '#FF3A21'
    },
    {
        id: 6,
        name: 'Clean Water and Sanitation ',
        image: goal6,
        info: 'Solutions such as watershed protection, wetland restoration, and sustainable land management practices can help to improve water quality and availability while also protecting aquatic ecosystems and biodiversity.',
        description: 'Ensure availability and sustainable management of water and sanitation for all.',
        color: '#26BDE2'
    },
    {
        id: 7,
        name: 'Affordable and Clean Energy',
        image: goal7,
        info: 'Solutions such as bioenergy, hydropower, and geothermal energy can provide clean and renewable sources of energy, reducing reliance on fossil fuels and mitigating climate change.',
        description: 'Ensure access to affordable, reliable, sustainable and modern energy for all.',
        color: '#FCC30B'
    },
    {
        id: 8,
        name: 'Decent Work and Economic Growth',
        image: goal8,
        info: 'Solutions can provide sustainable livelihoods and income generation opportunities, contributing to economic growth and reducing poverty.',
        description: 'Inclusive growth, decent work for all.',
        color: '#A21942'
    },
    {
        id: 9,
        name: 'Industry, Innovation and Infrastructure ',
        image: goal9,
        info: 'Solutions such as green infrastructure, green buildings, and sustainable transportation systems can help to reduce the environmental impacts of industry and infrastructure while also promoting sustainable economic development.',
        description: 'Resilient infrastructure, industrialization, innovation.',
        color: '#FD6925'
    },
    {
        id: 10,
        name: 'Reduced Inequalities',
        image: goal10,
        info: 'Solutions can help to reduce inequalities by providing marginalized communities with access to natural resources, economic opportunities, and other benefits.',
        description: 'Reduce inequality within and among countries.',
        color: '#DD1367'
    },
    {
        id: 11,
        name: 'Sustainable Cities and Communities ',
        image: goal11,
        info: 'Solutions such as green roofs, urban parks, and green infrastructure can help to create more livable and resilient cities, improving public health, reducing air pollution, and enhancing biodiversity.',
        description: 'Make cities and settlements inclusive and sustainable.',
        color: '#FD9D24'
    },
    {
        id: 12,
        name: 'Responsible Consumption and Production',
        image: goal12,
        info: 'Solutions such as sustainable agriculture, forestry, and fisheries can help to promote responsible consumption and production practices, reducing waste and environmental impacts.',
        description: 'Ensure sustainable consumption and production.',
        color: '#BF8B2E'
    },
    {
        id: 13,
        name: 'Climate Action',
        image: goal13,
        info: 'Solutions such as afforestation, reforestation, and sustainable land management can help to mitigate the impacts of climate change by sequestering carbon, enhancing ecosystem resilience, and reducing greenhouse gas emissions.',
        description: 'Take urgent action to combat climate change and its impacts.',
        color: '#3F7E44'
    },
    {
        id: 14,
        name: 'Life Below Water',
        image: goal14,
        info: 'Solutions such as marine protected areas, coral reef restoration, and sustainable fisheries management can help to protect and restore marine ecosystems, promoting biodiversity and ensuring the sustainable use of marine resources.',
        description: 'Conserve and sustainably use the oceans, seas and marine resources for sustainable development.',
        color: '#0A97D9'
    },
    {
        id: 15,
        name: 'Life on Land',
        image: goal15,
        info: 'Solutions such as habitat restoration, reforestation, and wildlife conservation can help to protect and restore terrestrial ecosystems, promoting biodiversity, enhancing ecosystem services, and mitigating the impacts of climate change.',
        description: 'Sustainable land use, combat desertification, preserve and restore biodiversity in ecosystems.',
        color: '#56C02B'
    },
    {
        id: 16,
        name: 'Peace, Justice and Strong Institutions',
        image: goal16,
        info: 'Solutions such as community-based natural resource management, land tenure reform, and wildlife conservation can help to promote peace, justice, and strong institutions by providing sustainable',
        description: 'Inclusive societies, justice and accountable institutions.',
        color: '#00689D'
    },
    {
        id: 17,
        name: 'Partnerships for the Goals',
        image: goal17,
        info: 'SDG 17 is relevant in terms of fostering partnerships and collaborations between different stakeholders involved in nature-based solutions. For example, partnerships between governments, private sector, and local communities.',
        description: 'Strengthen the means of implementation and revitalize the global partnership for sustainable development.',
        color: '#19486A'
    }
];
export const OWNERSHIP_TYPES = [
    'Privately Owned',
    'Publicly Listed',
    'Cooperative',
    'State Owned',
    'Unlisted Publicly Limited Company',
    'Other'
];
export const PROJECT_REPRESENTATIVE = ['Same as Account Owner', 'Someone else'];
export const PROJECT_STATUS = ['Concept', 'Planning', 'Under Development', 'Operational'];
export const THEMATIC_GROUPS = [
    'Energy transition and renewable energy',
    'Green minerals and manufacturing',
    'Sustainable agriculture, land use and ocean use',
    'Sustainable infrastructure and urbanization',
    'Natural capital'
];

export const ALL_THEMATIC_GROUPS = [
    'Energy transition and renewable energy',
    'Green minerals and manufacturing',
    'Sustainable agriculture, land use and ocean use',
    'Sustainable infrastructure and urbanization',
    'Natural capital',
    'Carbon finance & investment',
    'Adaptation & resilience'
];

export const DEAL_TYPES = {
    Grant: ['Recoverable', 'Non recoverable'],
    'Technical Assistance': [
        'Technical Feasibility Assessment',
        'Project Design and Planning',
        'Financial Modeling and Analysis',
        'Legal',
        'Engineering, procurement and construction',
        'Other'
    ],
    Pitch: ['Virtual', 'Physical'],
    Investment: ['Equity', 'Debt']
};

export const COUNTRIES = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' }
];
