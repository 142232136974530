/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect } from 'react';
import { dispatch } from 'store';
import { LOGIN } from 'store/actions';
import { useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import EmailImg from 'assets/images/icons/email.svg';
// material-ui
import { Box, Grid, Button, Typography, Divider } from '@mui/material';
import { handleReset } from 'store/slices/submittingOrganization';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
// ===========================|| JWT - REGISTER ||=========================== //
const SuccessCard = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();
    const handleComplete = async () => {
        handleReset();
        // reset the save user state
        navigate(`/so/projects`);
        // window.location.reload();
    };

    useEffect(() => {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }, []);

    return (
        <>
            <Grid spacing={2} sx={{ px: 2 }}>
                <Grid container justifyContent="center" direction="column" alignItems="center">
                    <Typography align="center">
                        <img
                            src={EmailImg}
                            sx={{
                                margin: '8px 0 8px 8px !important',
                                cursor: 'pointer'
                            }}
                            aria-haspopup="true"
                            color="inherit"
                            loading="lazy"
                        />
                    </Typography>
                    <Typography variant="h2" sx={{ color: theme.palette.grey[800], my: 1 }}>
                        Congratulations
                    </Typography>
                    <Typography variant="caption" sx={{ color: theme.palette.grey[800], mt: 3 }}>
                        You have successfully completed your profile.
                    </Typography>
                    <Divider />
                </Grid>
            </Grid>
            {/* Bottom Buttons */}
            <Grid container item xs={12} sm={12} md={12} justifyContent="center" sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <AnimateButton>
                        <Button
                            onClick={handleComplete}
                            disableElevation
                            fullWidth
                            sx={{
                                height: '48px',
                                backgroundColor: theme.palette.secondary.main,
                                color: 'white',
                                mr: 2
                            }}
                            color="secondary"
                            size="large"
                            variant="contained"
                        >
                            Continue to projects <EastIcon sx={{ ml: 'auto' }} />
                        </Button>
                    </AnimateButton>
                </Box>
            </Grid>
        </>
    );
};

export default SuccessCard;
