// third-party
import { createSlice } from '@reduxjs/toolkit';

// dataroom imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    dataroom: {},
    success: false,
    datarooms: [],
    dataRoomFiles: []
};

const slice = createSlice({
    name: 'dataroom',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        postDataRoomSuccess(state, action) {
            state.dataroom = action.payload;
            state.success = true;
        },
        patchDataRoomSuccess(state, action) {
            state.dataroom = action.payload;
        },
        getDataRoomSuccess(state, action) {
            state.dataroom = action.payload;
        },
        getDataRoomsSuccess(state, action) {
            state.datarooms = action.payload;
        },
        setFiles(state, action) {
            state.dataRoomFiles = action.payload;
        },
        deleteDataRoomSuccess(state, action) {
            state.dataroom = action.payload;
        }

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function setDataRoom(data) {
    dispatch(slice.actions.getDataRoomSuccess(data));
}

export function getDataRoomFiles(DataRoomGuid) {
    return async () => {
        const formData = { ParentFolderGuid: DataRoomGuid };
        try {
            const response = await axios.post(`/digify/files/browse`, formData);
            dispatch(slice.actions.setFiles(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDataRoom(id) {
    return async () => {
        try {
            const response = await axios.get(`/digify/data-rooms/${id}`);
            dispatch(slice.actions.getDataRoomSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getDataRooms() {
    return async () => {
        try {
            const response = await axios.get(`/digify/data-room`);
            dispatch(slice.actions.getDataRoomsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postDataRoom(data) {
    return async () => {
        try {
            const response = await axios.post(`/digify/data-room`, data);
            dispatch(slice.actions.postDataRoomSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteDataRoom(DataRoomGuid) {
    return async () => {
        try {
            const response = await axios.delete(`/digify/data-room/${DataRoomGuid}`);
            dispatch(slice.actions.deleteDataRoomSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function saveDataRoom(data) {
    const formData = new FormData();
    for (const field in data) {
        formData.append(field, data[field]);
    }
    return axios.post(`/digify/data-rooms`, data);
}
