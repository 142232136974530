// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    activeStep: 0,
    events: []
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        handleBack(state) {
            state.activeStep -= 1;
        },
        handleNext(state) {
            state.activeStep += 1;
        },
        handleReset(state) {
            state.activeStep = 0;
        },
        setActiveStep(state, action) {
            state.activeStep = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET EVENTS
        getEventsSuccess(state, action) {
            state.events = action.payload;
        },

        // ADD EVENT
        addEventSuccess(state, action) {
            state.events = action.payload;
        },

        // UPDATE EVENT
        updateEventSuccess(state, action) {
            state.events = action.payload;
        },

        // REMOVE EVENT
        removeEventSuccess(state, action) {
            state.events = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function handleBack() {
    dispatch(slice.actions.handleBack());
}
export function handleNext() {
    dispatch(slice.actions.handleNext());
}

export function handleReset() {
    return async () => {
        dispatch(slice.actions.handleResetSuccess());
    };
}

export function setActiveStep(data) {
    dispatch(slice.actions.setActiveStep(data));
}

// ----------------------------------------------------------------------

export function getEvents() {
    return async () => {
        try {
            const response = await axios.get('/events');
            dispatch(slice.actions.getEventsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addEvent(event) {
    return async () => {
        try {
            const response = await axios.post('/calendar/events/new', event);
            dispatch(slice.actions.addEventSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateEvent(event) {
    return async () => {
        try {
            const response = await axios.post('/calendar/events/update', event);
            dispatch(slice.actions.updateEventSuccess(response.data.events));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function removeEvent(eventId) {
    return async () => {
        try {
            const response = await axios.post('/calendar/events/remove', { eventId });
            dispatch(slice.actions.removeEventSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
