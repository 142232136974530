import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/route-guard/RoleGuard';

const UserProfile = Loadable(lazy(() => import('views/authentication/UserProfile')));
const TARequests = Loadable(lazy(() => import('views/technicalAssistant/TARequests')));
const TAChat = Loadable(lazy(() => import('views/technicalAssistant/TAChats')));
const TARequestDetails = Loadable(lazy(() => import('views/technicalAssistant/TARequests/view')));
const ProjectsTA = Loadable(lazy(() => import('views/technicalAssistant/project/list')));
const ProjectView = Loadable(lazy(() => import('views/project/view/technicalAssistant')));

/* Technical Assistant Pages */
import TechnicalAssistantsOnboarding from '../views/technicalAssistant/Onboarding';
import TechnicalAssistantsList from '../views/technicalAssistant/TAList';

const TARoutes = [
    {
        path: '/ta/onboarding/:id',
        element: (
            <RoleGuard role="technicalAssistant">
                <TechnicalAssistantsOnboarding />
            </RoleGuard>
        )
    },
    {
        path: '/ta/projects',
        element: (
            <RoleGuard role="technicalAssistant">
                <ProjectsTA />
            </RoleGuard>
        )
    },
    {
        path: '/ta/projects/:id',
        element: (
            <RoleGuard role="technicalAssistant">
                <ProjectView />
            </RoleGuard>
        )
    },
    {
        path: '/ta/profile',
        element: (
            <RoleGuard role="technicalAssistant">
                <UserProfile />
            </RoleGuard>
        )
    },
    {
        path: '/ta/requests',
        element: (
            <RoleGuard role="technicalAssistant">
                <TARequests />
            </RoleGuard>
        )
    },
    {
        path: '/ta/chats',
        element: (
            <RoleGuard role="technicalAssistant">
                <TAChat />
            </RoleGuard>
        )
    },
    {
        path: '/ta/requests/:id',
        element: (
            <RoleGuard role="technicalAssistant">
                <TARequestDetails />
            </RoleGuard>
        )
    }
];

export default TARoutes;
